import Vue from 'vue';
import Vuex from 'vuex';
import Basic from './modules/basic.store'
import Auth from './modules/auth.store'
import Workspace from './modules/workspace.store'
import User from './modules/user.store'
import Project from './modules/project.store'
import Task from './modules/task.store'
import Role from './modules/role.store'
import TaskQa from './modules/taskQa.store'
import TaskReview from './modules/taskReview.store'
import Permissions from './modules/permissions.store'
import Dashboard from './modules/dashboard.store'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Basic,
        Auth,
        Workspace,
        User,
        Project,
        Task,
        TaskQa,
        Role,
        TaskReview,
        Permissions,
        Dashboard
        },
    state: {
        isLoading: false
    },
    getters: {
        isLoading: state => state.isLoading
    },
})