<template>
  <div class="login py-11">
    <form
      class="
        flex-column
        content-center
        justify-content-center
        px-3
        py-2
        transition
        transform
        translate-y-6
        ease-in
      "
      @submit="confirmUserAccount"
    >
      <div class="relative z-0 mb-6 w-full group">
        <input
          type="email"
          v-model="email"
          name="floating_email"
          id="floating_email"
          class="
            block
            py-2.5
            px-0
            w-full
            text-sm text-white-100
            bg-transparent
            border-0 border-b border-gray-300
            appearance-none
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500
            focus:outline-none focus:ring-0 focus:border-blue-600
            peer
          "
          placeholder=" "
          required
        />
        <label
          for="floating_email"
          class="
            requiredSignup
            peer-focus:font-medium
            absolute
            text-sm text-gray-500
            dark:text-gray-400
            duration-300
            transform
            -translate-y-6
            scale-75
            top-3
            -z-10
            origin-[0]
            peer-focus:left-0
            peer-focus:text-blue-600
            peer-focus:dark:text-blue-500
            peer-placeholder-shown:scale-100
            peer-placeholder-shown:translate-y-0
            peer-focus:scale-75 peer-focus:-translate-y-6
          "
          >Email address</label
        >
      </div>

      <div class="relative z-0 mb-8 w-full group">
        <input
          v-model="password"
          type="password"
          name="floating_password"
          id="floating_password"
          class="
            block
            py-2.5
            px-0
            w-full
            text-sm text-white-100
            bg-transparent
            border-0 border-b border-gray-300
            appearance-none
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500
            focus:outline-none focus:ring-0 focus:border-blue-600
            peer
          "
          placeholder=" "
          required
        />

        <label
          for="floating_password"
          class="
            requiredSignup
            peer-focus:font-medium
            absolute
            text-sm text-gray-500
            dark:text-gray-400
            duration-300
            transform
            -translate-y-6
            scale-75
            top-3
            -z-10
            origin-[0]
            peer-focus:left-0
            peer-focus:text-blue-600
            peer-focus:dark:text-blue-500
            peer-placeholder-shown:scale-100
            peer-placeholder-shown:translate-y-0
            peer-focus:scale-75 peer-focus:-translate-y-6
          "
          >Your Password</label
        >
      </div>
      <div class="relative z-0 mb-8 w-full group">
        <input
          v-model="newPassword"
          type="password"
          name="floating_password"
          id="floating_password"
          class="
            block
            py-2.5
            px-0
            w-full
            text-sm text-white-100
            bg-transparent
            border-0 border-b border-gray-300
            appearance-none
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500
            focus:outline-none focus:ring-0 focus:border-blue-600
            peer
          "
          placeholder=" "
          required
        />

        <label
          for="floating_password"
          class="
            requiredSignup
            peer-focus:font-medium
            absolute
            text-sm text-gray-500
            dark:text-gray-400
            duration-300
            transform
            -translate-y-6
            scale-75
            top-3
            -z-10
            origin-[0]
            peer-focus:left-0
            peer-focus:text-blue-600
            peer-focus:dark:text-blue-500
            peer-placeholder-shown:scale-100
            peer-placeholder-shown:translate-y-0
            peer-focus:scale-75 peer-focus:-translate-y-6
          "
          >New Password</label
        >
      </div>
      <div class="relative z-0 mb-6 w-full group">
        <input
          type="password"
          name="repeat_password"
          v-model="confirmPassword"
          id="floating_repeat_password"
          class="
            block
            py-2.5
            px-0
            w-full
            text-sm text-white-100
            bg-transparent
            border-0 border-b border-gray-300
            appearance-none
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500
            focus:outline-none focus:ring-0 focus:border-blue-600
            peer
          "
          placeholder=" "
          required
        />
        <label
          for="floating_repeat_password"
          class="
            requiredSignup
            peer-focus:font-medium
            absolute
            text-sm text-gray-500
            dark:text-gray-400
            duration-300
            transform
            -translate-y-6
            scale-75
            top-3
            -z-10
            origin-[0]
            peer-focus:left-0
            peer-focus:text-blue-600
            peer-focus:dark:text-blue-500
            peer-placeholder-shown:scale-100
            peer-placeholder-shown:translate-y-0
            peer-focus:scale-75 peer-focus:-translate-y-6
          "
          >Confirm New password</label
        >
      </div>

      <div class="flex justify-center pt-8">
        <button
          class="
            bg-white-100
            hover:shadow-xl
            text-green-800
            font-bold
            py-2
            px-5
            mb-6
            rounded-3xl
            transition
            transform
            duration-500
            ease-in-out
          "
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TempUsers",
  data() {
    return {
      email: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
    };
  },
  methods: {
    ...mapActions(["confirmUser"]),

    async confirmUserAccount(e) {
      e.preventDefault();
      const body = {
        email: this.email,
        password: this.password,
        new_password: this.newPassword,
        confirm_new_password: this.confirmPassword,
      };

      let res = await this.confirmUser(body);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.$router.push("/login");
      } else {
        this.$toasted.error(res.msg);
      }
    },
  },
  // components: {
  //   Header,
  // },
};
</script>