<template>
  <div>
    <Header title="Users" />
    <nav class="text-black-50 bg-white-100 text-sm px-6 pt-20">
      <div
        class="border-b border-black-200 py-2 flex justify-between items-center"
      >
        <!-- Filters  -->
        <ul class="flex">
          <li class="px-3 py-2">
            <!-- <router-link to="/user"> -->
            <i class="fas fa-list text-xs"></i> Users List
            <!-- </router-link> -->
          </li>
        </ul>
        <!-- Add, Create new options -->
        <ul class="flex text-black-50">
          <div class="px-2" v-if="myAbilities.can('create_user', 'pms')">
            <button
              @click="createUserModal"
              class="bg-blue-500 text-white-100 px-2 py-1 rounded"
            >
              Create User
            </button>
          </div>
        </ul>
      </div>
    </nav>
    <div class="flex justify-center">
    <div class="lg:w-8/12  pt-6 mb-8">
      <div class="relative overflow-x-auto shadow-md">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead
            class="
              text-xs text-gray-700
              uppercase
              bg-gray-200
              dark:bg-gray-700 dark:text-gray-400
            "
          >
            <tr>
              <th scope="col" class="px-6 py-3">User name</th>
              <th scope="col" class="px-6 py-3">Role</th>
              <!-- <th scope="col" class="px-6 py-3"></th> -->
              <th scope="col" class="px-6 py-3">Actions</th>
              <!-- <th scope="col" class="px-6 py-3">
                <span class="sr-only">Edit</span>
              </th> -->
            </tr>
          </thead>
          <tbody v-if="myAbilities.can('get_user_list', 'pms')">
            <tr
              v-for="user in AllUsers"
              :key="user.id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <th
                scope="row"
                class="
                  px-6
                  py-4
                  font-normal
                  text-gray-900
                  dark:text-white
                  whitespace-nowrap
                "
              >
                {{ user.name }}
              </th>
              <td class="px-6 py-4">
                {{ user.roles.length > 0 ? user.roles[0].name : " " }}
              </td>
              <td class="px-6 py-4" v-if="myAbilities.can('update_activation_status', 'pms')">
                <i
                  class="fas fa-pen text-xs text-black-50 mr-2"
                  @click="editUserModal(user)"
                ></i
                ><span id="activeToggle" @click="activateStatus(user)">
                  <i
                    class="fas fa-toggle-on text-blue-500"
                    id="toggleUser"
                    v-if="user.is_active"
                  ></i>
                  <i class="fas fa-toggle-off" v-if="!user.is_active"></i>
                </span>
              </td>
            </tr>
            <!-- <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="
                  px-6
                  py-4
                  font-normal
                  text-gray-900
                  dark:text-white
                  whitespace-nowrap
                "
              >
                Microsoft Surface Pro
              </th>
              <td class="px-6 py-4">White</td>
                            <td class="px-6 py-4"><i class="fas fa-pen text-xs text-black-50 mx-1" @click="editUserModal"></i>
                          

                            </td>

              
            </tr>
            <tr class="bg-white dark:bg-gray-800">
              <th
                scope="row"
                class="
                  px-6
                  py-4
                  font-normal
                  text-gray-900
                  dark:text-white
                  whitespace-nowrap
                "
              >
                Magic Mouse 2
              </th>
              <td class="px-6 py-4">Black</td>
                            <td class="px-6 py-4"><i class="fas fa-pen text-xs text-black-50" @click="editUserModal"></i></td>

            </tr> -->
          </tbody>
          <tbody v-else>No permission to show users</tbody>
        </table>
      </div>
    </div>
    </div>

    <!-- Create User Modal  -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="modal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Create User
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="createUserModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="addCreateUser">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Name:</label
                >
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="Enter Name"
                  v-model="name"
                  required
                />
              </div>
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    required
                    dark:text-gray-300
                  "
                  >Email:</label
                >
                <input
                  type="email"
                  name="userEmail"
                  id="userEmail"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="Enter Email"
                  v-model="email"
                  required
                />
              </div>
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    required
                    dark:text-gray-300

                    required
                  "
                  >Phone:</label
                >
                <input
                  type="phone"
                  name="userPhone"
                  id="userPhone"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  v-model="phone"
                  placeholder="0300-0978601"
                  pattern="03[0-9]{9}"
                  required
                />
              </div>

              <div >
                <label
                  for="role"
                  class="
                  
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Role:</label
                >
                <multiselect
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    
                  "
                  :options="Roles"
                  open-direction="below"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :max-height="100"
                  :allow-empty="false"
                  :searchable="true"
                  select-label="false"
                  placeholder="Select"
                  name="userSelect"
                  id="roles"
                  track-by="id"
                  label="name"
                  v-model="role_id"
                >
                </multiselect>
              </div>
              <div class="flex justify-center pt-10">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded
                      text-sm
                      px-5
                      py-2
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Create User End -->

    <!-- Edit User  -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="editUserModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Edit User
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="editUserModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="editCreateUser">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Name:</label
                >
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="Enter Name"
                  required
                  v-model="name"
                />
              </div>
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Email:</label
                >
                <input
                  type="email"
                  name="userEmail"
                  id="userEmail"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  disabled
                  placeholder="Enter Email"
                  required
                  v-model="email"
                />
              </div>
              <!-- <div class="">
                <label
                  for="joinDate"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                  "
                  >Date Joining</label
                >
                <input
                  type="date"
                  name="date"
                  id="jnDate"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    block
                    w-full
                    px-2.5
                    py-2
                    dark:bg-gray-600
                    dark:border-gray-500
                    dark:placeholder-gray-400
                    dark:text-white
                  "
                />
              </div> -->
              <!-- <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                  "
                  >Role:</label
                >
                <input
                  type="text"
                  name="userRole"
                  id="userRole"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  required
                />
              </div> -->
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Phone:</label
                >
                <input
                  type="phone"
                  name="userPhone"
                  id="userPhone"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  v-model="phone"
                  placeholder="0300-0978601"
                  pattern="03[0-9]{9}"
                  required
                />
              </div>

              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Role:</label
                >
                <multiselect
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    z-10
                  "
                  :options="Roles ? Roles : []"
                  open-direction="bottom"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :max-height="100"
                  :allow-empty="false"
                  :searchable="true"
                  select-label="false"
                  placeholder="Select"
                  name="userSelect"
                  id="roles"
                  track-by="id"
                  label="name"
                  v-model="role_id"
                >
                </multiselect>
              </div>

              <div class="flex justify-center pt-10">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit User End -->
  </div>
</template>


<script>
// import $ from "jquery";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Header from "../layout/Header.vue";
export default {
  name: "Users",
  components: {
    Header,
  },

  data() {
    return {
      userSingle: "",
      userId: "",
      name: "",
      email: "",
      phone: "",
      role_id: null,
    };
  },

  methods: {
    ...mapActions([
      "fetchRoles",
      "createUser",
      "fetchAllUsers",
      "updateCreateUser",
      "activateUserStatus",
    ]),
    ...mapMutations(["toggleLoading"]),
    createUserModal() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.role_id = null;
      document.getElementById("modal").classList.toggle("hidden");
    },
    async editUserModal(user) {
      // console.log(user.id, "edit user id")

      document.getElementById("editUserModal").classList.toggle("hidden");

      if (user != null) {
        //   console.log(this.userSingle.forEach(element => {
        //   if(element.id  == user.id){
        //     user = element;
        //     console.log(user, "edited userrrrr")
        //   }
        // }), "1 edit User");
        this.name = user.name;
        this.userId = user.id;
        this.email = user.email;
        this.phone = user.phone;
        this.role_id = user.roles.length > 0 ? user.roles[0] : null;
      }
      // this.role_id=user.roles[0].id;
    },
    async addCreateUser(e) {
      e.preventDefault();
      let roleID = this.role_id.id;
      // console.log(roleID, 'role ID');
      const body = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        role_id: roleID,
      };
        console.log(body, "user body")
        this.toggleLoading(true);

      if (this.validateUser()) {
        console.log(body, "user body 2")
        let res = await this.createUser({
          name: this.name,
        email: this.email,
        phone: this.phone,
        role_id: roleID,
        });

        console.log(res, "RES body");
        if (res.success) {
          console.log("succes response");
          this.$toasted.success(res.msg);
          this.name = "";
          this.email = "";
          this.phone = "";
          this.role_id = "";
        } else {
          console.log("error response");
          this.$toasted.error(res.msg);
        }

        // console.log(body);
      }
        this.toggleLoading(false);
      this.createUserModal();


      // document.getElementById("modal").classList.toggle("hidden");

    },

    validateUser() {
      if (this.phone.length < 11) {
        this.$toasted.error("Invalid Phone number!");
        return false;
      }if(this.phone.length > 11){
        this.$toasted.error("Invalid Phone number!");
        return false;
      }
      else{
        return true;
      }
    },
    async editCreateUser() {
      this.toggleLoading(true);
      let res = await this.updateCreateUser({
        id: this.userId,
        name: this.name,
        email: this.email,
        phone: this.phone,
        role_id: this.role_id != null ? this.role_id.id : null,
      });
      console.log(res, "update user");
      // if (res.success) {
      //   this.$toasted.success(res.msg);
      //   this.name = "";
      //   this.email = "";
      //   this.phone = "";
      //   this.role_id = "";
      // } else {
      //   this.$toasted.error(res.msg);
      // }
      this.editUserModal(null);
      this.toggleLoading(false);
    },
    async activateStatus(user) {
      this.toggleLoading(true);
      let activeUser = 0;
      if (user.is_active == 1) {
        activeUser = 0;
      } else {
        activeUser = 1;
      }
      let res = await this.activateUserStatus({
        user_id: user.id,
        activation_status: activeUser,
      });
      if (res.success) {
        console.log("check");

        // if ($("#toggleUser").hasClass("fa-toggle-on")) {
        //   $("#toggleUser").removeClass("fa-toggle-on");
        //   $("#toggleUser").removeClass("text-blue-500");
        //   $("#toggleUser").addClass("fa-toggle-off");
        //   $("#toggleUser").addClass("text-black-50");
        // }else{
        //     $("#toggleUser").removeClass("fa-toggle-off");
        //   $("#toggleUser").addClass("fa-toggle-on");
        // }

        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
  },
  computed: {
    ...mapGetters(["Roles", "AllUsers", "myAbilities"]),
  },
  async created() {
    this.toggleLoading(true);
    await this.fetchRoles();
    await this.fetchAllUsers();
    this.toggleLoading(false);

    // this.userSingle = this.Users;
    //  console.log(this.Roles);
  },
};
</script>