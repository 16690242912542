<template>
  <div>
    <Header title="Permission" />
    <nav class="text-black-50 bg-white-100 text-sm px-3 pt-20">
      <div
        class="border-b border-black-200 py-2 flex justify-between items-center"
      >
        <ul class="flex">
          <li class="px-3 py-2" v-if="myAbilities.can('user_permission', 'pms')">
            <router-link to="/permission">
              <i class="fas fa-user-lock tetx-xs"></i> User Permisson
            </router-link>
          </li>
          <li class="px-3 py-2" v-if="myAbilities.can('role_permission', 'pms')">
            <router-link to="/rolepermission">
              <i class="fas fa-user-tag tetx-xs"></i> Role Permisson
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Content -->
    <div class="lg:py-8">
        <router-view :default="{path:'/'}" />
        
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from '../layout/Header.vue'
export default {
  name: "Permission",
  components: {
    Header
  },
   computed: {
    ...mapGetters([ "myAbilities"]),
  },

};
</script>