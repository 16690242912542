import Api from "../../helper/constant"
import TaskStore from './task.store'

const state = {
    taskQa: [],
}

const getters = {
    TaskQa: state => state.taskQa,
}


const actions = {
    async startQaTimer({ commit }, body) {
        let response = await Api.get(`/qa/start/${body.id}`)
        if (response.status == 200) {
            commit('startTaskQa', response.data.data)
            return { success: true, data: response.data.data, msg: response.data.message }
        }
        else {
            return { success: false, msg: response.data.message }
        }
    },
    async pauseQaTimer({ commit }, body) {
        let response = await Api.get(`/qa/pause/${body.id}`)
        if (response.status == 200) {
            commit('updateTaskQa', response.data.data)
            return { success: true, data: response.data.data, msg: response.data.message }
        }
        else {
            return { success: false, msg: response.data.message }
        }
    },
    async resumeQaTimer({ commit }, body) {
        let response = await Api.get(`/qa/resume/${body.id}`)
        if (response.status == 200) {
            commit('updateTaskQa', response.data.data)
            return { success: true, data: response.data.data, msg: response.data.message }
        }
        else {
            return { success: false, msg: response.data.message }
        }
    },
    async endQaTask({ commit }, body) {
        
        let response = await Api.put(`/qa/complete/${body.id}`, body)
        if (response.status == 200) {
            commit('updateTaskQa', response.data.data)
            commit('addQaComment', response.data.data.comment)
            return { success: true, data: response.data.data, msg: response.data.message }
        }
        else {
            return { success: false, msg: response.data.message }
        }
    }

}

const mutations = {


    startTaskQa: (state, payload) => {
        TaskStore.state.task.task_qa.push(payload)
    },


    updateTaskQa: (state, payload) => {
        if( TaskStore.state.task.task_qa.length === 0){
            TaskStore.state.task.task_qa.push(payload)
        }else{
            TaskStore.state.task.task_qa.find(el => {
                if(el.id == payload.id){
                    // el = payload;
                    el["is_pause"] = payload["is_pause"]
                    el["pause_time"] = payload["pause_time"]
                    el["resume_time"] = payload["resume_time"]
                    el["end_time"] = payload["end_time"]
                    el["is_pass"] = payload["is_pass"]
                    el["comment"] = payload["comment"]
                    el["is_completed"] = payload["is_completed"]
                }
            })
        }
        state.taskQa = payload
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}