import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import store from './store'
import "@fortawesome/fontawesome-free/css/all.min.css";
import Toasted from 'vue-toasted';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css";
import 'flowbite';
import http from './helper/constant'
import Vue2Editor from "vue2-editor";
import './assets/css/style.css'
import "vue-loading-overlay/dist/vue-loading.css";
import "fullcalendar/dist/fullcalendar.min.css";



Vue.prototype.$eventBus = new Vue();


Vue.config.productionTip = false

Vue.use(Toasted, {
  theme: 'bubble',
  position: 'top-center',
  duration: 3000,

})
Vue.use(Vue2Editor);

Vue.component('multiselect', Multiselect)
Vue.use(DatePicker);

//registering the axios request interceptors
http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
);

//registering the axios response interceptors
http.interceptors.response.use(
  response => {
    if (response.status < 300 || response.status > 100) {
      return Promise.resolve(response);
    }
    // else if(
    //   response.status === 202
    // ){
    //   return Promise.reject(response);

    // }
    else {
      return Promise.reject(response);
    }
  },
  error => {

    if (error.response.status) {
      switch (error.response.status) {

        case 400:

          //do something
          Vue.toasted.error(error.response.data)
          break;

        case 401:
          // alert("session expired");
          console.log(Toasted);
          Vue.toasted.error("session expired!")
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
          });
          break;
        case 403:
          Vue.toasted.error("Unauthorized!")
          router.replace({
            path: "/form",
            query: { redirect: router.currentRoute.fullPath }
          });
          break;
        case 404:
          Vue.toasted.error("Not Found")
          break;
        case 500:
          Vue.toasted.error("Internal server error!")
          // setTimeout(() => {
          //   router.replace({
          //     path: "/form",
          //     query: {
          //       redirect: router.currentRoute.fullPath
          //     }
          //   });
          // }, 1000);
      }
      return Promise.reject(error.response);
    }
  }
)



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
