import Api from "../../helper/constant"
import TaskStore from './task.store'

const state = {
    taskReview: []
}

const getters = {
    TaskReview: state => state.taskReview,
}

const actions = {
    async startReviewTimer({ commit }, body) {
        let response = await Api.get(`/review/start/${body.id}`)
        if (response.status == 200) {
            commit('startTaskReview', response.data.data)
            return { success: true, data: response.data.data, msg: response.data.message }
        }
        else {
            return { success: false, msg: response.data.message }
        }
    },

    async pauseReviewTimer({ commit }, body) {
        let response = await Api.get(`/review/pause/${body.id}`)
        if (response.status == 200) {
            commit('updateTaskReviewer', response.data.data)
            return { success: true, data: response.data.data, msg: response.data.message }
        }
        else {
            return { success: false, msg: response.data.message }
        }
    },

    async resumeReviewTimer({ commit }, body) {
        let response = await Api.get(`/review/resume/${body.id}`)
        if (response.status == 200) {
            commit('updateTaskReviewer', response.data.data)
            return { success: true, data: response.data.data, msg: response.data.message }
        }
        else {
            return { success: false, msg: response.data.message }
        }
    },

    async endReviewTask({ commit }, body) {
        let response = await Api.put(`/review/complete/${body.id}`, body)
        if (response.status == 200) {
            commit('updateTaskReviewer', response.data.data)
            return { success: true, data: response.data.data, msg: response.data.message }
        }
        else {
            return { success: false, msg: response.data.message }
        }
    }
}

const mutations = {
    startTaskReview:(state, payload) => {
        TaskStore.state.task.task_review.push(payload)
    },
    updateTaskReviewer: (state, payload) => {
        if (TaskStore.state.task.task_review.length == 0) {
            TaskStore.state.task.task_review.push(payload)
        } else {
            TaskStore.state.task.task_review.find(el => {
                if (el.id == payload.id) {
                    el["is_pause"] = payload["is_pause"]
                    el["pause_time"] = payload["pause_time"]
                    el["resume_time"] = payload["resume_time"]
                    el["end_time"] = payload["end_time"]
                    el["is_pass"] = payload["is_pass"]
                    el["comment"] = payload["comment"]
                    el["is_completed"] = payload["is_completed"]

                }
            })
        }
        state.taskReview = payload
    },
    
}


export default {
    state,
    getters,
    actions,
    mutations
}