import Api from "../../helper/constant"
// import RoleStore from './role.store'

const state = {
    roles: [],
    role: {
        name: ""
    }
}

const getters = {
   Roles: state => state.roles,
   Role: state => state.role
}

const actions = {
    
    

    // create new role
    async createRole({ commit }, body) {
        let response = await Api.post("/roles", body)
        if (response.status == 201) {
            commit('addRole', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },


    // Fetch Roles
    async fetchRoles({ commit }) {
        let response = await Api.get("/roles_list")
        if (response.status == 200) {
            commit('setRoles', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // update Role
    async updateAllRole({ commit }, body) {
        let response = await Api.put(`/roles/${body.id}`, body)
        if (response.status == 200) {
            commit('updateRole', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // delete role
    async deleteRole({ commit }, body) {
        let response = await Api.delete(`/roles/${body.id}`)
        if (response.status == 200) {
            commit('deleteRole', response.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    }
}

const mutations={
    setRoles: (state, payload) => {
        state.roles = payload
    },
    addRole: (state, payload) => {
        state.roles.push(payload);
    },
    updateRole: (state, payload) => {
        state.roles.find(el=> {
            if(el.id == payload.id){
                el.name = payload.name
            }
        })
    },
    deleteRole: (state, payload) => {
        let index = state.roles.findIndex(el => el.id == payload.data)
        state.roles.splice(index, 1);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}