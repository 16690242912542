import { defineAbility } from '@casl/ability'

//defining the permissions for a particular user
export default (user) => defineAbility((can) => {

    // let permissions = JSON.parse(localStorage.getItem('permissions'))
    let permissions = [];
    user = Object.keys(user).length == 0 ? JSON.parse(localStorage.getItem('user')) : user
    //saved permissions
    if (JSON.parse(localStorage.getItem('permissions')) && JSON.parse(localStorage.getItem('permissions')).length > 0) {
        permissions = JSON.parse(localStorage.getItem('permissions'))
    } else {
        //fetching role permissions
        if (user.roles.length > 0) {
            user.roles.forEach(role => {
                if (role.permissions.length > 0) {
                    permissions.push(...role.permissions);
                }
            })
        }
        //fetching user permissions 
        if (user.permissions.length > 0) {
            permissions.push(...user.permissions);
        }
    }
    // iterate and register all permissions
    if (permissions.length > 0) {
        localStorage.setItem('permissions', JSON.stringify(permissions));
        permissions.forEach(permission => {
            can(permission.slug, 'pms', { author: user.id })
        });
        can('access_granted', 'pms', { author: user.id })

    } else {
        can('access_denied', 'pms', { author: user.id })

    }


})