<template>
  <div>
    <Header
      :title="workspace.name"
      isEditable="true"
      @update-workspace="updateWorkspaceTitle"
    />
    Workspace Navbar
    <nav class="text-black-50 text-sm px-3 pt-10">
      <div
        class="border-b border-black-200 py-2 flex justify-between items-center"
      >
        <!-- Filters  -->
        <ul class="flex">
          <li class="px-2 py-2">
            <router-link to="/workspace">
              <i class="fas fa-clipboard-list tetx-xs"></i> Overview
            </router-link>
          </li>
        </ul>
        <!-- Archieve Workspace icon -->
        <div>
          <i
            class="fas fa-archive"
            @click="archiveWorkspace"
            v-if="myAbilities.can('delete_workspace', 'pms')"
          ></i>
        </div>
      </div>
    </nav>

    <!-- main container -->
    <div class="grid place-items-center">
      <div class="relative p-4 w-full max-w-5xl h-full md:h-auto">
        <a class="flex flex-col bg-white-100 md:flex-row">
          <div class="py-6 px-4">
            <div class="border-b-2 border-black-200 text-black-100 font-medium">
              <h4 class="mb-1">Description</h4>
            </div>
            <div class="py-3">
              <textarea
                v-if="myAbilities.can('update_workspace', 'pms')"
                name="description"
                id=""
                cols="30"
                rows="1"
                placeholder="Click to add workspace description..."
                class="
                  text-sm
                  border-0
                  rounded
                  tracking-tighter
                  focus:ring-1 focus:ring-black-100 focus:rounded-lg
                "
                v-model="workspace.description"
                @focusout="editWorkspace"
              ></textarea>
              <p v-else>
                {{ workspace.description }}
              </p>
            </div>
            <div class="py-4">
              <div
                class="border-b-2 border-black-200 text-black-100 font-medium"
              >
                <h4 class="mb-2">Members</h4>
              </div>
              <div>
                <div>
                  <multiselect
                    v-if="myAbilities.can('add_workspace_user', 'pms')"
                    class="
                      text-xs
                      w-full
                      border-0
                      mb-0
                      placeholder-mb-0
                      text-black-50
                      z-10
                    "
                    :options="filteredUsers"
                    :multiple="true"
                    options-direction="bottom"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    select-label="false"
                    placeholder="Select"
                    name="userSelect"
                    id="user"
                    track-by="id"
                    label="name"
                    v-model="workspaceMember"
                    @close="addWorkspaceMember"
                  >
                  </multiselect>
                </div>
                <div class="py-2 border mt-5 border-gray-400 rounded-md w-64">
                  <ul class="h-40 overflow-y-auto" id="myList">
                    <div
                      class="
                        px-2
                        flex
                        justify-between
                        items-center
                        relative
                        mb-3
                      "
                    >
                      <h4 class="font-medium mb-1" id="headingMembers">
                        Members
                      </h4>
                      <div class="">
                        <div
                          class="
                            flex
                            absolute
                            inset-y-0
                            right-0
                            items-center
                            pr-3
                            cursor-pointer
                          "
                          @click="searchField"
                        >
                          <svg
                            class="w-5 h-5 text-gray-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            id="searchIcon"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="searchInp"
                          class="
                            hidden
                            p-2
                            pr-10
                            w-full
                            text-gray-900
                            rounded-lg
                            border border-gray-50
                            sm:text-sm
                            focus:ring-blue-500 focus:border-blue-500
                          "
                          placeholder="Search..."
                          v-model="searchQuery"
                          v-on:keyup="Search"
                        />
                      </div>
                    </div>
                    <li
                      class="
                        flex
                        items-center
                        py-1
                        px-2
                        border-0
                        hover:border-b hover:border-gray-300
                        justify-between
                        cursor-pointer
                        hover:bg-gray-100
                        transform
                        hover:-translate-y-1
                      "
                      v-for="user in Workspace.user"
                      :key="user.id"
                    >
                      <div class="flex items-center">
                        <img
                          class="w-10 h-10 rounded-full mr-3"
                          src="../assets/images/user.jpg"
                          alt="Rounded avatar"
                        />
                        {{ user.name }}
                      </div>

                      <span
                        ><i
                          class="fas fa-times text-black-50 text-xs"
                          @click="deleteWorkspaceMember(user.id)"
                          v-if="myAbilities.can('remove_workspace_user', 'pms')"
                        ></i
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- Projects -->
          <div class="flex flex-col p-4 w-full leading-normal px-6 pt-7">
            <div
              class="tracking-tight border-b-2 border-black-200 text-black-100"
            >
              <h4 class="mb-2 font-medium">Projects</h4>
            </div>
            <!-- Project cards -->
            <div class="flex flex-wrap">
              <div class="pt-8 cursor-pointer" @click="toggleProjectModal">
                <div
                  class="
                    w-36
                    p-3
                    bg-white
                    rounded-3xl
                    h-52
                    border-0
                    mr-3
                    transition
                    duration-500
                    ease-in-out
                    hover:bg-gray-100
                    transform
                    hover:-translate-y-1 hover:text-black-50
                  "
                >
                  <a>
                    <div
                      class="
                        rounded-3xl
                        h-28
                        bg-white-100
                        border-dashed border-2 border-black-400
                        grid
                        place-items-center
                      "
                    >
                      <i class="fas fa-plus text-lg text-gray-400"></i>
                    </div>
                    <!-- <img
                    class="rounded-3xl h-28"
                    src="../assets/images/project2.png"
                    alt=""
                  /> -->
                  </a>
                  <div class="px-2 pb-5 pt-2">
                    <a>
                      <h5
                        class="
                          mb-2
                          font-medium
                          tracking-tight
                          text-gray-900
                          dark:text-white
                          text-center
                        "
                      >
                        New Project
                      </h5>
                    </a>
                  </div>
                </div>
              </div>
              <!-- 2nd -->
              <div
                class="pt-8 cursor-pointer"
                v-for="project in Workspace.project"
                :key="project.id"
              >
                <router-link :to="{ path: `/project/${project.id}` }">
                  <div
                    class="
                      w-36
                      p-3
                      bg-white
                      rounded-3xl
                      h-52
                      border-0
                      transition
                      duration-500
                      ease-in-out
                      hover:bg-gray-100
                      transform
                      hover:-translate-y-1
                    "
                  >
                    <a>
                      <img
                        class="rounded-3xl h-28"
                        src="../assets/images/project2.png"
                        alt=""
                      />
                    </a>
                    <div class="px-2 pb-5 pt-2">
                      <h5
                        class="
                          mb-2
                          font-medium
                          tracking-tight
                          text-gray-900
                          dark:text-white
                          text-center
                        "
                      >
                        {{ project.name }}
                      </h5>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!--Create Project Modal  -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="projectModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Content -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <!-- Modal header -->
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Create Project
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleProjectModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <!-- Modal body -->
          <div class="py-6 px-4 space-y-6 w-full">
            <form class="space-y-6" @submit.prevent="addProject">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-sm
                    font-medium
                    text-gray-900
                    dark:text-gray-300
                    required
                  "
                  >Project Name</label
                >
                <input
                  type="text"
                  name="ProjectName"
                  id="projectName"
                  class="
                    bg-white-100
                    border border-gray-400
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-500 focus:border-blue-500
                    block
                    w-full
                    p-2.5
                  "
                  v-model="project.name"
                  placeholder="Project Name"
                  required
                />
              </div>
              <div>
                <label
                  for="description"
                  class="
                    block
                    mb-2
                    text-sm
                    font-medium
                    text-gray-900
                    dark:text-gray-300
                  "
                  >Description</label
                >
                <input
                  type="text"
                  name="desc"
                  id="projectDesc"
                  class="
                    bg-white-100
                    border border-gray-400
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-500 focus:border-blue-500
                    block
                    w-full
                    p-2.5
                    dark:bg-gray-600
                    dark:border-gray-500
                    dark:placeholder-gray-400
                    dark:text-white
                  "
                  v-model="project.description"
                />
              </div>
              <div class="px-2">
                <ul>
                  <li class="text-black-50 border-b">Members</li>
                  <li>
                    <multiselect
                      class="
                        border border-gray-400
                        rounded-lg
                        text-gray-900 text-sm
                        mb-0
                        placeholder-mb-0
                      "
                      :options="Users"
                      :close-on-select="false"
                      open-direction="below"
                      :max-height="600"
                      :taggable="true"
                      :multiple="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :allow-empty="false"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Select"
                      name="userSelect"
                      id="user"
                      track-by="id"
                      label="name"
                      v-model="project.users"
                    >
                    </multiselect>
                  </li>
                </ul>
              </div>
              <div class="flex justify-center">
                <div class="flex items-center justify-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Validation from "../helper/validation";
import $ from "jquery";
import Header from "./layout/Header.vue";
export default {
  name: "Workspace",
  components: {
    Header,
  },
  data() {
    return {
      title: "Workspace",
      options: ["Noel", "Abc ", "Zain", "Saad"],
      blocked: false,
      workspaceMember: [],
      filteredUsers: [],
      workspace: {
        name: "",
        description: "",
      },
      project: {
        name: "",
        description: "",
        users: [],
      },
      searchQuery: "",
    };
  },
  methods: {
    ...mapActions([
      "fetchWorkspace",
      "storeWorkspaceMember",
      "updateWorkspace",
      "deleteWorkspace",
      "removeWorkspaceMember",
      "storeProject",
    ]),
    ...mapMutations(["setTitle", "toggleLoading"]),

    // toggle Projrct modal
    toggleProjectModal() {
      $("#projectModal").toggleClass("hidden");
    },
   
    block() {
      this.blocked = true;
      setTimeout(() => {
        this.blocked = false;
      }, 200);
    },

    // Search
    Search() {
      let value = $("#searchInp").val().toLowerCase();
      $("#myList li").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    },

    //add workspace members
    async addWorkspaceMember() {
      if (this.workspaceMember.length > 0) {
        this.toggleLoading(true);
        let memberIds = [];
        this.workspaceMember.forEach((el) => memberIds.push(el.id));
        let res = await this.storeWorkspaceMember({
          workspace_id: this.$route.params.id,
          user_id: memberIds,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
          this.workspaceMember = [];
        } else {
          this.$toasted.error(res.msg);
        }
        this.toggleLoading(false);
      }
    },

// Edit workspace
    async editWorkspace() {
      this.toggleLoading(true);
      let res = await this.updateWorkspace({
        id: this.Workspace.id,
        ...this.workspace,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    // Delete Workspace members
    async deleteWorkspaceMember(userId) {
      this.toggleLoading(true);
      let res = await this.removeWorkspaceMember({
        user_id: userId,
        workspace_id: this.Workspace.id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    // Delete workspace
    async archiveWorkspace() {
      this.toggleLoading(true);
      let res = await this.deleteWorkspace(this.Workspace.id);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.$router.replace({
          path: "/",
          query: { redirect: this.$router.currentRoute.fullPath },
        });
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
   
    // //add project
    async addProject() {
      if (Validation.validateProject(this.project)) {
        this.toggleLoading(true);
        let projectObj = {
          name: this.project.name,
          description: this.project.description,
        };
        if (this.project.users.length > 0) {
          let userIds = [];
          this.project.users.forEach((el) => userIds.push(el.id));
          projectObj["user_ids"] = userIds;
        }
        let res = await this.storeProject({
          ...projectObj,
          workspace_id: this.Workspace.id,
        });
        this.toggleLoading(false);

        if (res.success) {
          this.$toasted.success(res.msg);
          this.project.name = "";
          this.project.description = "";
          this.project.users = [];
          this.toggleProjectModal(null);
        } else {
          this.$toasted.error(res.msg);
        }
      }
    },
    async updateWorkspaceTitle(title) {
      this.toggleLoading(true);
      let res = await this.updateWorkspace({
        id: this.Workspace.id,
        name: title,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    // Search field in members list
    searchField() {
      $("#headingMembers").toggleClass("hidden");
        $("#searchIcon").toggleClass("mr-2");
        $("#searchInp").toggleClass("hidden");
    },
  },

  computed: {
    ...mapGetters(["Workspace", "Users", "myAbilities"]),
  },
  async created() {
    this.toggleLoading(true);
    let workspaceId = this.$route.params.id;
    await this.fetchWorkspace({ id: workspaceId });
    this.toggleLoading(false);
    //set the description
    this.workspace.description = this.Workspace.description;
    this.workspace.name = this.Workspace.name;
    //filter the workspace members
    this.filteredUsers = this.Users.filter((el) => {
      if (!this.Workspace.user.find((item) => item.id == el.id)) return el;
    });
  },
 
  mounted() {
    //set the description
    this.workspace.description = this.Workspace.description;
    this.workspace.name = this.Workspace.name;

    //filter the workspace members
    this.filteredUsers = this.Users.filter((el) => {
      if (!this.Workspace.user.find((item) => item.id == el.id)) return el;
    });
  },
};
</script>