<template>
  <div v-if="renderComponent">
    <Header :title="Project.name" :isEditable="true"  @update-project="updateProjectTitle"/>
    <!-- header -->
      <nav class="text-black-50 bg-white-100 text-sm px-3 top-16 fixed w-full" style="z-index:9">
        <div
          class="
            border-b border-black-200
            py-2
            flex
            justify-between
            items-center
          "
        >
          <!-- Filters  -->
          <ul
            class="container flex justify-between items-center "
            id="myTab"
            role="tablist"
          >
          <div class="flex flex-wrap -mb-px text-sm font-medium text-center">
            <li class="px-3 py-2">
              <router-link
                :to="{ path: `/projectOverview/${$route.params.id}` }"
              >
                <i class="fas fa-clipboard-list tetx-xs"></i> Overview
              </router-link>
            </li>
            <li class="px-3 py-2">
              <router-link :to="{ name : 'Detail' }" exact>
                <i class="fas fa-list text-xs"></i> List
              </router-link>
            </li>
            <li class="px-3 py-2">
              <router-link :to="{ name: 'TestKanban' }">
                <i class="fab fa-flipboard text-xs"></i> Board
              </router-link>
            </li>
            <li class="px-3 py-2">
              <router-link :to="{ name: 'Calendar' }">
                <i class="far fa-calendar text-xs"></i> Calendar
              </router-link>
            </li>
            <li class="px-3 py-2" v-if="myAbilities.can('get_project_document_files', 'pms')">
              <router-link :to="{ name: 'Files' }">
                
                <i class="far fa-file text-xs"></i> Files
              </router-link>
            </li>
            </div>
            <div class="flex justify-end items-center py-1 lg:pr-56 xl:pr-44">
              <!-- fILTERS -->
              <!-- <multiselect
                      class="
                        border-0
                        md:w-64
                        mr-2
                        rounded-lg
                        text-black-50 text-xs
                        mb-0
                        placeholder-mb-0
                        select
                      "
                      :options="Project.task_status"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select"
                  name="taskStatus"
                  id="user"
                  track-by="id"
                  label="name"
                      v-model="taskStatusId"
                      @select="saveStatus"
                      @input="filterTask()"
                      
                    >
                    </multiselect>

                    <multiselect
                      class="
                        border-0 
                        rounded-lg
                        text-gray-900 text-xs
                        md:w-64
                        mb-0
                        mr-2
                        placeholder-mb-0
                      "
                      :options="TaskPriority"
                      :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select"
                  name="taskPriority"
                  id="user"
                  track-by="id"
                  label="slug"
                      v-model="taskPriorityId"
                    >
                    </multiselect> -->


              <i
              v-if="myAbilities.can('store_project_documents', 'pms')"
                class="fas fa-paperclip text-black-50 mr-4"
                @click="toggleDocModal"
              ></i>

              <!-- <i
                class="fas fa-archive pr-4"
                @click="archiveProject"
                data-tooltip-target="archieve-tooltip"
                data-tooltip-placement="bottom"
                v-if="myAbilities.can('delete_project', 'pms')"
              ></i> -->
              <!-- <div
                  id="archieve-tooltip"
                  role="tooltip"
                  class="
                    inline-block
                    absolute
                    invisible
                    z-10
                    py-1
                    px-2
                    text-xs text-black-100
                    bg-white-100
                    rounded
                    border border-yellow-200
                    shadow-sm
                    opacity-0
                    tooltip
                  "
                >
                  Archive
                </div> -->
            </div>
          </ul>
          <!-- 
            Filters 
          <ul class="flex">
            <li class="">
              <div>
                <button
                  class="hover:bg-gray-300 px-3 rounded-md py-2"
                  data-dropdown-toggle="dropdownDivider"
                  
                >
                  <i class="fas fa-list text-xs"></i> List <i class="fas fa-caret-down pl-1"></i>
                </button>
                <div
                  id="dropdownDivider"
                  class="
                    hidden
                    right-50
                    z-10
                    w-32
                    bg-white-100
                    rounded
                    divide-y divide-gray-100
                    shadow
                    dark:bg-gray-700 dark:divide-gray-600
                  "
                >
                  <ul
                    class="py-1 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDividerButton"
                  >
                    <li>
                      <router-link
                        to="/project/"
                        class="
                          block
                          py-2
                          px-4
                          hover:bg-gray-100
                          dark:hover:bg-gray-600 dark:hover:text-white
                        "
                        >List</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to="/kanban"
                        class="
                          block
                          py-2
                          px-4
                          hover:bg-gray-100
                          dark:hover:bg-gray-600 dark:hover:text-white
                        "
                        >Kanban</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to=""
                        class="
                          block
                          py-2
                          px-4
                          hover:bg-gray-100
                          dark:hover:bg-gray-600 dark:hover:text-white
                        "
                        >Calendar</router-link
                      >
                    </li>
                  </ul>
                  
                </div>
              </div>
            </li>
            <li class="px-3 py-2">
              <router-link to="/kanban">
                <i class="fas fa-check-circle text-xs"></i> All tasks
              </router-link>
            </li>
            <li class="px-3 py-2">
              <i class="fas fa-sort-amount-down text-xs"></i> Filter
            </li>
            <li class="px-3 py-2">
              <i class="fas fa-sort-alpha-down text-xs"></i> Sort
            </li>
          </ul>
           -->
          <!-- Add, Create new options -->
          <ul class="flex text-black-50">
            <!-- <li class="px-3">Customize</li>
            <li class="px-3">
              <i class="fas fa-circle text-xs"></i> Create Link
            </li> -->
            
            <!-- <li class="px-3"><i class="fas fa-paperclip text-black-50" @click=""></i></li> -->
            <!-- <li class="px-3"><i class="fas fa-plus text-xs"></i> Add new</li> -->
            <!-- <li class="px-3"><i class="fas fa-ellipsis-h"></i></li> -->
          </ul>
        </div>
      </nav>
      <!-- List Header -->
      <router-view default="{path:'/'}" class="lg:pt-28 pt-40" :key="$route.name">
        <div>
          <ul>
            <!-- header -->
            <div class="px-3">
              <li
                class="
                  flex
                  border-b border-black-200
                  w-full
                  text-xs text-black-50
                  sticky
                "
              >
                <div
                  class="
                    w-10/12
                    border-r border-black-200
                    px-1
                    py-2
                    flex
                    justify-between
                  "
                >
                  <span>Task Name </span> <span>+</span>
                </div>

                <div class="py-2 px-3 border-r border-black-200 w-1/5">
                  Assignee
                </div>
                <div class="py-2 px-3 border-r border-black-200 w-1/5">
                  Due date ↓
                </div>
                <div class="py-2 px-3 border-r border-black-200 w-1/5">
                  Status
                </div>
                <div class="py-2 px-3 border-r border-black-200 w-1/5">
                  Priority
                </div>

                <div class="py-2 px-3"><i class="fas fa-plus text-xs"></i></div>
              </li>

              <!-- header end -->

              <!-- list  -->
              <div class="overflow-y-auto h-96" id="listBox">
                <li
                  class="
                    flex
                    border-b border-black-200
                    w-full
                    items-center
                    h-10
                  "
                >
                  <div
                    class="
                      w-3/4
                      border-r border-black-200
                      px-1
                      pl-5
                      py-2
                      flex
                      justify-between
                    "
                  >
                    <span class="flex items-center"
                      ><i
                        class="fas fa-check-circle text-xs text-gray-400 pr-1"
                      ></i>

                      <div
                        contenteditable="true"
                        class="focus:outline-gray leading-4"
                        v-on:keyup.enter.prevent="onEnter"
                      >
                        {{ this.task }}
                      </div>
                    </span>
                    <span
                      ><button
                        class="
                          absolute
                          text-white-100
                          bg-black-100
                          focus:ring-4 focus:outline-none focus:ring-blue-300
                          rounded-lg
                          text-xs
                          px-1
                          py-1
                          text-center
                        "
                        type="button"
                        data-modal-toggle="top-right-modal"
                      >
                        Details
                      </button></span
                    >
                  </div>

                  <!--Select for Users  -->
                  <div class="border-r border-black-200 py-1 w-1/5">
                    <select
                      name="Asignee"
                      id="UserSelect"
                      class="text-xs w-full border-0"
                    >
                      <option value="Name1" disabled selected>Select</option>
                      <option value="Name1">Neha Iqbal</option>
                      <option value="Name1" class="text-black-100">
                        Saumia
                      </option>
                    </select>
                  </div>
                  <!-- Date picker -->

                  <div
                    class="
                      border-r border-black-200
                      w-1/5
                      py-1
                      text-xs text-green-500
                    "
                  >
                    <date-picker
                      class="text-xs outline-none w-full pb-0"
                      valueType="format"
                      type="date"
                      placeholder=""
                      range
                    >
                    </date-picker>
                  </div>
                  <!-- Select for Status -->
                  <div class="w-1/5 py-1 border-r border-black-200">
                    <select
                      name="Asignee"
                      id="UserSelect"
                      class="text-xs px-3 w-full border-0"
                    >
                      <option value="Name1" selected>Pending</option>
                      <option value="Name1">InProgress</option>
                      <option value="Name1" class="text-black-100">
                        Complete
                      </option>
                    </select>
                  </div>
                  <!-- Select Priority -->
                  <div
                    class="w-1/5 py-1 border-r border-black-200"
                    @click="showList"
                  >
                    <select
                      name="Asignee"
                      id="UserSelect"
                      class="text-xs w-full border-0"
                    >
                      <option value="Name1" selected>Low</option>
                      <option value="Name1">Medium</option>
                      <option value="Name1" class="text-black-100">High</option>
                    </select>
                  </div>

                  <div class="py-2 px-3 text-white-100">si</div>
                </li>
                <!-- End -->

                <!-- New List -->

                <li id="addTask">Add Task</li>
              </div>
            </div>
          </ul>
          <button class="bg-blue-400 px-3 py-2" @click="makeCopy">Test</button>
        </div>
      </router-view>
    <!-- Detail Modal -->
    <div
      id="top-right-modal"
      data-modal-placement="top-right"
      tabindex="-1"
      class="
        hidden
        overflow-y-auto overflow-x-hidden
        fixed
        top-0
        right-0
        left-0
        z-50
        w-full
        md:inset-0
        h-modal
        md:h-full
      "
    >
      <div class=" p-4 w-full max-w-2xl h-full md:h-auto">
        <!-- Modal content -->
        <div class=" bg-white-100 rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b border-black-200
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-gray-900 dark:text-white">
              Top right modal
            </h3>
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              data-modal-toggle="top-right-modal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400"
            >
              With less than a month to go before the European Union enacts new
              consumer privacy laws for its citizens, companies around the world
              are updating their terms of service agreements to comply.
            </p>
            <p
              class="text-base leading-relaxed text-gray-500 dark:text-gray-400"
            >
              The European Union’s General Data Protection Regulation (G.D.P.R.)
              goes into effect on May 25 and is meant to ensure a common set of
              data rights in the European Union. It requires organizations to
              notify users as soon as possible of high-risk data breaches that
              could personally affect them.
            </p>
          </div>
          <!-- Modal footer -->
          <div
            class="
              flex
              items-center
              p-6
              space-x-2
              rounded-b
              border-t border-gray-200
              dark:border-gray-600
            "
          >
            <button
              data-modal-toggle="top-right-modal"
              type="button"
              class="
                text-white
                bg-blue-700
                hover:bg-blue-800
                focus:ring-4 focus:outline-none focus:ring-blue-300
                font-medium
                rounded-lg
                text-sm
                px-5
                py-2.5
                text-center
                dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
              "
            >
              I accept
            </button>
            <button
              data-modal-toggle="top-right-modal"
              type="button"
              class="
                text-gray-500
                bg-white
                hover:bg-gray-100
                focus:ring-4 focus:outline-none focus:ring-gray-200
                rounded-lg
                border border-gray-200
                text-sm
                font-medium
                px-5
                py-2.5
                hover:text-gray-900
                focus:z-10
                dark:bg-gray-700
                dark:text-gray-300
                dark:border-gray-500
                dark:hover:text-white
                dark:hover:bg-gray-600
                dark:focus:ring-gray-600
              "
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Document Modal  -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="docModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Add Files
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleDocModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="addDoc">
              <input
                class="
                  block
                  w-full
                  mb-5
                  text-sm text-gray-900
                  border border-gray-300
                  rounded-lg
                  cursor-pointer
                  bg-white-100
                  focus:outline-none
                  
                "
                id="docFile"
                type="file"
                @change="mapFile"
              />
              <div class="flex items-center"></div>

              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import { createPopper } from "@popperjs/core";
import Header from "../layout/Header.vue";
// import "vue-multiselect/dist/vue-multiselect.min.css";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Project",
  components: {
    // DatePicker,
    Header,
  },

  data() {
    return {
      options: ["aa", "bbb", "ccc"],
      dropdownPopoverShow: false,
      renderComponent: true,
      task: "",
      date: "",
      user: "",
      doc: {
        file: "",
      },
      taskStatusId: 0,
      taskPriorityId: 0,
      selectedSatus: "",
    };
  },
  methods: {
    ...mapMutations(["toggleLoading"]),
    ...mapActions([
      "fetchProject",
      "deleteProject",
      "storeProjectDoc",
      "fetchProjectDocs",
      "updateProject",
      "getFilteredTasks"
    ]),

      saveStatus(item){
          this.selectedSatus = item;
      this.taskStatusId = this.selectedSatus.id;
      },


    // filter task function
    async filterTask(){
      console.log(this.selectedSatus, "task status");
      console.log(this.Project.id, this.taskStatusId, this.taskPriorityId, "Task status and task priority Ids")
      const body = {
        projectId: this.Project.id,
        statusId: this.selectedSatus.id,
        priorityId: this.taskPriorityId,
      };
      console.log(body, "body")
      this.toggleLoading(true);
      let res = await this.getFilteredTasks(body)
      if (res.success) {
        this.taskStatusId = this.selectedSatus.id;
        this.$toasted.success(res.msg);
        // this.$router.go();
        // this.$forceUpdate();
        console.log(this.selectedSatus.id, "after response")
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);

    },


      // update project title
    async updateProjectTitle(title) {
      console.log(title)
      this.toggleLoading(true);
      let res = await this.updateProject({
        id: this.Project.id,
        workspace_id: this.Project.workspace_id,
        name:title,
      });
      if (res.success) {
        this.$toasted.success("Project Name Updated Successfully");
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    async archiveProject() {
      let res = await this.deleteProject({
        id: this.Project.id,
        workspace_id: this.Project.workspace_id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.$router.replace({
          path: "/",
          query: { redirect: this.$router.currentRoute.fullPath },
        });
      } else {
        this.$toasted.error(res.msg);
      }
    },
    // Click
    makeCopy() {
      let newItem = document.getElementById("listBox");
      console.log(newItem.lastChild);
      let a = document.getElementById("addTask");
      console.log(a.previousSibling);

      let list = a.previousSibling.append(
        <li class="flex border-b w-full" id="list">
          <div class="w-3/4 border-r px-1 pl-5 py-2 flex justify-between">
            <span class="flex items-center">
              <i class="fas fa-check-circle text-xs text-gray-400 pr-1"></i>

              <input
                type="text"
                class="focus:outline-none leading-4"
                onclick="makeCopy"
              />
            </span>
            <span>+</span>
          </div>

          <div class="w-1/6 border-r">
            <select
              name="Asignee"
              id="UserSelect"
              class="py-2 px-3 w-full border-0"
            >
              <option value="Name1" disabled selected>
                Select an option
              </option>
              <option value="Neha Iqbal">Neha Iqbal</option>
              <option value="Saumia" class="text-black-100">
                Saumia
              </option>
            </select>
          </div>

          <div class="py-2 px-3 border-r w-1/6 text-green-500">
            <input type="date" name="" id="" />
          </div>
          <div>
            <select name="Status" id="">
              <option value="Pending">Pending</option>
              <option value="InProgres">InProgres</option>
            </select>
          </div>
          <div class="py-2 px-3 text-white-100">si</div>
        </li>
      );
      console.log(list);
      // let newList = list.cloneNode(true);
      // newItem.append(newList)
    },
    toggleDocModal() {
      document.getElementById("docModal").classList.toggle("hidden");
    },
    mapFile(e) {
      this.doc.file = e.target.files[0];
    },
    async addDoc() {
      let formdata = new FormData();
      formdata.append("file", this.doc.file);
      formdata.append("project_id", this.$route.params.id);
      let res = await this.storeProjectDoc(formdata);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleDocModal();
      this.doc.file = "";
    },

    methodThatForcesUpdate() {
      // ...
      // this.$forceUpdate();
    },
    // forceRerender() {
    //   // Remove my-component from the DOM
    //   this.renderComponent = false;

    //   this.$nextTick(() => {
    //     // Add the component back in
    //     this.renderComponent = true;
    //   });
    // },

    check() {
      console.log("Hello World!!");
    },
    // Status List
    showList() {
      let item = document.getElementById("selectList");
      item.classList.replace("hidden", "block");
      // console.log(document.getElementById('selectList').classList)
    },
    // dropdown
    toggleDropdown(event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    // New List item on Enter Key
    onEnter(e) {
      e.preventDefault();
      console.log("NEw Item");
      console.log(this.task, this.user, this.date);
      let newItem = document.getElementById("listBox");
      console.log(newItem.lastChild);
      let list = newItem.lastChild;
      let newList = list.cloneNode(true);
      newItem.append(newList);
    },

    // addEventListener(){

    // }
  },
  props: {
    title: String,
  },
  computed: {
    ...mapGetters(["Project", "ProjectDocs", "myAbilities", "TaskPriority"]),
  },
  async created() {
    this.toggleLoading(true);
    let res = await this.fetchProject({ id: this.$route.params.id });
    if (!res.success) {
      this.$toasted.error(res.msg);
      this.$router.go(-1);
    }
    this.toggleLoading(false);
  },
  async mounted() {
    this.toggleLoading(true);
    let res = await this.fetchProject({ id: this.$route.params.id });
    if (!res.success) {
      this.$toasted.error(res.msg);
      this.$router.go(-1);
    }
    this.toggleLoading(false);
  },
};
</script>