<template>
  <div>
    <Header title="My Profile" :isEditable="false" />

    <div class="lg:flex place-items-center pt-20 px-16">
      <div
        class="
          m-2
          flex
          py-6
          h-full
          md:h-auto
          w-full
          bg-white
          rounded-lg
          border border-gray-300
          shadow-lg
          dark:bg-gray-800 dark:border-gray-700
          pb-10
        "
      >
        <div class="px-6">
          <div class="">
            <img
              class="w-40 h-36"
              src="../../assets/images/user-avatar.png"
              alt="Extra large avatar"
            />
          </div>
       
        </div>

        <div class="w-full px-3">
          <div>
            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">
              {{ user.name }}
            </h5>
          </div>
          <div
            @focusout="editDescription"
            v-if="myAbilities.can('update_profile', 'pms')"
          >
            <p class="font-medium text-lg">
              About
              <span
                ><i
                  class="fas fa-pen text-xs text-black-50 ml-5"
                  @click="editDesc"
                  data-tooltip-target="edit-tooltip"
                  data-tooltip-placement="bottom"
                ></i>
                <div
                  id="edit-tooltip"
                  role="tooltip"
                  class="
                    inline-block
                    absolute
                    invisible
                    z-10
                    py-1
                    px-2
                    text-xs text-black-100
                    bg-white-100
                    rounded
                    border border-yellow-200
                    shadow-sm
                    opacity-0
                    tooltip
                  "
                >
                  Edit
                </div>
              </span>
            </p>
            <p id="desc" class="px-1 py-1">{{ user.description }}</p>
          </div>
        </div>
      </div>
      <div
        class="
          flex
          m-2
          py-10
          h-full
          md:h-auto
          w-full
          bg-white
          rounded-lg
          border border-gray-300
          shadow-lg
          dark:bg-gray-800 dark:border-gray-700
        "
      >
        <div class="w-1/2 flex flex-col px-6">
          <div class="relative">
            <table class="w-full text-sm text-left dark:text-gray-400">
              <thead class="text-xs">
              </thead>
              <tbody>
                <tr
                  class="
                    bg-white
                    border-b
                    dark:bg-gray-800 dark:border-gray-700
                  "
                >
                  <th
                    scope="row"
                    class="
                      px-6
                      py-4
                      font-medium
                      text-gray-900
                      dark:text-white
                      whitespace-nowrap
                    "
                  >
                    Role:
                  </th>
                  <td class="px-4 py-3 flex items-center">
                    <div v-if="user.roles.length > 0">
                      <div class="py-1 px-2" id="role" v-if="!showMultiselect">
                        {{ user.roles[0].name }}
                      </div>
                    </div>
                    <div v-else>No Role Assigned</div>
                  </td>
                </tr>
                <tr
                  class="
                    bg-white
                    border-b
                    dark:bg-gray-800 dark:border-gray-700
                  "
                >
                  <th
                    scope="row"
                    class="
                      px-6
                      py-4
                      font-medium
                      text-gray-900
                      dark:text-white
                      whitespace-nowrap
                    "
                  >
                    Email:
                  </th>
                  <td class="px-4 py-3 flex items-center">
                    <div class="py-1 px-2" id="userEmail">
                      {{ user.email }}
                    </div>
                  </td>
                </tr>
                <tr class="bg-white dark:bg-gray-800">
                  <th
                    scope="row"
                    class="
                      px-6
                      py-4
                      font-medium
                      text-gray-900
                      dark:text-white
                      whitespace-nowrap
                    "
                  >
                    Phone No:
                  </th>
                  <td class="px-4 py-3 flex items-center">
                    <div
                      class="py-1 px-2"
                      id="userPhone"
                      @focusout="UpdatePhoneNo"
                    >
                      {{ user.phone }}
                    </div>
                    <i
                      v-if="myAbilities.can('update_profile', 'pms')"
                      class="fas fa-pen text-xs text-black-50 ml-5"
                      @click="editPhno"
                      data-tooltip-target="edit-tooltip"
                      data-tooltip-placement="bottom"
                    ></i>
                    <div
                      id="edit-tooltip"
                      role="tooltip"
                      class="
                        inline-block
                        absolute
                        invisible
                        z-10
                        py-1
                        px-2
                        text-xs text-black-100
                        bg-white-100
                        rounded
                        border border-yellow-200
                        shadow-sm
                        opacity-0
                        tooltip
                      "
                    >
                      Edit
                    </div>
                  </td>
                </tr>
                <tr
                  class="
                    px-10
                    py-4
                    font-medium
                    text-blue-600
                    dark:text-white
                    whitespace-nowrap
                  "
                  @click="showUpdPassword"
                >
                  Change Password
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="px-16 hidden"
      id="updatePassword"
      v-if="myAbilities.can('update_profile', 'pms')"
    >
      <div
        class="
          mt-10
          py-6
          px-3
          h-full
          md:h-auto
          w-full
          bg-white
          rounded-lg
          border border-gray-300
          shadow-lg
          dark:bg-gray-800 dark:border-gray-700
        "
      >
        <!-- Change Password -->
        <div>
          <h3 class="text-2xl font-medium text-gray-900 dark:text-white mb-4">
            Update Password
          </h3>

          <form @submit.prevent="editPassword">
            <div class="grid xl:grid-cols-3 xl:gap-6">
              <div class="mb-6">
                <label
                  for="old-password"
                  class="
                    block
                    mb-2
                    text-sm
                    font-medium
                    text-gray-900
                    dark:text-gray-300
                  "
                  >Old password</label
                >
                <input
                  type="password"
                  id="old-password"
                  class="
                    shadow-sm
                    border border-gray-50
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-500 focus:border-blue-500
                    block
                    w-full
                    p-2.5
                    dark:bg-gray-700
                    dark:border-gray-600
                    dark:placeholder-gray-400
                    dark:text-white
                    dark:focus:ring-blue-500
                    dark:focus:border-blue-500
                    dark:shadow-sm-light
                  "
                  required
                  v-model="oldpassword"
                />
              </div>
              <div class="mb-6">
                <label
                  for="password"
                  class="
                    block
                    mb-2
                    text-sm
                    font-medium
                    text-gray-900
                    dark:text-gray-300
                  "
                  >New password</label
                >
                <input
                  type="password"
                  id="password"
                  class="
                    shadow-sm
                    border border-gray-50
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-500 focus:border-blue-500
                    block
                    w-full
                    p-2.5
                    dark:bg-gray-700
                    dark:border-gray-600
                    dark:placeholder-gray-400
                    dark:text-white
                    dark:focus:ring-blue-500
                    dark:focus:border-blue-500
                    dark:shadow-sm-light
                  "
                  v-model="new_password"
                  required
                />
              </div>
              <div class="mb-6">
                <label
                  for="repeat-password"
                  class="
                    block
                    mb-2
                    text-sm
                    font-medium
                    text-gray-900
                    dark:text-gray-300
                  "
                  >Confirm password</label
                >
                <input
                  type="password"
                  id="confirm-password"
                  class="
                    shadow-sm
                    border border-gray-50
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-500 focus:border-blue-500
                    block
                    w-full
                    p-2.5
                    dark:bg-gray-700
                    dark:border-gray-600
                    dark:placeholder-gray-400
                    dark:text-white
                    dark:focus:ring-blue-500
                    dark:focus:border-blue-500
                    dark:shadow-sm-light
                  "
                  v-model="confirm_password"
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              class="bg-blue-500 text-white-100 px-2 py-1 rounded"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Header from "../layout/Header.vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "UserProfile",
  components: {
    Header,
  },
  data() {
    return {
      user: [],
      oldpassword: "",
      new_password: "",
      confirm_password: "",
      description: "",
      role_id: null,
      showMultiselect: false,

      phoneNo: null,
    };
  },
  methods: {
    ...mapActions([
      "updatePassword",
      "updateDesc",
      "fetchRoles",
      "updateRole",
      "updateProfile",
    ]),
    ...mapMutations(["toggleLoading"]),

// Edit Description
    editDesc() {
      let about = $("#desc");
      about.attr("contentEditable", true);
      about.filter(":visible").focus();
    },

    // Edit email
    editEmail() {
      let about = $("#userEmail");
      about.attr("contentEditable", true);
      about.filter(":visible").focus();
    },

    // Edit phone no
    async editPhno() {
      let about = $("#userPhone");
      about.attr("contentEditable", true);
      about.filter(":visible").focus();
    },

    // Update phone no
    async UpdatePhoneNo() {
      let about = $("#userPhone");
      let res = await this.updateProfile({
        id: this.user.id,
        phone: about[0].innerText,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.user = JSON.parse(localStorage.getItem("user"));
      } else {
        this.$toasted.error(res.msg);
      }
    },

    // Edit user role
    async editRole(item) {
      this.toggleLoading(true);
      let res = await this.updateRole({
        id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        role_id: item.id,
        phone: this.user.phone,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.showMultiselect = false;
        this.user = JSON.parse(localStorage.getItem("user"));
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

// toggle workspace
    toggleWorkspace() {
      if ($("#projList").hasClass("hidden")) {
        $("#projList").removeClass("hidden");
        $("#toggleIcon").removeClass("fa-caret-down");
        $("#toggleIcon").addClass("fa-caret-up");
      } else {
        $("#projList").addClass("hidden");
        $("#toggleIcon").addClass("fa-caret-down");
      }
    },

    // Show password fields
    showUpdPassword() {
      document.getElementById("updatePassword").classList.remove("hidden");
    },

    // Edit password
    async editPassword() {
      this.toggleLoading(true);
  // checking new password and confirm password
      if (this.confirm_password == this.new_password) {
        let res = await this.updatePassword({
          oldpassword: this.oldpassword,
          new_password: this.new_password,
          confirm_password: this.confirm_password,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
          this.$router.go();
          this.oldpassword = "";
          this.new_password = "";
          this.confirm_password = "";
          document.getElementById("updatePassword").classList.add("invisible");
        } else {
          this.$toasted.error(res.msg);
        }
      }else{
        this.$toasted.error("New password and Confirm password must be same!")
      }

      this.toggleLoading(false);
    },
    showMul(user) {
      this.showMultiselect = true;
      this.role_id = user.roles.length > 0 ? user.roles[0] : null;
    },
    async editDescription() {
      let edit = $("#desc");
      this.toggleLoading(true);
      let res = await this.updateDesc({
        id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        description: edit[0].innerText,
        phone: this.user.phone,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.user = JSON.parse(localStorage.getItem("user"));
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
  },
  computed: {
    ...mapGetters(["Roles", "Users", "myAbilities"]),
  },
  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.Roles.length == 0) {
      this.fetchRoles();
    }
  },
};
</script>