import axios from "axios";

export default axios.create({
    // baseURL: "https://newpms.futureplan.tech/api",
    baseURL: "https://pmsbackend.futureplan.cloud/api",
    
    headers: {
        'Content-Type': 'Application/json',
        'Accept': 'Application/json'
    }
})


// export default {
//     url: ' https://newpms.futureplan.tech/api',
//     // url: 'http://localhost:8000/api',
//     //    url:'https://mngbackend.outcastsolutions.us/api',
//     header: (token) => {
//         return {
//             'Content-Type': 'Application/json',
//             'Authorization': `Bearer ${token}`,
//             'Accept': 'Application/json'
//         }
//     }

// }