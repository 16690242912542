import api from "../../helper/constant"

const state = {
    users: [],
    allUsers: [],
    activationStatus: true,

}

const getters = {
    Users: state => state.users,
    AllUsers: state => state.allUsers,
    ActivationStatus: state => state.activationStatus

}

const actions = {
    //to get users list
    async fetchAllUsers({ commit }) {
        let response = await api.get(`/all_users`)
        commit("setAllUsers", response.data.data);
    },


    // only active users
    async fetchUsers({ commit }) {
        let response = await api.get(`/users_list`)
        commit("setUsers", response);
    },

    //to create user
    async createUser({ commit }, body) {
        try {
            let response = await api.post(`/create_user`, body)
            if (response.status == 200) {
                console.log("success");
                commit('addCreateUser');
                return { success: true, msg: response.data.message }
            } else {
                return { success: false, msg: "Unexpected Error" }
            }
        } catch (e) {
            return { success: false, msg: "Unexpected Error" }
        }

        // if (response.status == 200) {
        // console.log("success");
        //     commit('addCreateUser', response.data.data)
        //     return { success: true, msg: response.data.message }
        // }else{
        // console.log("others");
        //     return { success: false, msg: "Unexpected Error" }
        // }
    },


    //to edit create user
    async updateCreateUser({ commit }, body) {
        let response = await api.put(`/update_user/${body.id}`, body);
        if (response.status == 200) {
            commit('updateAllUsers', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    //to update password
    async updatePassword({ commit }, body) {
        let response = await api.post(`/change_password`, body);
        if (response.status == 200) {
            commit('updateUserPassword', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // to update user description
    async updateDesc({ commit }, body) {
        let response = await api.post(`/update_profile`, body);
        if (response.status == 200) {
            localStorage.setItem('user', JSON.stringify(response.data.data))
            commit('updateUser', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }
    },

    //to update role
    async updateRole({ commit }, body) {
        let response = await api.put(`/update_user/${body.id}`, body);
        if (response.status == 200) {
            localStorage.setItem('user', JSON.stringify(response.data.data))
            commit('updateUser', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },


    // Active deactive user
    async activateUserStatus({ commit }, body) {
        let response = await api.post(`/user_account`, body);
        if (response.status == 200) {
            commit('updateAllUsers', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }
    },

    // Update Profile 
    async updateProfile({ commit }, body) {
        let response = await api.post(`/update_profile`, body);
        if (response.status == 200) {
            commit('updateUser', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    }
}

const mutations = {
    // all users
    setAllUsers: (state, payload) => {
        state.allUsers = payload
    },

    // to set new created user (active users)
    setUsers: (state, payload) => {
        state.users = payload;
    },

    // add new created user in users 
    addCreateUser: (state, payload) => {
        // state.users.push(payload)
        console.log(payload, "state payload")
    },
    // update All Users
    updateAllUsers: (state, payload) => {
        state.allUsers.find(el => {
            if (el.id == payload.id) {
                el.name = payload.name
                el.roles = payload.roles
                el.phone = payload.phone
                el.email = payload.email
                el.is_active = payload.is_active

            }
        })
    },


    //update user
    updateUser: (state, payload) => {
        state.users.find(el => {
            if (el.id == payload.id) {
                el.name = payload.name
                el.roles = payload.roles
                el.phone = payload.phone
                el.email = payload.email
                el.is_active = payload.activation_status

            }
        })
    },

    //update password of user

    activateUser: (state, payload) => {
        state.activationStatus = payload
    }


}

export default {
    state,
    getters,
    actions,
    mutations
}