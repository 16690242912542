import Api from "../../helper/constant"

const state = {
    workspaces: [],
    workspacesWithProjects: [],
    workspace: {},
    workspaceUsers: []
}

const getters = {
    getWorkspaces: state => state.workspaces,
    Workspace: state => state.workspace,
    WorkspaceUsers: state => state.workspaceUsers
}

const actions = {
    // fetch all workspaces
    async fetchWorkspaces({ commit }) {
        let response = await Api.get(`/get_workspace_project`)
        if (response.status == 200) {
            commit('setWorkspaces',
                response.data.data
            )
        }
    },

    // store new workspace
    async storeWorkspace({ commit }, body) {
        let response = await Api.post("/add_workspaces", body);
        if (response.status == 200) {
            commit('addWorkspace', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }

    },

    // fetch single workspace
    async fetchWorkspace({ commit }, body) {
        let response = await Api.get(`/single_workspace/${body.id}`);
        commit('setWorkspace', response.data.data)

    },

    // store workspace member 
    async storeWorkspaceMember({ commit }, body) {
        let response = await Api.post("/add_workspace_users", body);
        if (response.status == 200) {
            commit('setWorkspace', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }
    },

    // remove workspace member
    async removeWorkspaceMember({ commit }, body) {
        let response = await Api.post("/delete_workspace_user", body);
        if (response.status == 200) {
            commit('setWorkspace', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }
    },

    // update workspace member
    async updateWorkspace({ commit }, body) {
        let response = await Api.put(`/workspace/${body.id}`, body);
        if (response.status == 200) {
            commit('updateWorkspace', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }
    },

    // remove workspace
    async deleteWorkspace({ commit }, body) {
        let response = await Api.delete(`/workspace/${body}`);
        if(response.status == 200){
        commit('archiveWorkspace', body)
        return { success: true, msg: response.data.message }
    } else {
        return { success: false, msg: response.data.message }

    }
    },

    // fetch workspace members
    async fetchWorkspaceMembers({ commit }, body) {
        let response = await Api.get(`/workspace_members/${body.id}`);
        if (response.status == 200) {
            commit('setWorkspaceMember', response.data.data)
            return response.data.message
        }

    }
}

const mutations = {
    setWorkspaces: (state, payload) => {
        state.workspaces = payload.workspace
    },
    setWorkspace: (state, payload) => {
        state.workspace = payload
    },
    setWorkspaceMember: (state, payload) => {

        state.workspaceUsers = payload.user
    },
    addWorkspace: (state, payload) => {
        state.workspaces.push(payload);
    },
    updateWorkspace: (state, payload) => {
        state.workspace = payload
        state.workspaces.find(el=>{
            if(el.id == payload.id){
                el.name = payload.name
            }
        })
    },
    archiveWorkspace: (state, payload) => {
        let workspaceIndex = state.workspaces.findIndex(el => el.id == payload)
        state.workspaces.splice(workspaceIndex, 1);

    }

}

export default {
    state,
    getters,
    actions,
    mutations
}