<template>
  <div>
    <Header title="Dashboard" />
    <div class="mt pt-32 flex justify-center md:px-20 pl-40">
      <div
        class="
          items-center
          justify-between
          p-5
          2xl:w-3/4
          md:w-full
          bg-white
          rounded
          shadow-md
        "
      >
        <!-- Dashboard tabs -->

        <ul
          class="
            text-sm
            font-medium
            text-center text-gray-500
            rounded-lg
            divide-x divide-gray-200
            shadow
            flex
            dark:divide-gray-700 dark:text-gray-400
          "
        >
          <li class="w-full">
            <a
              id="tabTask"
              @click="tasksTab"
              class="
                inline-block
                flex flex-col
                p-4
                w-full
                text-black-100
                bg-gray-100
                rounded-l-lg
                focus:ring-4 focus:ring-blue-300 focus:outline-none
                dark:bg-gray-700 dark:text-white
              "
              aria-current="page"
              ><span
                >{{ taskCount.total_completed }}/{{ taskCount.total_due }}</span
              >
              <span>Tasks</span></a
            >
          </li>
          <li class="w-full">
            <a
              id="attentab"
              @click="attendanceTab"
              class="
                inline-block
                flex flex-col
                p-4
                w-full
                bg-white
                focus:ring-4 focus:ring-blue-300 focus:outline-none
                dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700
              "
            >
              <div class="">
                <span>{{ totalPresent }}/{{ totalUsers }}</span>
                <span class="mx-2">{{ presentRatio }}%</span>
              </div>
              <span>Attendance</span>
            </a>
          </li>
          <li class="w-full">
            <a
              id="resourcetab"
              @click="ResourcesTab"
              class="
                inline-block
                flex flex-col
                p-4
                w-full
                bg-white
                focus:ring-4 focus:ring-blue-300 focus:outline-none
                dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700
              "
            >
              <span>{{ resourcesCount }}</span>
              <span>Free Resources</span>
            </a>
          </li>
        </ul>
        <!-- Dashboard tabs end -->

        <!-- Tab for tasks -->
        <div class="mt-10" id="taskdiv">
          <div class="mt-5 flex flex-wrap justify-between justify-start py-2">
            <!-- Workspace List -->
            <div
              class="lg:w-1/2 md:w-full"
              v-for="workspace in workspaceTaskCount"
              :key="workspace.id"
            >
              <div
                class="bg-gray-300 p-2 px-3 rounded shadow-md m-1"
                :id="`workspace`"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center justify-between">
                    <span
                      class="
                        border-dashed border-2 border-black-50
                        rounded-lg
                        py-2
                        px-5
                        bg-red-400
                        m-2
                        h-12
                      "
                    ></span>
                    <h4 class="font-medium text-lg w-48">
                      {{ workspace.workspace_name }}
                    </h4>
                  </div>
                  <span class="flex justify-between items-center"
                    ><p class="text-lg font-medium mx-10">
                      {{ workspace.total_completed }}/{{ workspace.total_due }}
                    </p>
                    <i
                      class="fas fa-caret-down cursor-pointer"
                      @click="
                        toggleWorkspace(`wkspace${workspace.workspace_id}`)
                      "
                      :class="`Arrowwkspace${workspace.workspace_id}`"
                    ></i
                  ></span>
                </div>
                <div
                  class="py-2 px-3 hidden z-10"
                  :class="`Projectwkspace${workspace.workspace_id}`"
                >
                  <ul>
                    <li
                      class=""
                      v-for="project in workspace.project_data"
                      :key="project.id"
                    >
                      <div class="py-2 border-0 border-b flex justify-between">
                        <router-link
                          :to="{ path: `/project/${project.project_id}` }"
                          ><span class="w-44">{{
                            project.project_name
                          }}</span></router-link
                        >

                        <span class="flex justify-between items-center">
                          <span class="mx-10"
                            >{{ project.total_completed }} /
                            {{ project.total_due }}</span
                          >
                          <i
                            class="fas fa-caret-down"
                            @click="toggleList(`${project.project_id}`)"
                            :class="`listArrow${project.project_id}`"
                          ></i
                        ></span>
                      </div>
                      <!-- Project Incomplete Task List -->
                      <div
                        class="py-2 px-3 hidden z-10 shadow-inner"
                        :class="`pTask${project.project_id}`"
                      >
                        <ul>
                          <router-link
                            :to="{ path: `/project/${project.project_id}` }"
                          >
                            <li
                              class="
                                py-2
                                border-0 border-b
                                flex
                                justify-between
                              "
                              v-for="task in project.incompleted_task"
                              :key="task.id"
                            >
                              <span class="w-64 text-truncate">{{
                                task.name
                              }}</span>
                              <div
                                class="group relative flex items-center"
                                style="width: fit-content"
                              >
                                <img
                                  class="
                                    mr-1
                                    mb-3
                                    w-10
                                    h-10
                                    rounded-full
                                    shadow-lg
                                  "
                                  src="../../assets/images/user.jpg"
                                  alt="Bonnie image"
                                />
                                {{ task.user.name }}
                                <div
                                  class="
                                    group-hover:transform
                                    -translate-y-1
                                    absolute
                                    inline-block
                                    bg-white-100
                                    opacity-0
                                    top-3/4
                                    rounded-xl
                                    shadow-md
                                    group-hover:opacity-100
                                    px-4
                                    z-10
                                    w-56
                                  "
                                >
                                  <div class="flex justify-end px-4 pt-4"></div>
                                  <div class="flex flex-col items-center pb-10">
                                    <img
                                      class="
                                        mb-3
                                        w-20
                                        h-20
                                        rounded-full
                                        shadow-lg
                                      "
                                      src="../../assets/images/user.jpg"
                                      alt="Bonnie image"
                                    />
                                    <h5
                                      class="
                                        mb-1
                                        text-xl
                                        font-medium
                                        text-gray-900
                                        dark:text-white
                                      "
                                    >
                                      {{ task.user.name }}
                                    </h5>
                                    <span
                                      class="
                                        text-sm text-gray-500
                                        dark:text-gray-400
                                      "
                                      >{{ task.user.email }}</span
                                    >
                                    <span
                                      class="
                                        text-sm text-gray-500
                                        dark:text-gray-400
                                      "
                                      >{{ task.user.phone }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </li>
                          </router-link>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End -->

        <!-- Tab for Attendance -->
        <div class="hidden" id="attendanceDiv">
          <div class="flex flex-wrap justify-between justify-start py-5 mt-2">
            <!-- All Users Attendance Card -->
            <div class="lg:w-1/2 md:w-full">
              <div
                class="bg-gray-300 p-2 px-3 rounded shadow-md m-1"
                :id="`workspace`"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center justify-between">
                    <span
                      class="
                        border-dashed border-2 border-black-50
                        rounded-lg
                        py-2
                        px-5
                        bg-red-400
                        m-2
                        h-12
                      "
                    ></span>
                    <h4 class="font-medium text-lg w-48">All Workspaces</h4>
                  </div>
                  <span class="flex justify-between items-center"
                    ><p class="text-lg font-medium mx-10">
                      {{ totalPresent }}/{{ totalUsers }}
                    </p>
                    <i
                      class="fas fa-caret-down cursor-pointer"
                      @click="toggleAllAbsent()"
                      id="absentArrow"
                    ></i
                  ></span>
                </div>
                <div class="py-2 px-3 hidden shadow-inner z-10" id="absentList">
                  <ul>
                    <li
                      class=""
                      v-for="absentUser in absentUsers"
                      :key="absentUser.id"
                    >
                      <div
                        class="group relative flex items-center"
                        style="width: fit-content"
                      >
                        <img
                          class="mr-1 mb-3 w-10 h-10 rounded-full shadow-lg"
                          src="../../assets/images/user.jpg"
                          alt="Bonnie image"
                        />
                        {{ absentUser.name }}
                        <div
                          class="
                            group-hover:transform
                            -translate-y-1
                            absolute
                            inline-block
                            bg-white-100
                            opacity-0
                            top-3/4
                            rounded-xl
                            shadow-md
                            group-hover:opacity-100
                            px-4
                            z-10
                            w-56
                          "
                        >
                          <div class="flex justify-end px-4 pt-4"></div>
                          <div class="flex flex-col items-center pb-10">
                            <img
                              class="mb-3 w-20 h-20 rounded-full shadow-lg"
                              src="../../assets/images/user.jpg"
                              alt="Bonnie image"
                            />
                            <h5
                              class="
                                mb-1
                                text-xl
                                font-medium
                                text-gray-900
                                dark:text-white
                              "
                            >
                              {{ absentUser.name }}
                            </h5>
                            <span
                              class="text-sm text-gray-500 dark:text-gray-400"
                              >{{ absentUser.email }}</span
                            >
                            <span
                              class="text-sm text-gray-500 dark:text-gray-400"
                              >{{ absentUser.phone }}</span
                            >

                            <!-- <div class="flex mt-4 space-x-3 lg:mt-6">
            <router-link to="/profile" class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">View Profile</router-link>
        </div> -->
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Workspace Attendance Card -->
            <div
              class="lg:w-1/2 md:w-full"
              v-for="workspace in workspaceAttendance"
              :key="workspace.id"
            >
              <div
                class="bg-gray-300 p-2 px-3 rounded shadow-md m-1"
                :id="`workspace`"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center justify-between">
                    <span
                      class="
                        border-dashed border-2 border-black-50
                        rounded-lg
                        py-2
                        px-5
                        bg-red-400
                        m-2
                        h-12
                      "
                    ></span>
                    <h4 class="font-medium text-lg w-48">
                      {{ workspace.name }}
                    </h4>
                  </div>
                  <span class="flex justify-between items-center"
                    ><p class="text-lg font-medium mx-10">
                      {{ workspace.total_present }}/{{ workspace.total_users }}
                    </p>
                    <i
                      class="fas fa-caret-down cursor-pointer"
                      @click="toggleAttendance(`wkspace${workspace.id}`)"
                      :class="`ArrowAtwkspace${workspace.id}`"
                    ></i
                  ></span>
                </div>
                <div
                  class="py-2 px-3 hidden shadow-inner z-10"
                  :class="`attendancewkspace${workspace.id}`"
                >
                  <ul>
                    <li
                      class=""
                      v-for="absentUser in workspace.absent_user"
                      :key="absentUser.id"
                    >
                      <div
                        class="group relative flex items-center"
                        style="width: fit-content"
                      >
                        <img
                          class="mr-1 mb-3 w-10 h-10 rounded-full shadow-lg"
                          src="../../assets/images/user.jpg"
                          alt="Bonnie image"
                        />
                        <a class="">{{ absentUser.name }}</a>
                        <!-- User Detail card on hover-->
                        <div
                          class="
                            group-hover:transform
                            -translate-y-1
                            absolute
                            inline-block
                            bg-white-100
                            opacity-0
                            top-3/4
                            rounded-xl
                            shadow-md
                            group-hover:opacity-100
                            px-4
                            z-10
                            w-56
                          "
                        >
                          <div class="flex justify-end px-4 pt-4"></div>
                          <div class="flex flex-col items-center pb-10">
                            <img
                              class="mb-3 w-20 h-20 rounded-full shadow-lg"
                              src="../../assets/images/user.jpg"
                              alt="Bonnie image"
                            />
                            <h5
                              class="
                                mb-1
                                text-xl
                                font-medium
                                text-gray-900
                                dark:text-white
                              "
                            >
                              {{ absentUser.name }}
                            </h5>
                            <span
                              class="text-sm text-gray-500 dark:text-gray-400"
                              >{{ absentUser.email }}</span
                            >
                            <span
                              class="text-sm text-gray-500 dark:text-gray-400"
                              >{{ absentUser.phone }}</span
                            >

                            <!-- <div class="flex mt-4 space-x-3 lg:mt-6">
            <router-link to="/profile" class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">View Profile</router-link>
        </div> -->
                          </div>
                        </div>
                        <!-- User Detail card end -->
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Attendance tab end -->

        <!-- Tab for resources -->
        <div class="hidden" id="resourcesDiv">
          <div class="flex flex-wrap justify-between justify-start py-5 mt-2">
            <!-- All Free Resources Card -->
            <div class="lg:w-1/2 md:w-full">
              <div
                class="bg-gray-300 p-2 px-3 rounded shadow-md m-1"
                :id="`workspace`"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center justify-between">
                    <span
                      class="
                        border-dashed border-2 border-black-50
                        rounded-lg
                        py-2
                        px-5
                        bg-red-400
                        m-2
                        h-12
                      "
                    ></span>
                    <h4 class="font-medium text-lg w-48">All Free Resources</h4>
                  </div>
                  <span class="flex justify-between items-center"
                    ><p class="text-lg font-medium mx-10">
                      {{ resourcesCount }}
                    </p>
                    <i
                      class="fas fa-caret-down cursor-pointer"
                      @click="toggleAllResources()"
                      id="resourceArrow"
                    ></i
                  ></span>
                </div>
                <div
                  class="py-2 px-3 hidden shadow-inner z-10"
                  id="resourcesList"
                >
                  <ul>
                    <li
                      class=""
                      v-for="freeResource in freeResources"
                      :key="freeResource.id"
                    >
                      <div
                        class="group relative flex items-center"
                        style="width: fit-content"
                      >
                        <img
                          class="mr-1 mb-3 w-10 h-10 rounded-full shadow-lg"
                          src="../../assets/images/user.jpg"
                          alt="Bonnie image"
                        />
                        {{ freeResource.name }}
                        <div
                          class="
                            group-hover:transform
                            -translate-y-1
                            absolute
                            inline-block
                            bg-white-100
                            opacity-0
                            top-3/4
                            rounded-xl
                            shadow-md
                            group-hover:opacity-100
                            px-4
                            z-10
                            w-56
                          "
                        >
                          <div class="flex justify-end px-4 pt-4"></div>
                          <div class="flex flex-col items-center pb-10">
                            <img
                              class="mb-3 w-20 h-20 rounded-full shadow-lg"
                              src="../../assets/images/user.jpg"
                              alt="Bonnie image"
                            />
                            <h5
                              class="
                                mb-1
                                text-xl
                                font-medium
                                text-gray-900
                                dark:text-white
                              "
                            >
                              {{ freeResource.name }}
                            </h5>
                            <span
                              class="text-sm text-gray-500 dark:text-gray-400"
                              >{{ freeResource.email }}</span
                            >
                            <span
                              class="text-sm text-gray-500 dark:text-gray-400"
                              >{{ freeResource.phone }}</span
                            >

                            <!-- <div class="flex mt-4 space-x-3 lg:mt-6">
            <router-link to="/profile" class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">View Profile</router-link>
        </div> -->
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end -->

            <!-- Workspace Free Resource Card -->
            <div
              class="lg:w-1/2 md:w-full"
              v-for="workspace in workspaceResource"
              :key="workspace.id"
            >
              <div
                class="bg-gray-300 p-2 px-3 rounded shadow-md m-1"
                :id="`workspace`"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center justify-between">
                    <span
                      class="
                        border-dashed border-2 border-black-50
                        rounded-lg
                        py-2
                        px-5
                        bg-red-400
                        m-2
                        h-12
                      "
                    ></span>
                    <h4 class="font-medium text-lg w-48">
                      {{ workspace.name }}
                    </h4>
                  </div>
                  <span class="flex justify-between items-center"
                    ><p class="text-lg font-medium mx-10">
                      {{ workspace.count }}
                    </p>
                    <i
                      class="fas fa-caret-down cursor-pointer"
                      @click="toggleResource(`wkspace${workspace.id}`)"
                      :class="`ArrowRwkspace${workspace.id}`"
                    ></i
                  ></span>
                </div>
                <div
                  class="py-2 px-3 hidden shadow-inner z-10"
                  :class="`resourcewkspace${workspace.id}`"
                >
                  <ul>
                    <li
                      class=""
                      v-for="freeResource in workspace.free_resources"
                      :key="freeResource.id"
                    >
                      <div
                        class="group relative flex items-center"
                        style="width: fit-content"
                      >
                        <img
                          class="mr-1 mb-3 w-10 h-10 rounded-full shadow-lg"
                          src="../../assets/images/user.jpg"
                          alt="Bonnie image"
                        />
                        {{ freeResource.name }}
                        <div
                          class="
                            group-hover:transform
                            -translate-y-1
                            absolute
                            inline-block
                            bg-white-100
                            opacity-0
                            top-3/4
                            rounded-xl
                            shadow-md
                            group-hover:opacity-100
                            px-4
                            z-10
                            w-56
                          "
                        >
                          <div class="flex justify-end px-4 pt-4"></div>
                          <div class="flex flex-col items-center pb-10">
                            <img
                              class="mb-3 w-20 h-20 rounded-full shadow-lg"
                              src="../../assets/images/user.jpg"
                              alt="Bonnie image"
                            />
                            <h5
                              class="
                                mb-1
                                text-xl
                                font-medium
                                text-gray-900
                                dark:text-white
                              "
                            >
                              {{ freeResource.name }}
                            </h5>
                            <span
                              class="text-sm text-gray-500 dark:text-gray-400"
                              >{{ freeResource.email }}</span
                            >
                            <span
                              class="text-sm text-gray-500 dark:text-gray-400"
                              >{{ freeResource.phone }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
        <!-- Resources tab end -->
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import Header from "./Header.vue";
export default {
  name: "Dashboard",
  components: {
    Header,
  },
  data() {
    return {
      taskWorkspace: "",
      presentRatio: "",
    };
  },
  methods: {
    ...mapActions(["getTaskCount", "getAttendanceCount", "getFreeResources"]),
    // fn for toggle task workspace card
    toggleWorkspace(workspace) {
      console.log("clickefc", workspace);
      $(`.Project${workspace}`).toggleClass("hidden");
      $(`.Arrow${workspace}`).toggleClass("fa-caret-down");
      $(`.Arrow${workspace}`).toggleClass("fa-caret-up");
    },

    // fn for toggle attendance card
    toggleAttendance(workspace) {
      $(`.attendance${workspace}`).toggleClass("hidden");
      $(`.ArrowAt${workspace}`).toggleClass("fa-caret-down");
      $(`.ArrowAt${workspace}`).toggleClass("fa-caret-up");
    },

    // fn for toggle resources card
    toggleResource(workspace) {
      $(`.resource${workspace}`).toggleClass("hidden");
      $(`.ArrowR${workspace}`).toggleClass("fa-caret-down");
      $(`.ArrowR${workspace}`).toggleClass("fa-caret-up");
    },

    toggleList(project) {
      $(`.pTask${project}`).toggleClass("hidden");
      $(`.listArrow${project}`).toggleClass("fa-caret-down");
      $(`.listArrow${project}`).toggleClass("fa-caret-up");
    },
    // For List of all Absentees
    toggleAllAbsent() {
      $("#absentList").toggleClass("hidden");
      $("#absentArrow").toggleClass("fa-caret-down");
      $("#absentArrow").toggleClass("fa-caret-up");
    },
    // For list of all free resources
    toggleAllResources() {
      $("#resourcesList").toggleClass("hidden");
      $("#resourceArrow").toggleClass("fa-caret-down");
      $("#resourceArrow").toggleClass("fa-caret-up");
    },

    // Task Tab function
    tasksTab() {
      $("#tabTask").addClass("bg-gray-100 text-black-100");
      $("#attentab").removeClass("bg-gray-100 text-black-100");
      $("#resourcetab").removeClass("bg-gray-100 text-black-100");

      $("#resourcesDiv").addClass("hidden");
      $("#attendanceDiv").addClass("hidden");
      $("#taskdiv").removeClass("hidden");
    },
    attendanceTab() {
      $("#attentab").addClass("bg-gray-100 text-black-100");
      $("#tabTask").removeClass("bg-gray-100 text-black-100");
      $("#resourcetab").removeClass("bg-gray-100 text-black-100");

      $("#attendanceDiv").removeClass("hidden");
      $("#resourcesDiv").addClass("hidden");
      $("#taskdiv").addClass("hidden");
    },
    ResourcesTab() {
      $("#resourcetab").addClass("bg-gray-100 text-black-100");
      $("#tabTask").removeClass("bg-gray-100 text-black-100");
      $("#attentab").removeClass("bg-gray-100 text-black-100");
      
      $("#taskdiv").addClass("hidden");
      $("#attendanceDiv").addClass("hidden");
      $("#resourcesDiv").removeClass("hidden");
    },
  },
  computed: {
    ...mapGetters([
      "workspaceTaskCount",
      "taskCount",
      "presenceRatio",
      "getWorkspaces",
      "totalUsers",
      "totalPresent",
      "totalAbsent",
      "absentUsers",
      "freeResources",
      "resourcesCount",
      "workspaceAttendance",
      "workspaceResource",
    ]),
  },
  async created() {
    await this.getTaskCount();
    await this.getAttendanceCount();
    await this.getFreeResources();
    this.presentRatio = Math.round(this.presenceRatio);
  }
};
</script>
