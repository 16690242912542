<template>
  <div class="min-h-screen">
    <div class="mr-4">
     
    </div>
      <div class="flex items-center justify-center pt-10"> 
      <div class="w-3/4 flex flex-row flex-wrap justify-start items-center">
      
        <div
          class="
          w-1/4
            flex justify-between items-center
            m-4
            bg-white-100
            border border-gray-300
            rounded
            px-3
            py-4
          "
          v-for="doc in ProjectDocs"
          :key="doc.id"
        >
          <div class="flex ">
            <i class="fas fa-file text-4xl text-gray-600"></i>
            <div class="px-2 font-medium flex justify-between items-center">
              <p>{{ doc.fileoriginal_name }}</p>
              
            </div>  
            
          </div>
          <span
            ><i
            v-if="myAbilities.can('delete_project_documents', 'pms')"
              class="fas fa-times text-black-50 text-xs"
              @click="removeDoc(doc.id)"
            ></i
          ></span>
        </div>
      </div> 
    </div>

  
  </div>
</template>
<script>
import $ from 'jquery';
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Documents",
  data() {
    return {
      doc: {
        file: "",
      },
    };
  },
  methods: {
    ...mapActions(["fetchProjectDocs", "storeProjectDoc", "removeProjectDoc"]),
    ...mapMutations(["toggleLoading"]),
    downloadDoc(docname) {
      const a = document.createElement("a");
      a.href = `https://newpms.futureplan.tech/api/${docname}`;
      a.setAttribute("download", true);
      a.click();
    },
    toggleDocModal() {
     $("#docModal").toggleClass("hidden");
    },
    mapFile(e) {
      this.doc.file = e.target.files[0];
    },
    async addDoc() {
      let formdata = new FormData();
    this.toggleLoading(true);

      formdata.append("file", this.doc.file);
      formdata.append("project_id", this.$route.params.id);
      let res = await this.storeProjectDoc(formdata);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
    this.toggleLoading(false);

      this.toggleDocModal();
      this.doc.file = "";
    },
    async removeDoc(id) {
      let res = await this.removeProjectDoc({
        id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
    },
  },
  computed: {
    ...mapGetters(["ProjectDocs", "myAbilities"]),
  },
  async created() {
    this.toggleLoading(true);
    await this.fetchProjectDocs({ id: this.$route.params.id });
    this.toggleLoading(false)
 },
};
</script>