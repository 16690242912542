<template>
  <div>
    <div class="flex justify-center">
      <div
        class="
          block
          p-6
          lg:w-2/4
          bg-white
          rounded-lg
          border border-gray-200
          shadow-md
          permissions-div
        "
      >
        <!-- Select -->
        <div
          class="flex justify-between items-center"
          v-if="myAbilities.can('get_role', 'pms')"
        >
          <div class="w-3/4">
            <multiselect
              class="
                text-xs
                w-full
                border-0
                mb-0
                placeholder-mb-0
                text-black-50
              "
              :options="Roles"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="false"
              :searchable="true"
              :show-labels="false"
              placeholder="Select Role"
              name="RoleSelect"
              id="role"
              rack-by="id"
              label="name"
              v-model="role"
              @close="checkPerm(role)"
            >
            </multiselect>
          </div>
          <div>
            <button
              @click="toggleRoleModal"
              class="
                bg-blue-500
                w-lg
                text-white-100
                focus:ring-4 focus:outline-none focus:ring-red-300
                font-medium
                rounded
                text-sm
                px-5
                py-2.5
                text-center
                dark:bg-red-400
              "
               v-if="myAbilities.can('store_role', 'pms')"
            >
              Create Role
            </button>
          </div>
        </div>

        <!-- Permissions Checkboxes -->
        <div class="pt-6">
          <div
            class="
              text-lg
              font-medium
              text-gray-900
              border-0 border-b border-gray-300
            "
          >
            Permissions
          </div>
          <div class="pt-6 lg:flex flex-row  justify-between flex-wrap">
            <div
              class="lg:w-1/2 md:w-full  flex items-center mb-4"
              v-for="permission in Permissions"
              :key="permission.id"
            >
              <input
                :value="permission.id"
                :id="permission.id"
                type="checkbox"
                class="
                  p-checks
                  w-4
                  h-4
                  text-blue-600
                  bg-gray-100
                  border-gray-300
                  rounded
                  focus:ring-blue-500 focus:ring-2
                "
              />
              <label
                for="disabled-checkbox"
                class="
                  ml-2
                  text-sm
                  font-medium
                  text-gray-600
                  dark:text-gray-500
                "
              >
                {{ permission.name }}
              </label>
            </div>
          </div>
          <div class="flex items-center mb-4 border-0 border-t-2 py-2">
            <input
              type="checkbox"
              class="
                w-4
                h-4
                text-blue-600
                bg-gray-100
                border-gray-300
                rounded
                focus:ring-blue-500 focus:ring-2
              "
              id="checkAll"
              @change="checkAll"
            />
            <label
              for="disabled-checkbox"
              class="ml-2 text-sm font-medium text-gray-600 dark:text-gray-500"
            >
              Check All
            </label>
          </div>
        </div>
        <footer class="flex justify-center items-center px-3 pt-4">
          <button
            class="bg-blue-600 text-white-100 font-medium px-3 rounded-md py-1"
            @click="AssignPermission"
          >
            Update
          </button>
        </footer>
      </div>
    </div>

    <!-- role Modal  -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="roleModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Add Role
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleRoleModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="addRole">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Name:</label
                >
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="Enter Name"
                  v-model="name"
                  required
                />
              </div>

              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "RolePermission",
  data() {
    return {
      options: ["Neha", "Zayn", "Laraib"],
      role: "",
      tempRole: [],
      name: "",
      assignedPermissions: []

    };
  },
  methods: {
    ...mapActions([
      "fetchRoles",
      "fetchPermissions",
      "assignRolePermissions",
      "createRole",
    ]),
    ...mapMutations(["toggleLoading"]),


  checkPerm(role){
      this.temp = [];      
      role.permissions.forEach((el) => this.temp.push(el.id));
      this.assignedPermissions = this.temp;
       document
        .querySelectorAll(".p-checks")
        .forEach((el) =>
          this.temp.includes(parseInt(el.id))
            ? (el.checked = true)
            : (el.checked = false)
        );
        document.getElementById("checkAll").checked =
        this.temp.length == this.Permissions.length ? true : false;
    },
  checkAll(e) {
      if (e.target.checked) {
        document
          .querySelectorAll(".p-checks")
          .forEach((el) => (el.checked = true));
        // e.target.disabled = true;
      } else {
        document
          .querySelectorAll(".p-checks")
          .forEach((el) => (el.checked = false));
      }
    },

    uncheckAllCheckbox(e) {
      if (!e.target.checked) {
        document.getElementById("checkAll").checked = false;
        document.getElementById("checkAll").disabled = false;
      }
    },


    // Role Modal
    toggleRoleModal() {
      document.getElementById("roleModal").classList.toggle("hidden");
    },

    async addRole() {
      let res = await this.createRole({
        name: this.name,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.name = "";
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleRoleModal();
    },

    // Assign Permission
    async AssignPermission() {
      let assigned = [];
      let removeArray = [];
      document
        .querySelectorAll(".p-checks")
        .forEach((el) => (el.checked ? assigned.push(parseInt(el.value)) : ""));
      this.tempRole.forEach((existing) => {
        if (assigned.find((el) => el == existing)) {
          let index = assigned.findIndex((el) => el == existing);
          assigned.splice(index, 1);
        } else {
          removeArray.push(existing);
        }
      });

      this.toggleLoading(true);
        let res = await this.assignRolePermissions({
          role_id: this.role.id,
          permission_ids: assigned,
        });

        if (res.success) {
          this.role = "";
          this.$toasted.success(res.msg);
          document
          .querySelectorAll(".p-checks")
          .forEach((el) => (el.checked = false));
        } else {
          this.$toasted.error(res.msg);
        }
      this.toggleLoading(false);

    },
  },
  computed: {
    ...mapGetters(["Permissions", "Roles", "myAbilities"]),
  },
  async created() {
    await this.fetchPermissions();
    await this.fetchRoles();
  },
};
</script>