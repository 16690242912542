<template>
  <div class="bg-black-100">
    <div class="flex h-screen">
      <div
        class="
          flex flex-row
          align-center
          justify-center
          m-auto
          lg:w-6/12
          md:w-max
          shadow-xl
          border border-black-100
        "
      >
        <div
          class="
            w-4/5
            hidden
            sm:flex
            text-lg
            bg-white-100
            items-center
            justify-center
            px-4
          "
        >
          <div
            class="
              font-serif
              text-white text-6xl
              font-bold
              text-gray-800 text-center
              transition
              transform
              ease-in
            "
          >
            <div>"Plan</div>
            <div class="">Your activities</div>
            <div>
              <div>and control</div>
              <div>your progress</div>
              online!"
            </div>
          </div>
        </div>
        <div class="w-full px-6 py-2 bg-gray-800">
          <div class="flex justify-end text-white-100">
            <div
              class="border rounded-l-2xl px-2 py-1 border-white-100 text-sm"
              id="signUp"
              @click="toSignup"
            >
              Signup
            </div>
            <div
              class="
                border
                rounded-r-2xl
                border-l-0
                px-2
                py-1
                bg-white-100
                border-white-100
                text-sm text-gray-800
              "
              id="logIn"
              @click="toLogIn"
            >
              Login
            </div>
          </div>
          <router-view default="{path:'/login'}" class=""></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Form",
  methods: {
    toSignup() {
      this.$router.replace("signup");
      $("#signUp").addClass(
        "border rounded-l-2xl border-r-0 px-2 py-1 bg-white-100 border-white-100 text-sm text-gray-800  mb-0"
      );
      $("#logIn").removeClass(
        " bg-white-100 border-white-100 text-sm py-1 text-gray-800"
      );
    },
    toLogIn() {
      this.$router.replace("login");
      $("#logIn").addClass(
        "border rounded-r-2xl border-r-0 px-2 py-1 bg-white-100 border-white-100 text-sm text-gray-800  mb-0"
      );
      $("#signUp").removeClass(
        " bg-white-100 border-white-100 py-1 text-sm text-gray-800"
      );
    },
  },
};
</script>