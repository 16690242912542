<template>
  <div class="signup">
    <form
      class="flex-column content-center justify-content-center px-3"
      @submit="submitSignupForm"
    >
      <div class="text-xl text-white-100 text-center py-6">Signup</div>
      <div class="relative z-0 mb-6 w-full group">
        <input type="text" name="floating_first_name" v-model="user.name" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-white-100 bg-transparent border-0 border-b border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
        <label for="floating_first_name" class="requiredSignup peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Full Name</label>
    </div>
  
      <div class="relative z-0 mb-6 w-full group">
      <input type="email" v-model="user.email" name="floating_email" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-white-100 bg-transparent border-0 border-b border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
      <label for="floating_email" class="requiredSignup peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
  </div>
     
      <div class="relative z-0 mb-6 w-full group">
        <input type="tel" pattern="03[0-9]{9}" v-model="user.phoneNo" name="floating_phone" id="floating_phone" class="block py-2.5 px-0 w-full text-sm text-white-100 bg-transparent border-0 border-b border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
        <label for="floating_phone" class="requiredSignup peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number (0300-1234567)</label>
    </div>
 <div class="relative z-0 mb-8 w-full group">
        <input
         v-model="user.password"
         type="password"
          name="floating_password"
          id="floating_password"
          class="
            block
            py-2.5
            px-0
            w-full
            text-sm text-white-100
            bg-transparent
            border-0 border-b border-gray-300
            appearance-none
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500
            focus:outline-none focus:ring-0 focus:border-blue-600
            peer
          "
          placeholder=" "
          required
        />
        
        <label
          for="floating_password"
          class="
          requiredSignup
            peer-focus:font-medium
            absolute
            text-sm text-gray-500
            dark:text-gray-400
            duration-300
            transform
            -translate-y-6
            scale-75
            top-3
            -z-10
            origin-[0]
            peer-focus:left-0
            peer-focus:text-blue-600
            peer-focus:dark:text-blue-500
            peer-placeholder-shown:scale-100
            peer-placeholder-shown:translate-y-0
            peer-focus:scale-75 peer-focus:-translate-y-6
          "
          >Password</label
        >
      </div>
    

       <div class="relative z-0 mb-6 w-full group">
      <input type="password" name="repeat_password" v-model="user.confirmpass" id="floating_repeat_password" class="block py-2.5 px-0 w-full text-sm text-white-100 bg-transparent border-0 border-b border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
      <label for="floating_repeat_password" class="requiredSignup peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirm password</label>
  </div>
      
      <div class="flex justify-center">
        <button
          class="
            bg-white-100
            hover:shadow-xl
            text-green-800
            font-bold
            py-2
            px-5
            my-3
            rounded-3xl
          "
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Signup",
  data() {
    return {
      user: {
        name: "",
        email: "",
        password: "",
        phoneNo: "",
        confirmpass: "",
      },
    };
},
  methods: {
    ...mapActions(["RegisterUser"]),
    ...mapMutations(["toggleLoading"]),

    // Signup

    async submitSignupForm(e) {
      e.preventDefault();
      if (this.validateUser()) {
        this.toggleLoading(true);
        let res = await this.RegisterUser({
          name: this.user.name,
          email: this.user.email,
          phone: this.user.phoneNo,
          password: this.user.password,
          confirm_password: this.user.confirmpass,
        });

        this.toggleLoading(false);
        if (res.success == true) {
          this.$toasted.success(res.msg);
          this.$router.push("/login");
        } else {
          this.$toasted.error(res.msg);
        }
        //reset the form
        this.resetSignupForm();
      }else{
          this.$toasted.error("Invalid data in form!");

      }
    },

    // Validation for Password
    validateUser() {
      // if (this.user.email == "" ) {
      //   this.$toasted.error("Not a valid email pattern");
      //   return false;
      // }
      if (this.user.password != this.user.confirmpass) {
        this.$toasted.error("Password are not same!");
        this.user.confirmpass = "";
        return false;
      }
      if (this.user.phoneNo.length < 11) {
        this.$toasted.error("Please enter a valid phone number");
        return false;
      }
      return true;
    },
    resetSignupForm() {
      this.user = {
        name: "",
        email: "",
        password: "",
        phoneNo: "",
        confirmpass: "",
      };
    },
  },
  computed: {
    // ...mapFields(["email", "password"]),
    ...mapGetters(["Token"]),
  },
};
</script>