<template>
  <div>
    <header id="mainContent" class="">
      <!-- HEader  -->
      <nav id="mainHeader" class="bg-yellow-100 shadow px-2 bg-gray-800 p-2 mt-0 fixed z-10 top-0 w-full" >
        <div class="flex justify-between py-3 sm:pr-40 lg:pr-60">
          <div class="flex items-center px-1">
            <div class="md:hidden" id="headerToggle"><i  class="fas fa-bars" @click="callToggleInChildComponent"></i></div>
            <div class="text-xl text-bold px-2" id="title" @focusout="update" > 
              {{ title?title:"Title" }}
            </div>
              <span class="ml-2" v-if="isEditable"><i class="fas fa-pen text-sm text-black-50" @click="makeEditable"></i> </span>

          </div>

          <div class="flex items-center">
            <!-- Check In and out button -->
            <div>
              <button v-if="isPresent" @click="markCheckOut" class="border border-white-100 bg-blue-500 px-2 rounded text-xs text-white-100 py-1.5 mr-2 hover:pointer hover:bg-blue-600">Check Out</button>
              <button @click="markCheckIn" v-else class="border border-white-100 bg-blue-500 px-2 rounded text-xs text-white-100 py-1.5 mr-2 hover:pointer hover:bg-blue-600">Check In</button>
            </div>
            <!-- Plus dropdown -->
            <div v-if="myAbilities.can('access_granted', 'pms')">
              <a
                class="
                  text-white-100
                  bg-red-400
                  px-2
                  py-2
                  flex
                  items-center
                  text-xs
                  uppercase
                  border
                  rounded-full
                  border-transparent
                  focus:bg-pink-50
                  font-bold
                "
                id="dropdownDefault"

                @click="dropdown"
              >
                <i class="fas fa-plus text-xs"></i>
              </a>
              <div
                id="plusDropdown"
                class="
                  bg-white-100
                  text-base
                  z-10
                  float-left
                  py-4
                  list-none
                  text-left
                  rounded
                  shadow-lg
                  w-48
                  mr-4
                  absolute
                  lg:right-64
                  right-1
                  hidden
                  min-w-48
                "
              >
               <li
                  class="
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-blueGray-700
                    hover:bg-red-400 hover:text-white-100
                    cursor-pointer
                  "
                  @click="toggleAddTaskInChild"
                >
                  <i class="fas fa-clipboard pr-2"></i>Add Task
                </li>
                <li
                  class="
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-blueGray-700
                    hover:bg-red-400 hover:text-white-100
                    cursor-pointer
                  "
                  @click="workspaceToggleInChild"
                  v-if="myAbilities.can('store_workspace', 'pms')"
                >
                  <i class="fas fa-users pr-2"></i>Create Workspace
                </li>
                <li
                  class="
                    text-sm
                    py-2
                    px-4
                    font-normal
                    block
                    w-full
                    whitespace-nowrap
                    bg-transparent
                    text-blueGray-700
                    hover:bg-red-400 hover:text-white-100
                    cursor-pointer
                  "
                  @click="toggleUserInChild"
                  v-if="myAbilities.can('create_user', 'pms')"
                >
                  <i class="fas fa-user pr-2"></i> Create User
                </li>
              </div>
            </div>
            <!-- plus dropdown end -->
            <!-- User Profile -->
            <div class="px-2" v-if="myAbilities.can('get_profile', 'pms')">
              <router-link to="/profile">
              <i class="fas fa-user-circle text-xl text-blue-800 mr-2"></i>
              </router-link>
            </div>
            <!-- User profile end -->
          </div>
        </div>       
      </nav>
    </header>
  </div>
</template>

<script>

import $ from 'jquery';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: "Header",
  data() {
    return {
      newTitle:""
    };
  },
  props:{
    title:String,
    isEditable:Boolean
  },
  methods: {
    ...mapActions(["present", "checkIn", "checkOut"]),
    ...mapMutations(["toggleLoading"]),

    // dorpdown 
    dropdown(){
      $("#plusDropdown").toggleClass("hidden")
    },

    // using sidebar toggle function from layout
     callToggleInChildComponent() {
      //As an instance property
      this.$eventBus.$emit("callToggleInChild");
    },
    
    // for open workspace modal using function from parent 
    workspaceToggleInChild() {
      this.$eventBus.$emit("callWorkspaceToggle");
    },

    // for open user modal using function from layout 
    toggleUserInChild() {
      this.$eventBus.$emit("callUserToggle");
    },

    // for open task modal using function from layout 
    toggleAddTaskInChild() {
      this.$eventBus.$emit("callTaskModalToggle");
    },

    // Make title in header editable 
    makeEditable(){
      let edit = $('#title');
      edit.attr('contentEditable', true)
      edit.filter(':visible').focus();
    },

    // update title
    update(){
      let edit = $('#title');
      this.$emit('update-workspace',edit[0].innerText)
      this.$emit('update-project',edit[0].innerText)
    }, 

    // CheckIn function
    async markCheckIn(){
      this.toggleLoading(true);
      let res = await this.checkIn();
      if(res.success){
        this.$toasted.success(res.msg);
      }else{
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    
    // Check out
    async markCheckOut(){
      this.toggleLoading(true);
      let res = await this.checkOut();
      if(res.success){
        this.$toasted.success(res.msg);
      }else{
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    }
  },
  
  computed: {
    ...mapGetters(["myAbilities", "isPresent"])
  }
};
</script>