import Api from "../../helper/constant"
import WorkspaceStore from './workspace.store'
const state = {
    projects: [],
    project: null,
    projectDocs: [],

}

const getters = {
    Projects: state => state.projects,
    Project: state => state.project,
    ProjectDocs: state => state.projectDocs,
}

const actions = {
    // fetch all projects
    async fetchProjects({ commit }) {
        let response = await Api.get("/project")
        commit('setProjects', response)
    },

    // store new project
    async storeProject({ commit }, body) {
        let response = await Api.post("/add_project", body)
        if (response.status == 200) {
            commit('addProject', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // fetch project
    async fetchProject({ commit }, body) {
        let response = await Api.get(`/project/${body.id}`)
        if (response.status == 200) {
            commit('setProject', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // store project members
    async storeProjectMembers({ commit }, body) {
        let response = await Api.post(`/add_project_users`, body)
        if (response.status == 200) {
            commit('setProject', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // remove Project members
    async removeProjectMembers({ commit }, body) {
        let response = await Api.post(`/remove`, body)
        if (response.status == 200) {
            commit('setProject', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }
    },

    //qa members
    async storeProjectQaMembers({ commit }, body) {
        let response = await Api.post(`/add_project_qa`, body)
        if (response.status == 200) {
            commit('addProjectQa', response.data.data)
            return { success: true, msg: response.data.message }

        } else {
            return { success: false, msg: response.data.message }

        }
    },

    // remove project qa members
    async removeProjectQaMembers({ commit }, body) {
        let response = await Api.get(`/remove_project_qa/${body.user_id}/${body.project_id}`)
        if (response.status == 200) {
            commit('removeProjectQa', body.user_id)
            return { success: true, msg: response.data.message }

        } else {
            return { success: false, msg: response.data.message }

        }
    },

    //project reviewer
    async storeProjectReviewMembers({ commit }, body) {
        let response = await Api.post(`/add_project_reviewer`, body)
        if (response.status == 200) {
            commit('addProjectReview', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }

    },

    // remove project reviewers
    async removeProjectReviewMembers({ commit }, body) {
        let response = await Api.get(`/remove_project_reviewer/${body.user_id}/${body.project_id}`)
        if (response.status == 200) {
            commit('removeProjectReview', body.user_id)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }
    },


    // update project
    async updateProject({ commit }, body) {
        let response = await Api.put(`/project/${body.id}`, body)
        if (response.status == 200) {
            commit('setProject', response.data.data)
            return { success: true, msg: response.data.message }

        } else {
            return { success: false, msg: response.data.message }

        }
    },

    // delete project
    async deleteProject({ commit }, body) {
        let response = await Api.delete(`/project/${body.id}`)
        if (response.status == 200) {
            commit('removeProject', body)
            return { success: true, msg: response.data.message }

        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // fetch project documents
    async fetchProjectDocs({ commit }, body) {
        let response = await Api.get(`/get_project_docs/${body.id}`)
        if (response.status == 200) {
            //remove the index when response structure will change
            commit('setProjectDocs', response.data.data[0])
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }
    },

    // store project documents
    async storeProjectDoc({ commit }, body) {
        let response = await Api.post(`/project_docs`, body)
        if (response.status == 200) {
            //remove the index when response structure will change
            commit('addProjectDoc', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },


    // remove Project documents
    async removeProjectDoc({ commit }, body) {
        let response = await Api.delete(`/project_docs/${body.id}`)
        if (response.status == 200) {
            //remove the index when response structure will change
            commit('removeProjectDoc', body.id)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }

        }

    },

    // get filtered tasks
    async getFilteredTasks({ commit }, body) {
        let response = await Api.get(`/get_tasks/${body.projectId}/${body.statusId}/${body.priorityId}`)
        if (response.status == 200) {
            commit('filtereTasks', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    }
}

const mutations = {
    setProjects: (state, payload) => {
        state.projects = payload
    },
    addProject: (state, payload) => {
        state.projects.push(payload)
        //if same workspace tab is opened
        WorkspaceStore.state.workspace.id == payload.workspace_id && WorkspaceStore.state.workspace.project.push(payload)

        //add to the collection of workspaces on sidebar
        WorkspaceStore.state.workspaces.find(el => {
            if (el.id == payload.workspace_id) {
                el.project.push(payload)
            }
        });
    },
    setProject: (state, payload) => {
        state.project = payload
    },

    removeProject: (state, payload) => {
        let projectIndex = state.projects.findIndex(el => el.id == payload.id)
        state.projects.splice(projectIndex, 1);
        WorkspaceStore.state.workspaces.find(el => {
            if (el.id == payload.workspace_id) {
                el.project = el.project.filter(item => item.id !== payload.id)
            }
        })
    },

    //project qa
    addProjectQa: (state, payload) => {
        state.project.project_qa.push(payload.user)
    },
    removeProjectQa: (state, payload) => {
        let userIndex = state.project.project_qa.findIndex(el => el.id == payload)
        state.project.project_qa.splice(userIndex, 1);
    },
    //project review
    addProjectReview: (state, payload) => {
        state.project.project_reviewer.push(payload.user)
    },
    removeProjectReview: (state, payload) => {
        let userIndex = state.project.project_reviewer.findIndex(el => el.id == payload)
        state.project.project_reviewer.splice(userIndex, 1);
    },

    // project docs
    addProjectDoc: (state, payload) => {
        state.projectDocs.push(payload);
    },
    setProjectDocs: (state, payload) => {
        state.projectDocs = payload.project_docs;
    },

    removeProjectDoc: (state, payload) => {
        let docIndex = state.projectDocs.findIndex(el => el.id == payload)
        state.projectDocs.splice(docIndex, 1);
    },
    
    filtereTasks: (state, payload) => {
        state.project.task = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}