<template>
    <div>
<Layout />
    </div>
</template>

<script>
import Layout from './layout/Layout.vue'
export default {
    name: "HomePage",
    components: {
        Layout
    }
}
</script>