import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../components/HomePage'
import Dashboard from '../components/layout/Dashboard'
import Signup from '../components/Signup'
import Login from '../components/Login'
import LoginForm from '../components/Form'
import Project from '../components/project/Project'
import Calendar from '../components/project/Views/Calendar'
import WorkSpace from '../components/Workspace.vue'
import Overview from '../components/project/ProjectOverview'
import User from '../components/users/Users'
import TempUser from '../components/TempUsers'
import Roles from '../components/Roles'
import Profile from '../components/users/UserProfile'
import Files from '../components/project/Documents'
import Permission from '../components/permissions/Permission'
import UserPerms from '../components/permissions/UserPermission'
import RolePerms from '../components/permissions/RolePermission'
import store from '../store/index'



Vue.use(VueRouter)

const routes = [
    {

        path: '/login',
        component: LoginForm,
        children: [
            {
                
                path: '/',
                props: true,
                component: Login,
            },
            {
                
                path: '/signup',
                props: true,
                component: Signup,
            },
            {

                path: '/tempUser',
                props: true,
                component: TempUser,
            },
        ]
    },

    {
        path: '/',
        beforeEnter: guardRoute,
        component: HomePage,
        name: "Home",
        children: [
            
            {

                path: '/',
                component: Dashboard,
                name: 'Dashboard',
                

            },
            {

                path: 'permission',
                name: 'Permission',
                component: Permission,
                children: [
                    {
                        path: '',
                        name: 'UserPermission',
                        component: UserPerms,
                    },
                    {
                        path: '/rolepermission',
                        component: RolePerms,
                    }
                ]
            },
            {

                path: '/user',
                name: 'User',
                component: User,
            },
           
            {

                path: '/role',
                name: 'Roles',
                component: Roles,
            },
            {

                path: '/profile',
                component: Profile,
            },
            {

                path: '/myTask',
                name: 'MyTask',
                component: () => import ('../components/layout/myTask/MyTask'),
                children: [
                    {
                        path: '',
                        name: 'MyTaskList',
                        component: () => import ('../components/layout/myTask/MyTaskList'),
                        children: [
                            {
                                path: 'mytaskList',
                                name: 'ITaskDetail',

                            },
                            {
                                path: ':taskId/myTaskDetail',
                                name: 'MyTaskDetail',
                                component: () => import ('../components/project/Views/taskDetails/Details'),
                        
                            },
                            {
                                path: ':taskId/myTaskqastatus',
                                name: 'myQaStatus',
                                component: () => import  ('../components/project/Views/taskDetails/QAstatus')

                            },
                            {
                                path: ':taskId/myTaskreview',
                                name: 'myReview',
                                component: () => import  ('../components/project/Views/taskDetails/Review')
                            },
                        ]
                    },
                    {
                        path: 'myTaskKanban',
                        name: 'myTaskKanban',
                        component: () => import('../components/layout/myTask/MyKanban'),
                    },
                    {
                        path: 'myTaskCalendar',
                        name: 'myTaskCalendar',
                        component: () => import('../components/layout/myTask/MyCalendar'),
                        children: [
                            {
                                path: 'calendarDetail',
                                name: 'calendarDetail',

                            },
                            {
                                path: ':taskId/taskCalendarDetail',
                                name: 'MyCalendarDetail',
                                component: () => import ('../components/project/Views/taskDetails/Details'),
                        
                            },
                            {
                                path: ':taskId/myTaskCalendarQastatus',
                                name: 'myCalendarQaStatus',
                                component: () => import  ('../components/project/Views/taskDetails/QAstatus')

                            },
                            {
                                path: ':taskId/myTaskCalendarreview',
                                name: 'myCalendariew',
                                component: () => import  ('../components/project/Views/taskDetails/Review')
                            },
                        ]
                    }
                ]
            },

            {
                
                path: '/workspace/:id',
                component: WorkSpace,
                beforeEnter: guardRoute,
            },
            {
                
                path: '/project/:id',
                component: Project,
                beforeEnter: guardRoute,
                children: [
                    {
                        path: '',
                        name: 'List',
                        component: () => import ('../components/project/Views/List'),
                        children: [
                            {
                                path: 'list',
                                name: 'Detail',
                                
                        
                            },
                            
                            {
                                path: ':taskId/qastatus',
                                name: 'QaStatus',
                                component: () => import  ('../components/project/Views/taskDetails/QAstatus')

                            },
                            {
                                path: ':taskId/review',
                                name: 'Review',
                                component: () => import  ('../components/project/Views/taskDetails/Review')
                            },
                            {
                                path: ':taskId/taskDetail',
                                name: 'TaskDetail',
                                component: () => import ('../components/project/Views/taskDetails/Details'),
                        
                            },
                            
                        ]
                    },
                    {
                        path: '/projectOverview/:id',
                        component: Overview
                    },
                   
                    {
                        path: 'kanbanBoard',
                        component: () => import('../components/project/Views/KanbanBoard'),
                        name: "TestKanban",
                        children: [
                            {
                                path: ':taskId/kanbandetails',
                                name: 'KanbanDetail',
                                component: () => import ('../components/project/Views/taskDetails/Details')
                            },
                            {
                                path: ':taskId/kanbanqastatus',
                                name: 'kanbanqastatus',
                                component: () => import ('../components/project/Views/taskDetails/QAstatus')
                            },
                            {
                                path: ':taskId/kanbanreview',
                                name: 'kanbanreview',
                                component: () => import ('../components/project/Views/taskDetails/Review')
                            },
                        ]
                    },
                    {
                        path: 'calendar',
                        name: 'Calendar',
                        component: Calendar,
                        children: [
                          
                            {
                                path: ':taskId/CalendarDetail',
                                name: 'TaskCalendarDetail',
                                component: () => import ('../components/project/Views/taskDetails/Details'),
                        
                            },
                            {
                                path: ':taskId/taskCalendarQastatus',
                                name: 'calendarQaStatus',
                                component: () => import  ('../components/project/Views/taskDetails/QAstatus')

                            },
                            {
                                path: ':taskId/taskCalendarreview',
                                name: 'calendariew',
                                component: () => import  ('../components/project/Views/taskDetails/Review')
                            },
                        ]
                    },
                    {
                        path: 'files',
                        name: 'Files',
                        component: Files
                    },
                ]
            }

        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "active"
})

function guardRoute(to, from, next) {
    if (localStorage.getItem('token')) {
        store.commit('setToken', localStorage.getItem('token'))
        store.commit('setPermissions')
        next()
    } else {
        next('/login')
    }
}

export default router;