<template>
  <div>
    <Header title="Roles" />
    <nav class="text-black-50 bg-white-100 text-sm px-6 pt-20">
      <div
        class="border-b border-black-200 py-2 flex justify-between items-center"
      >
        <!-- Filters  -->
        <ul class="flex">
          <li class="px-3 py-2">
            <i class="fas fa-list text-xs"></i> Roles List
          </li>
        </ul>

        <!-- Add, Create new options -->
        <ul
          class="flex text-black-50"
          v-if="myAbilities.can('store_role', 'pms')"
        >
          <div class="px-2">
            <button
              @click="toggleRoleModal"
              class="bg-blue-500 text-white-100 px-2 py-1 rounded"
            >
              Create Role
            </button>
          </div>
        </ul>
      </div>
    </nav>

    <div class="lg:px-72 sm:px-12 pt-6">
      <div class="relative overflow-x-auto shadow-md">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead
            class="
              text-xs text-gray-700
              uppercase
              bg-gray-200
              dark:bg-gray-700 dark:text-gray-400
            "
          >
            <tr>
              <th scope="col" class="px-6 py-3">Role</th>
              <!-- <th scope="col" class="px-6 py-3"></th> -->
              <th scope="col" class="px-6 py-3">Actions</th>
              <!-- <th scope="col" class="px-6 py-3">
                <span class="sr-only">Edit</span>
              </th> -->
            </tr>
          </thead>
          <tbody v-if="myAbilities.can('get_role', 'pms')">
            <tr
              v-for="role in Roles"
              :key="role.id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <th
                scope="row"
                class="
                  px-6
                  py-4
                  font-normal
                  text-gray-900
                  dark:text-white
                  whitespace-nowrap
                "
              >
                {{ role.name }}
              </th>
              <!-- <td class="px-6 py-4">
                {{ user.roles.length > 0 ? user.roles[0].name : " " }}
              </td> -->
              <td
                class="px-10 py-4"
                v-if="myAbilities.can('update_role', 'pms')"
              >
                <i
                  class="fas fa-pen text-xs text-black-50 mr-2"
                  @click="editRoleModal(role)"
                ></i>
                <i class="fas fa-trash text-xs text-black-50 mr-2" @click="delte(role)"></i>
              </td>
            </tr>
            <!-- <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="
                  px-6
                  py-4
                  font-normal
                  text-gray-900
                  dark:text-white
                  whitespace-nowrap
                "
              >
                Microsoft Surface Pro
              </th>
              <td class="px-6 py-4">White</td>
                            <td class="px-6 py-4"><i class="fas fa-pen text-xs text-black-50 mx-1" @click="editUserModal"></i>
                          

                            </td>

              
            </tr>
            <tr class="bg-white dark:bg-gray-800">
              <th
                scope="row"
                class="
                  px-6
                  py-4
                  font-normal
                  text-gray-900
                  dark:text-white
                  whitespace-nowrap
                "
              >
                Magic Mouse 2
              </th>
              <td class="px-6 py-4">Black</td>
                            <td class="px-6 py-4"><i class="fas fa-pen text-xs text-black-50" @click="editUserModal"></i></td>

            </tr> -->
          </tbody>
          <tbody v-else>
            No permission to show Roles
          </tbody>
        </table>
      </div>
    </div>

    <!-- role Modal  -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="roleModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Add Role
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleRoleModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="addRole">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Name:</label
                >
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="Enter Name"
                  v-model="name"
                  required
                />
              </div>

              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Role -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="editRoleModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Edit Role
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="editRoleModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="editRole">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Name:</label
                >
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="Enter Name"
                  v-model="role"
                  required
                />
              </div>

              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Edit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Header from "./layout/Header.vue";

export default {
  name: "Roles",
  components: {
    Header,
  },
  data() {
    return {
      role: "",
      roleId: "",
      name: "",
    };
  },
  methods: {
    ...mapActions(["fetchRoles", "createRole", "updateAllRole", "deleteRole"]),
    ...mapMutations(["toggleLoading"]),
    // Role Modal
    toggleRoleModal() {
      document.getElementById("roleModal").classList.toggle("hidden");
    },

    // edit role Modal
    async editRoleModal(role) {
      document.getElementById("editRoleModal").classList.toggle("hidden");
      if (role != null) {
        this.roleId = role.id;
        this.role = role.name;
      }
    },

    async editRole() {
      this.toggleLoading(true);

      let res = await this.updateAllRole({
        id: this.roleId,
        name: this.role,
      });
      console.log(res, "role");
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.editRoleModal();
      this.toggleLoading(false);
    },

    // delete role
    async delte(role) {
      if (confirm("Are you sure?")) {
        this.toggleLoading(true);
        let res = await this.deleteRole({
          id: role.id,
        });
        this.toggleLoading(false);
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.success(res.error);
        }
      }
    },

    // create role
    async addRole() {
      this.toggleLoading(true);
      let res = await this.createRole({
        name: this.name,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.name = "";
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
      this.toggleRoleModal();
    },
  },
  computed: {
    ...mapGetters(["Roles", "myAbilities"]),
  },
  async created() {
    this.toggleLoading(true);
    await this.fetchRoles();
    this.toggleLoading(false);
  },
};
</script>