const state = {
    loading: false,
    title: "Home"
}

const getters = {
    loading: state => state.loading,
    Title: state => state.title
}

const mutations = {
    toggleLoading: (state, bool) => {
        state.loading = bool;
    },
    setTitle: (state, payload) => {
        state.title = payload
    }
}

export default {
    state,
    getters,
    mutations
}