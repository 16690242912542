<template>
  <div class="login py-11">
    <loading
      :active.sync="loading"
      :can-cancel="true"
      :is-full-page="fullPage"
      loader="spinner"
      color="#0d6efdd6"
      backgroundColor="#4c4c506e"
    />
    <form
      class="
        flex-column
        content-center
        justify-content-center
        px-3
        py-2
        transition
        transform
        translate-y-6
        ease-in
      "
      @submit="SubmitLoginForm"
    >
      <div class="text-xl text-white-100 text-center pb-16">Login</div>

      <div class="relative z-0 mb-8 w-full group">
        <input
          type="email"
          name="floating_email"
          v-model="email"
          id="floating_email"
          class="
            block
            py-2.5
            px-0
            w-full
            text-sm text-white-100
            bg-transparent
            border-0 border-b border-gray-300
            appearance-none
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500
            focus:outline-none focus:ring-0 focus:border-blue-600
            peer
          "
          placeholder=" "
          required
        />
        <label
          for="floating_email"
          class="
          requiredSignup
            peer-focus:font-medium
            absolute
            text-sm text-gray-500
            dark:text-gray-400
            duration-300
            transform
            -translate-y-6
            scale-75
            top-3
            -z-10
            origin-[0]
            peer-focus:left-0
            peer-focus:text-blue-600
            peer-focus:dark:text-blue-500
            peer-placeholder-shown:scale-100
            peer-placeholder-shown:translate-y-0
            peer-focus:scale-75 peer-focus:-translate-y-6
          "
          >Email address</label
        >
      </div>
      <div class="relative z-0 mb-8 w-full group">
        <input
          :type="passShow ? 'text' : 'password'"
          v-model="password"
          name="floating_password"
          id="floating_password"
          class="
          
            block
            py-2.5
            px-0
            w-full
            text-sm text-white-100
            bg-transparent
            border-0 border-b border-gray-300
            appearance-none
            dark:text-white dark:border-gray-600 dark:focus:border-blue-500
            focus:outline-none focus:ring-0 focus:border-blue-600
            peer
          "
          placeholder=" "
          required
        />
        <i
          class="fas fa-eye-slash text-white-50 absolute lg:right-10 bottom-3"
          @click="toggleEye()"
          v-if="passShow"
        ></i>
        <i
          class="fas fa-eye text-white-50 absolute lg:right-10 bottom-3"
          @click="toggleEye()"
          v-else
        ></i>
        <label
          for="floating_password"
          class=" requiredSignup
            peer-focus:font-medium
            absolute
            text-sm text-gray-500
            dark:text-gray-400
            duration-300
            transform
            -translate-y-6
            scale-75
            top-3
            -z-10
            origin-[0]
            peer-focus:left-0
            peer-focus:text-blue-600
            peer-focus:dark:text-blue-500
            peer-placeholder-shown:scale-100
            peer-placeholder-shown:translate-y-0
            peer-focus:scale-75 peer-focus:-translate-y-6
          "
          >Password</label
        >
      </div>

      <div class="flex justify-center pt-8">
        <button
          class="
            bg-white-100
            hover:shadow-xl
            text-green-800
            font-bold
            py-2
            px-5
            mb-6
            rounded-3xl
            transition
            transform
            duration-500
            ease-in-out
          "
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Login",
  components: { Loading },

  data() {
    return {
      email: "",
      password: "",
      emailError: false,
      passError: false,
      emailErrorMsg: "",
      passErrorMsg: "",
      // loading: false,
      alertClass: "alert alert-success login-success alert-dismissable",
      alertShow: false,
      alertMsg: "",
      passShow: false,
      isLoading: false,
      fullPage: true,
    };
  },
  methods: {
    ...mapActions(["Login"]),
    ...mapMutations(["toggleLoading"]),

    toggleEye() {
      this.passShow = !this.passShow;
    },

    // Loader
    doAjax() {
      this.isLoading = true;
      // simulate AJAX
      setTimeout(() => {
        this.isLoading = false;
      }, 5000);
    },
    async SubmitLoginForm(e) {
      e.preventDefault();

      if (!this.emailError && !this.passError) {
        const body = {
          email: this.email,
          password: this.password,
        };
        this.toggleLoading(true);
        let res;
        res = await this.Login(body);
        this.toggleLoading(false);
        // Response Successfull---> Login
        if (res.success == "200") {
          this.$toasted.success(res.msg);

          this.$router.push("/");
        } else {
         
          this.$toasted.error(res.msg);
        }
      }
    },
  },
  computed: {
    // ...mapFields(["email", "password"]),
    ...mapGetters(["Token", "isLogging", "loading"]),
  },
  created() {
    this.toggleLoading(false);
  },
};
</script>