import Api from "../../helper/constant"
import userStore from "./user.store"
import roleStore from "./role.store"


const state = {
    permissions: [],

}

const getters = {
    Permissions: state => state.permissions,
}

const actions = {
    // fetch all Permissions
    async fetchPermissions({ commit }) {
        let response = await Api.get('/permission')
        if (response.status == 200) {
            commit('setPermissions', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },


    // Assign permission to user 
    async assignUserPermission({ commit }, body) {
        let response = await Api.post(`/user_permission`, body)
        if (response.status == 200) {
            commit('addPermission', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // assign permissions to role
    async assignRolePermissions({ commit }, body) {
        let response = await Api.post(`/role_permission`, body)
        if (response.status == 200) {
            commit('addRolePermission', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },
}

const mutations = {
    setPermissions: (state, payload) => {
        state.permissions = payload;
    },

    addPermission: (state, payload) => {
        userStore.state.users.find(el => {
            if (el.id == payload.id) {
                el.permissions = payload.permissions
            }
        })
    },
    addRolePermission: (state, payload) => {
        roleStore.state.roles.find(el => {
            if (el.id == payload.id) {
                el.permissions = payload.permissions
            }
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}

