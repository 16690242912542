<template>
  <div class="w-full flex">
    <loading
      :active.sync="loading"
      :can-cancel="true"
      loader="spinner"
      color="#0d6efdd6"
      backgroundColor="#4c4c506e"
    />
    <!-- Sidebar -->
    <aside
      id="Sidebar"
      style="height: -webkit-fill-available"
      class="
      bg-black-100  md:block md:bottom-0 md:fixed md:flex-nowrap md:flex-row md:left-0 md:overflow-hidden md:overflow-y-auto md:top-0 md:w-64 px-6 py-4 relative shadow-xl z-10
       absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto  hidden
      "
    >
      <div
        class="
         md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center  w-full mx-auto
        "
      >
      <div class="border-0 border-b border-gray-50 w-full flex justify-between items-center">
        <a class="md:block text-left md:pb-2 text-white-100 text-xl  mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"> PMS</a>
        <button @click="closeSidebar()" class="text-white-100 md:hidden" id="closebtn">
         <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
        </button>
        </div>
      
      <!-- Side Nav -->
      <nav class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        <ul class="py-2 border-b border-gray-50">
          <li>
            <router-link :to="{ name: 'Dashboard' }" exact>
              <div class="py-1 hover:bg-black-500">
                <div class="flex items-center text-black-400 px-7 sidebar-list">
                  <i class="fas fa-home mr-2 text-white-50"></i>
                  <div class="text-sm">Home</div>
                </div>
              </div>
            </router-link>
          </li>
          <li class="" v-if="myAbilities.can('get_user_list', 'pms')">
            <div class="py-1 hover:bg-black-500">
              <router-link :to="{ name: 'User' }">
                <div class="flex items-center text-black-400 px-7">
                  <i class="fas fa-users mr-3 text-white-50"></i>
                  <div class="text-sm">Users</div>
                </div>
              </router-link>
            </div>
          </li>
          <li class="" v-if="myAbilities.can('get_role', 'pms')">
            <div class="py-1 hover:bg-black-500">
              <router-link :to="{ name: 'Roles' }">
                <div class="flex items-center text-black-400 px-7">
                  <i class="fas fa-user-tag mr-3 text-white-50"></i>
                  <div class="text-sm">Roles</div>
                </div>
              </router-link>
            </div>
          </li>

          <li class="" v-if="myAbilities.can('get_permission', 'pms')">
            <div class="py-1 hover:bg-black-500">
              <router-link :to="{ name: 'UserPermission' }">
                <div class="flex items-center text-black-400 px-7">
                  <i class="fas fa-user-lock mr-2 text-white-50"></i>
                  <div class="text-sm">Permission</div>
                </div>
              </router-link>
            </div>
          </li>
          <li class="">
            <router-link :to="{ path: `/myTask` }">
              <div class="py-1 hover:bg-black-500">
                <div class="flex items-center text-black-400 px-7">
                  <i class="fas fa-check-circle text-white-50 mr-2"></i>
                  <div class="text-sm">My Task</div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <ul class="py-2 border-b border-gray-50">
          <li class="text-white-100 text-lg overflow-y-auto">
            <div class="justify-between items-center">
              <div
                class="
                  flex
                  justify-between
                  items-center
                  text-black-300 text-sm
                  py-3
                  pb-4
                  px-7
                "
              >
                <div class="text-sm font-bold">Workspaces</div>
                <i
                  class="fas fa-plus mr-1 text-xs"
                  @click="toggleWorkspaceModal"
                  data-tooltip-target="workspace-tooltip"
                  data-tooltip-placement="bottom"
                  v-if="myAbilities.can('store_workspace', 'pms')"
                ></i>
                <div
                  id="workspace-tooltip"
                  role="tooltip"
                  class="
                    inline-block
                    absolute
                    invisible
                    z-10
                    py-1
                    px-2
                    text-xs text-black-100
                    bg-white-100
                    rounded
                    border border-yellow-200
                    shadow-sm
                    opacity-0
                    tooltip
                  "
                >
                  Add Workspace
                </div>
              </div>
              <div v-if="myAbilities.can('get_workspace', 'pms')">
                <div
                  class="text-sm"
                  v-for="workspace in getWorkspaces"
                  :key="workspace.id"
                >
                  <div
                    class="
                      flex
                      justify-between
                      items-center
                      text-sm
                      px-7
                      py-1
                      hover:bg-black-500
                    "
                  >
                    <div
                      class="flex items-center text-black-400"
                      @click="showProjects(`workspace${workspace.id}`)"
                    >
                      <i
                        class="fas fa-caret-right text-black-300 text-sm pr-2"
                        :class="`Arrowworkspace${workspace.id}`"
                      
                      ></i>
                      <router-link :to="{ path: `/workspace/${workspace.id}` }">
                        <a href="" class="border-0">
                          <div class="text-sm text-black-400">
                            {{ workspace.name }}
                          </div></a
                        ></router-link
                      >
                    </div>
                    <div v-if="myAbilities.can('store_project', 'pms')">
                      <span class="text-white-50 text-sm">
                        <i
                          class="fas fa-plus mr-1 text-xs"
                          @click="toggleProjectModal(workspace.id)"
                          data-tooltip-target="project-tooltip"
                          data-tooltip-placement="bottom"
                        ></i
                      ></span>
                      <div
                        id="project-tooltip"
                        role="tooltip"
                        class="
                          inline-block
                          absolute
                          invisible
                          z-10
                          py-1
                          px-2
                          text-xs text-black-100
                          bg-white-100
                          rounded
                          border border-yellow-200
                          shadow-sm
                          opacity-0
                          tooltip
                          "
                      >
                        Add Project
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="myAbilities.can('get_project', 'pms')"
                    class="hidden projectsName text-black-400 py-1 pb-3"
                    :class="`workspace${workspace.id}`"
                  >
                    <li
                      class="hover:bg-black-500"
                      v-for="project in workspace.project"
                      :key="project.id"
                    >
                      <router-link :to="{ path: `/project/${project.id}` }">
                        <div class="text-sm px-7 py-1">
                          <i class="fas fa-circle text-xs text-red-400"></i>
                          <span class="text-black-400"> {{ project.name }}</span>
                        </div>
                      </router-link>
                    </li>
                    <button
                      class="hover:bg-black-500 rounded-md"
                      v-if="workspace.project.length == 0"
                    >
                      <span
                        class="text-white-50 text-sm px-7 py-1"
                        @click="toggleProjectModal(workspace.id)"
                      >
                        <i
                          class="fas fa-plus mr-1 text-xs"
                          @click="toggleProjectModal(workspace.id)"
                          data-tooltip-target="project-tooltip"
                          data-tooltip-placement="bottom"
                        ></i>
                        Create Project</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="px-5 border-b border-gray-50 pt-12">
          <li
            class="py-2 text-black-400 text-sm"
            v-if="myAbilities.can('get_profile', 'pms')"
          >
            <router-link to="/profile" class="flex items-center">
              <img
                class="w-8 h-8 rounded-full mr-3"
                src="../../assets/images/user-img.png"
                alt="Rounded avatar"
              />
              <span class="text-black-400">My Profile</span>
            </router-link>
          </li>
        </ul>
        <footer class=" bottom-0 w-full pb-5">
          <ul class="px-5">
            <li class="py-4">
              <button @click.prevent="logout()" class="text-black-400 text-sm">
                <i class="fas fa-sign-out-alt text-white-50 mr-1"></i> Logout
              </button>
            </li>
          </ul>
        </footer>
      </nav>
      </div>
      <!-- Side Nav End-->
    </aside>

    <!-- MAin Content -->
    <main class="flex-1 relative md:ml-64 bg-blueGray-100 w-full" id="maindiv">
      <!-- <div v-if="!isPermission">No Permissions assigned!</div> -->


      <!-- Content changed on routes-->
      <div id="mainContent" class="">
      <Header title="Home"  v-if="myAbilities.can('access_denied', 'pms')" />
        <div v-if="myAbilities.can('access_denied', 'pms')"   class="pt-20 text-lg font-bold">No Permissions</div>
        <div class="w-full" v-else>
          <router-view
            default="{path:'/'}"
            :key="$route.fullPath"
          ></router-view>
        </div>
      </div>
    </main>
    <!-- main content div end -->


    <!-- Modals -->

    <!--Project Modal  -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="sideProjectModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Content -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <!-- Modal header -->
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Create Project
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleProjectModal(null)"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <!-- Modal body -->
          <div class="py-6 px-4 space-y-6 w-full">
            <form
              class="space-y-6"
              id="projectForm"
              @submit.prevent="addProject"
            >
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-sm
                    font-medium
                    text-gray-900
                    dark:text-gray-300
                    required
                  "
                  >Project Name</label
                >
                <input
                  type="text"
                  name="ProjectName"
                  id="projectName"
                  class="
                    bg-white-100
                    border border-gray-400
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-500 focus:border-blue-500
                    block
                    w-full
                    p-2.5
                  "
                  v-model="project.name"
                  placeholder="Project Name"
                  required
                />
              </div>
              <div>
                <label
                  for="description"
                  class="
                    block
                    mb-2
                    text-sm
                    font-medium
                    text-gray-900
                    dark:text-gray-300
                  "
                  >Description</label
                >
                <input
                  type="text"
                  name="desc"
                  id="projectDesc"
                  class="
                    bg-white-100
                    border border-gray-400
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-500 focus:border-blue-500
                    block
                    w-full
                    p-2.5
                    dark:bg-gray-600
                    dark:border-gray-500
                    dark:placeholder-gray-400
                    dark:text-white
                  "
                  v-model="project.description"
                />
              </div>
              <div class="px-2">
                <ul>
                  <li class="text-black-50 border-b">Members</li>
                  <li>
                    <multiselect
                      class="
                        border border-gray-400
                        rounded-lg
                        text-gray-900 text-sm
                        mb-0
                        placeholder-mb-0
                      "
                      :options="WorkspaceUsers ? WorkspaceUsers : []"
                      :close-on-select="false"
                      open-direction="below"
                      :max-height="600"
                      :taggable="true"
                      :multiple="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :allow-empty="false"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Select"
                      name="userSelect"
                      id="workspaceuser"
                      track-by="id"
                      label="name"
                      v-model="project.users"
                    >
                    </multiselect>
                  </li>
                </ul>
              </div>
              <div class="flex justify-center pt-10">
                <div class="flex items-center justify-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- Workspace Modal -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="workspacModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Content -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <!-- Modal header -->
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Workspace
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleWorkspaceModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <!-- Modal body -->
          <div class="py-6 px-4 space-y-6 w-full">
            <form class="space-y-6 px-2" @submit.prevent="addWorkspace">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-sm
                    font-medium
                    text-gray-900
                    dark:text-gray-300
                    required
                  "
                  >Name</label
                >
                <input
                  type="text"
                  name="WorkspaceName"
                  id="workspaceName"
                  class="
                    bg-white-100
                    border border-gray-400
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-500 focus:border-blue-500
                    block
                    w-full
                    p-2.5
                  "
                  v-model="workspace.name"
                  placeholder="Workspace Name"
                  required
                />
              </div>
              <div>
                <label
                  for="description"
                  class="
                    block
                    mb-2
                    text-sm
                    font-medium
                    text-gray-900
                    dark:text-gray-300
                  "
                  >Description</label
                >
                <input
                  type="text"
                  name="description"
                  id="description"
                  class="
                    bg-white-100
                    border border-gray-400
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-500 focus:border-blue-500
                    block
                    w-full
                    p-2.5
                    dark:bg-gray-600
                    dark:border-gray-500
                    dark:placeholder-gray-400
                    dark:text-white
                  "
                  v-model="workspace.description"
                />
              </div>
              <div class="px-2">
                <ul>
                  <li class="text-black-50 border-b">Members</li>
                  <li>
                    <multiselect
                      class="
                        border border-gray-400
                        rounded-lg
                        text-gray-900 text-sm
                        mb-0
                        placeholder-mb-0
                      "
                      :options="Users ? Users : []"
                      :close-on-select="false"
                      open-direction="below"
                      :max-height="600"
                      :taggable="true"
                      :multiple="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :allow-empty="false"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Select"
                      name="userSelect"
                      id="user"
                      track-by="id"
                      label="name"
                      v-model="workspace.users"
                    >
                    </multiselect>
                  </li>
                </ul>
              </div>
              <div class="flex justify-center pt-10">
                <div class="flex items-center justify-center pb-6">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="flex justify-center"></div>
        </div>
      </div>
    </div>
    <!-- End  -->

    <!-- Create User Modal  -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="modal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Create User
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="createUserModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="addCreateUser">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Name:</label
                >
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="Enter Name"
                  required
                  v-model="userName"
                />
              </div>
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Email:</label
                >
                <input
                  type="email"
                  name="userEmail"
                  id="userEmail"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="Enter Email"
                  required
                  v-model="userEmail"
                />
              </div>
              <div class="">
                <label
                  for="phoneno"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Phone No:</label
                >
                <input
                  type="text"
                   placeholder="0300-0978601"
                  pattern="03[0-9]{9}"
                  name="phoneNo"
                  id="phneNo"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    block
                    w-full
                    px-2.5
                    py-2
                    dark:bg-gray-600
                    dark:border-gray-500
                    dark:placeholder-gray-400
                    dark:text-white
                  "
                  v-model="userPhneNo"
                />
              </div>
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Role:</label
                >
                <multiselect
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    
                  "
                  :options="Roles"
                  open-direction="below"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :max-height="100"
                  :allow-empty="false"
                  :searchable="true"
                  select-label="false"
                  placeholder="Select"
                  name="userSelect"
                  id="roles"
                  track-by="id"
                  label="name"
                  v-model="userRole_id"
                >
                </multiselect>
              </div>
              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                    
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Create User End -->

    <!-- Add task Modal  -->
    <div
      class="fixed z-10 h-auto top-0 w-full left-0 hidden"
      id="taskmodal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            h-auto
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Add Task
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="addTaskModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6 overflow-y-scroll" style="height: 80vh;">
            <form class="space-y-6" action="#">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    required
                    dark:text-gray-300
                  "
                  >Name:</label
                >
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  v-model="newTask.name"
                  placeholder="Enter Name"
                  required
                />
              </div>
             
              <div class="">
                <label
                  for="workspace"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Workspace</label
                >
                <multiselect
                  class="text-xs border-0 mb-2 placeholder-mb-0 text-black-50"
                  :options="getWorkspaces"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder=" Workspaces"
                  name="workspace"
                  id="workspaceId"
                  track-by="id"
                  label="name"
                  v-model="taskWorkspace"
                  @close="getWorkspaceProjects"
                >
                </multiselect>
              </div>
              <div>
                <label
                  for="project"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Project</label
                >
                <multiselect
                  class="text-xs border-0 mb-2 placeholder-mb-0 text-black-50"
                  :options="workspaceProjects"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Projects"
                  name="project"
                  id="projectId"
                  track-by="id"
                  label="name"
                  v-model="taskProject"
                  @close="getProjectMembers"
                >
                </multiselect>
              </div>
              <div>
                <label
                  for="asignee"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Asignee</label
                >
                <multiselect
                  class="text-xs border-0 mb-2 placeholder-mb-0 text-black-50"
                  :options="projectUsers"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Users"
                  name="user"
                  id="userId"
                  track-by="id"
                  label="name"
                  v-model="newTask.assignTo"
                >
                </multiselect>
              </div>
              <div class="grid xl:grid-cols-2 xl:gap-6">
                <div class="relative z-0 w-full mb- group">
                  <label
                    for="deadline"
                    class="
                      block
                      mb-2
                      text-xs
                      font-medium
                      text-black-50
                      dark:text-gray-300
                    "
                    >Due Date</label
                  >
                  <date-picker
                    class="text-xs w-full"
                    valueType="YYYY-MM-DD"
                    type="date"
                    placeholder="deadline"
                    v-model="newTask.deadline"
                    @change="saveTaskDeadlineValue"

                  >
                  </date-picker>
                </div>
                <div class="relative z-0 w-full mb- group">
                  <label
                    for="est.duration"
                    class="
                      block
                      mb-2
                      text-xs
                      font-medium
                      text-black-50
                      dark:text-gray-300
                    "
                    >Estimated Duration</label
                  >
                  <date-picker
                    class="text-xs datepicker pb-0"
                    valueType="HH:mm:ss"
                    type="time"
                    placeholder="Estimated Duration"
                    v-model="newTask.estimatedDuration"
                    @change="saveTaskDurationValue"
                  >
                  </date-picker>
                </div>
              </div>
              <div class="grid xl:grid-cols-2 xl:gap-6">
                <div class="relative z-0 w-full mb-6 group">
                  <label
                    for="priority"
                    class="
                      block
                      mb-2
                      text-xs
                      font-medium
                      text-black-50
                      dark:text-gray-300
                      required
                    "
                    >Priority</label
                  >
                  <multiselect
                    class="text-xs border-0 mb-0 placeholder-mb-0 text-black-50"
                    :options="TaskPriority"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    :show-labels="false"
                    placeholder="Select Priority"
                    name="priority"
                    id="priority"
                    track-by="id"
                    label="value"
                    v-model="newTask.priority"
                  >
                  </multiselect>
                </div>
                <div class="relative z-0 w-full mb-6 group">
                  <label
                    for="status"
                    class="
                      block
                      mb-2
                      text-xs
                      font-medium
                      text-black-50
                      dark:text-gray-300
                      required
                    "
                    >Status</label
                  >
                  <multiselect
                    class="text-xs border-0 mb-0 placeholder-mb-0 text-black-50"
                    :options="taskStatus"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    :show-labels="false"
                    placeholder="Select"
                    name="statusSelect"
                    id="status"
                    track-by="id"
                    label="name"
                    v-model="newTask.status"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                    @click.prevent="NewTask"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->
  </div>
</template>

<script>
// import { ref } from 'vue';
// import {Modal} from 'flowbite';

import $ from "jquery";
import Header from './Header.vue'
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Validation from "../../helper/validation";
export default {
  name: "Layout",
  components: {
    Loading,
    Header
  },
  data() {
    return {
      showSidebar: false,
      dropdownPopoverShow: false,
      isLoading: false,
      options: ["Noel", "Zain", "Abid"],
      addModal: {},
      toggleBtn: false,
      workspace: {
        name: "",
        description: "",
        users: [],
      },
      permissions: JSON.parse(localStorage.getItem("permissions")),
      workspaceId: "",
      project: {
        name: "",
        description: "",
        users: [],
      },
      taskWorkspace: [],
      workspaceProjects: [],
      newTask: {
        name: "",
        assignTo: null,
        priority: null,
        status: null,
        estimatedDuration: null,
        deadline: null,
      },
      userName: "",
      userEmail:" ",
      userPhneNo: "",
      userRole_id: "",
      taskProject: [],
      projectUsers: [],
      taskStatus: [],
    };
  },
  methods: {
    ...mapActions([
      "fetchWorkspaces",
      "fetchUsers",
      "fetchRoles",
      "storeWorkspace",
      "storeProject",
      "storeNewTask",
      "storeTask",
      "fetchPriority",
      "createUser",
      "present",
      "fetchWorkspaceMembers",
      "Logout"
    ]),
    ...mapMutations(["toggleLoading"]),

    // get all workspaces for display in side menu
    async getAllWorkspaces() {
      await this.fetchWorkspaces({
        mode: "project",
        user: false,
      });
    },
// validation for user
validateUser() {
      if (this.userPhneNo.length < 11) {
        this.$toasted.error("Invalid Phone number!");
        return false;
      }if(this.userPhneNo.length > 11){
        this.$toasted.error("Invalid Phone number!");
        return false;
      }
      else{
        return true;
      }
    },

    // create user function
   async addCreateUser(e) {
      e.preventDefault();
      let roleID = this.userRole_id.id;
      const body = {
        name: this.userName,
        email: this.userEmail,
        phone: this.userPhneNo,
        role_id: roleID,
      };
      if (this.validateUser()) {
        this.toggleLoading(true);
        let res = await this.createUser(body);
        if (res.success) {
          this.$toasted.success(res.msg);
          this.name = "";
          this.email = "";
          this.phone = "";
          this.role_id = "";
        } else {
          this.$toasted.error(res.msg);
        }
        this.toggleLoading(false);
        this.createUserModal();
      }
    },

    // get selected workspace projects
    getWorkspaceProjects() {
      this.workspaceProjects = this.taskWorkspace.project;
    },

    // get selected project members
    getProjectMembers() {
      this.projectUsers = this.taskProject.user;
      this.taskStatus = this.taskProject.task_status;
      this.newTask.status = this.taskProject.task_status.find(
        (el) => el.is_default
      )
        ? this.taskProject.task_status.find((el) => el.is_default)
        : this.taskProject.task_status[0];
    },

 //access selected deadline value
    saveTaskDeadlineValue(item) {
    
      this.currentDeadline = item;
    },

    //access selected duration value
    saveTaskDurationValue(item) {
     
      this.currentDuration = item;
    },

    //add new workspace
    async addWorkspace() {
      if (this.validateWorkspace()) {
        this.toggleLoading(true);
        let workspaceObj = {
          name: this.workspace.name,
          description: this.workspace.description,
        };
        if (this.workspace.users.length > 0) {
          let userIds = [];
          this.workspace.users.forEach((el) => userIds.push(el.id));
          workspaceObj["user_ids"] = userIds;
        }
        let res = await this.storeWorkspace(workspaceObj);
        this.toggleLoading(false);
        if (res.success) {
          this.$toasted.success(res.msg);
          this.toggleWorkspaceModal();
          (this.workspace.name = ""),
            (this.workspace.description = ""),
            (this.workspace.users = []);
        } else {
          this.$toasted.error(res.msg);
        }
      }
    },

    // Add new task
    async NewTask() {
      let url = window.location.href;
      let segment = url.split("/").pop();
      if (this.taskProject.task_status.length == 0) {
        this.$toasted.error("No task status, Please add status first");
      } else {
        if (this.newTask.name.length > 0) {
          this.toggleLoading(true);
          if (segment == "project") {
            let res = await this.storeTask({
              name: this.newTask.name,
              priority: this.newTask.priority.id,
              status_id: this.newTask.status.id,
              project_id: this.taskProject.id,
              assign_to: this.newTask.assignTo.id,
              deadline: this.currentDeadline,
              estimated_duration: this.currentDuration,
            });
            this.addTaskModal();
            if (res.success) {
              this.$toasted.success(res.msg);
            } else {
              this.$toasted.error(res.msg);
            }
          } else {
            let res = await this.storeNewTask({
              name: this.newTask.name,
              priority: this.newTask.priority.id,
              status_id: this.newTask.status.id,
              project_id: this.taskProject.id,
              assign_to: this.newTask.assignTo.id,
              deadline: this.currentDeadline,
              estimated_duration: this.currentDuration,
            });
            this.addTaskModal();
            if (res.success) {
              this.$toasted.success(res.msg);
            } else {
              this.$toasted.error(res.msg);
            }
          }
          this.toggleLoading(false);
        }
      }
      (this.taskWorkspace = []),
        (this.workspaceProjects = []),
        (this.taskProject = []),
        (this.newTask = {
          name: "",
          assignTo: null,
          priority: null,
          status: null,
          estimatedDuration: null,
          deadline: null,
        });
    },

    //validate workspace
    validateWorkspace() {
      if (this.workspace.name.length < 1) {
        this.$toasted.error("Name is required!");
        return false;
      }
      return true;
    },

    //logout
    async logout() {
            this.toggleLoading(true);
        let res = await this.Logout()
          if (res.success){
            localStorage.removeItem("permissions")
            this.$router.push('/login');
          }else{
            this.router.go();
          }
            this.toggleLoading(false);
    },
    toggleWorkspaceModal() {
      $("#workspacModal").toggleClass("hidden");
    },
    async toggleProjectModal(workspaceId) {
      this.toggleLoading(true);
      if (workspaceId !== null) {
        this.workspaceId = workspaceId;
        console.log("workspace id", workspaceId);
        await this.fetchWorkspaceMembers({ id: workspaceId });
        console.log(this.WorkspaceUsers);
      }
  this.toggleLoading(false);
      $("#sideProjectModal").toggleClass("hidden");
    },
    // display sidebar on small screens 
    toggleSidebar() {
      console.log("caksk")
      let bar = document.querySelector("#Sidebar")
      let maind = document.querySelector('#maindiv')
      $('#closebtn').removeClass('hidden');
      bar.classList.remove('hidden');
      bar.classList.remove('relative');
      bar.classList.remove('md:w-64');
      maind.classList.remove('md:ml-64');


      bar.classList.add('absolute');
      maind.classList.add('md:ml-52');
      maind.classList.add('bg-fixed');
      bar.classList.add('w-52');
    },
    // for closing sidebar when display on small screens 
    closeSidebar(){
      let bar = document.querySelector("#Sidebar");
      bar.classList.add('hidden');
      $('#closebtn').addClass('hidden');
    },
 
    // show projects list on the side menu 
    showProjects(workspaceName) {
      if ($(`.${workspaceName}`).hasClass("hidden")) {
        $(`.Arrow${workspaceName}`).removeClass("fa-caret-right");
        $(`.Arrow${workspaceName}`).addClass("fa-caret-down");
        $(`.${workspaceName}`).removeClass("hidden");
      } else {
        $(`.Arrow${workspaceName}`).removeClass("fa-caret-down");
        $(`.Arrow${workspaceName}`).addClass("fa-caret-right");
        $(`.${workspaceName}`).addClass("hidden");
      }
    },
    // Toggle create user modal
    createUserModal() {
      $("#modal").toggleClass("hidden");
    },

    // Add task Modal
    addTaskModal() {
      $("#taskmodal").toggleClass("hidden");
      this.newTask.priority = this.TaskPriority.find(
        (el) => el.slug == "medium"
      );
      this.newTask.assignTo = JSON.parse(localStorage.getItem("user"));
      this.newTask.status = this.taskProject.task_status.find(
        (el) => el.is_default
      )
        ? this.taskProject.task_status.find((el) => el.is_default)
        : this.taskProject.task_status[0];
    },
    // add project
    async addProject() {
      if (Validation.validateProject(this.project)) {
        this.toggleLoading(true);
        let projectObj = {
          name: this.project.name,
          description: this.project.description,
        };
        if (this.project.users.length > 0) {
          let userIds = [];
          this.project.users.forEach((el) => userIds.push(el.id));
          projectObj["user_ids"] = userIds;
        }
        let res = await this.storeProject({
          ...projectObj,
          workspace_id: this.workspaceId,
        });
        this.toggleLoading(false);
        if (res.success) {
          this.$toasted.success(res.msg);
          this.project.name = "";
          this.project.description = "";
          this.project.users = [];
          this.toggleProjectModal(null);
        } else {
          this.$toasted.error(res.msg);
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "myAbilities",
      "isPermission",
      "getWorkspaces",
      "Projects",
      "Users",
      "Title",
      "TaskPriority",
      "loading",
      "Roles",
      "isPresent",
      "WorkspaceUsers",
    ]),
  },
  mounted() {
    console.log(this.taskWorkspace, "selected workspace");
    //As an instance property
    this.$eventBus.$on("callToggleInChild", () => {
      this.toggleSidebar('bg-white m-2 py-3 px-6 absolute top-0 left-0 right-0 z-40');
    });
    this.$eventBus.$on("callProjectToggle", () => {
      this.toggleProjectModal();
    });
    //calling workspacetoggle function from child components 
    this.$eventBus.$on("callWorkspaceToggle", () => {
      this.toggleWorkspaceModal();
    });
    //calling usertoggle function from child components 
    this.$eventBus.$on("callUserToggle", () => {
      this.createUserModal();
    });
    //calling addtask modal toggle function from child components 
    this.$eventBus.$on("callTaskModalToggle", () => {
      this.addTaskModal();
    });
  },
  async created() {
    this.toggleLoading(true);
     //check present state for user to display checki and check out buttons
    await this.present();
    await this.fetchWorkspaces({
      mode: "project",
      user: false,
    });
    await this.fetchUsers();
    await this.fetchPriority();
    await this.fetchRoles();
    this.toggleLoading(false);
  },
};
</script>

<style >
</style>

//  v-if="myAbilities.can('store_workspace','pms')"


  // {{ myAbilities.can("get_profile", "pms") }}
  //       {{ myAbilities.can("get_permission", "pms") }}
  //       {{ myAbilities.can("create_user", "pms") }}
  //       {{ myAbilities.can('update_profile', "pms") }}