import constant from "../../helper/constant"
import defineAbility from '../../helper/permission'

const state = {
    userData: [],
    token: "",
    user: {},
    users: [],
    loggedIn: localStorage.getItem('token') ? true : false,
    islogging: false,
    ability: {},
    isPermission: "false",
    havePermission: false,
    isPresent: false,

}
const getters = {
    tempUserData: state => state.userData,
    PermanentUsers: state => state.users,
    Token: state => state.token,
    isLogging: state => state.isLogging,
    loggedIn: state => state.loggedIn,
    myAbilities: state => state.ability,
    isPermission: state => state.havePermission,
    isPresent: state => state.isPresent

}

const actions = {
    // User Register
    async RegisterUser({ commit }, body) {
        try{
            let response = await constant.post('/register', body)
            console.log(response, "Respoonse")
            let payload = response.data;
            if (response.status == 200) {
                console.log("success")
                commit('setToken', payload.data.token);
                commit('setUser', payload.data.user);
                return { success: true, msg: "Signedup Successfully! Wait for approval" };
            } else {
                return { success: false, msg: payload.message };
            }
        }
        catch(err){
            console.log("error")
            return {success: false, msg: "Unexpected Error"}
        }
    },

    // User confirmation
    async confirmUser({ commit }, body) {
        let response = await constant.post('/create_permanent_user', body)
        if (response.status == '200') {
            commit('SetPermanentUsers', response.data.data)
            return { success: response.status, msg: response.data.message }
        } else {
            return { error: response.status, msg: response.data.error }
        }
    },

    // User Login function
    async Login({ commit }, body) {
        let response = await constant.post('/login', body)
        let payload = response.data;
        if (response.status == '200') {
            commit('setToken', payload.data.token);
            commit('setUser', payload.data.user);
            commit('setPermissions');
            return { success: response.status, msg: "LoggedIn Successfully!" }
        }
        else {
            return { error: response.status, msg: payload.error }
        }
    },

    // User Logout function
    async Logout({ commit }) {
        let response = await constant.get('/logout')
        let payload = response.data;
        console.log(payload, "djsdj")
        if (response.status == '200') {
            commit('setToken', payload.data);
            commit('setUser', payload.data);
            commit('setPermissions', payload.data);
            return { success: response.status, msg: payload.message }
        }
        else {
            return { error: response.status, msg: payload.error }
        }
    },

    // for user checkIN and check out status
    async present({ commit }) {
        let response = await constant.get('/present_marked')
        if (response.status == '200') {
            commit('setPresent', response.data);
            return { success: response.status, msg: response.data.message }
        }
        else {
            return { error: response.status, msg: response.data.message }
        }
    },

    // User check in function
    async checkIn({ commit }) {
        let response = await constant.get('/checkin')
        if (response.status == '200') {
            commit('setPresent', response.data.data);
            return { success: response.status, msg: response.data.message }
        }
        else {
            return { error: response.status, msg: response.data.message }
        }
    },

    // User check out function
    async checkOut() {
        let response = await constant.get('/checkout')
        if (response.status == '200') {
            // commit('setPresent', false);
            return { success: response.status, msg: response.data.message }
        }
        else {
            return { error: response.status, msg: response.data.message }
        }
    },
}

const mutations = {
    addUserData: (state, payload) => {
        state.userData.push(payload)
    },
    setToken: (state, token) => {
        localStorage.setItem('token', token);
        state.token = token
        state.loggedIn = true

    },
    setUser: (state, payload) => {
        localStorage.setItem('user', JSON.stringify(payload))
        state.user = payload
    },
    toggleLoggedIn: (state, bool) => {
        state.loggedIn = bool
    },
    setUserIsLogging(state, isLogging) {
        state.islogging = isLogging
    },
    setPermissions: (state) => {

        state.ability = defineAbility(state.user)
    },
    setPresent: (state, payload) => {
        state.isPresent = payload
    },
    noPermission: (state) => {
        state.isPermission = false
    },
    setPermanentUsers: (state, payload) => {
        state.users = payload;
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}