<template>
  <div>
    <!-- main container -->
    <div class="grid place-items-center">
      <div class="relative p-4 w-full max-w-5xl h-full md:h-auto">
        <a class="flex flex-col bg-white-100 md:flex-row">
          <div class="py-6 px-4 lg:w-1/2">
            <div class="border-b-2 border-black-200 text-black-100 font-medium">
              <h4 class="mb-1">Description</h4>
            </div>
            <div class="py-3">
              <textarea
              v-if="myAbilities.can('update_project', 'pms')"
                name="description"
                id=""
                cols="30"
                rows="1"
                placeholder="Click to add description..."
                class="
                  text-sm
                  border-0
                  rounded
                  tracking-tighter
                  focus:ring-1 focus:ring-black-100 focus:rounded-lg
                "
                v-model="project.description"
                @focusout="editProject"
              ></textarea>
              <p v-else>{{ this.projectDescription }}</p>
            </div>
            <div class="py-4" >
              <!-- All Members -->
              <div class="py-2 w-full m-2">
                <div
                  class="
                    border-b-2 border-black-200
                    text-black-100
                    font-medium
                    flex
                    justify-between
                    items-center
                    cursor-pointer
                  "
                  @click="toggleAllMembers"
                >
                  <h4 class="mb-2">Members(task owner)</h4>

                  <span
                    ><i class="fas fa-caret-down" id="allMembersIcon"></i
                  ></span>
                </div>
                <div class="" id="allMembers" >
                  <div v-if="myAbilities.can('add_project_member', 'pms')">
                    <multiselect
                      class="
                        text-xs
                        w-full
                        mb-0
                        placeholder-mb-0
                        text-black-50
                        z-10
                      "
                      :options="workspaceFilteredUser"
                      options-direction="bottom"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :multiple="true"
                      :allow-empty="false"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Select Members"
                      name="userSelect"
                      id="user"
                      track-by="id"
                      label="name"
                      v-model="projectMembers"
                      @close="addProjectMembers"
                    >
                    </multiselect>
                  </div>
                  <div class="py-2 border mt-5 border-gray-400 rounded-md ">
                    <ul class="h-40 overflow-y-auto" id="membersList">
                      <div
                        class="
                          px-2
                          flex
                          justify-between
                          items-center
                          relative
                          mb-3
                        "
                      >
                        <h4 class="font-medium mb-1" id="headingMembers">
                          Members
                        </h4>
                        <div class="">
                          <div
                            class="
                              flex
                              absolute
                              inset-y-0
                              right-0
                              items-center
                              pr-3
                              cursor-pointer
                            "
                            @click="searchField"
                          >
                            <svg
                              class="w-5 h-5 text-gray-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                              id="searchIcon"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="searchInp"
                            class="
                              hidden
                              p-2
                              pr-10
                              w-full
                              text-gray-900
                              rounded-lg
                              border border-gray-50
                              sm:text-sm
                              focus:ring-blue-500 focus:border-blue-500
                            "
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                      <li
                        class="
                          flex
                          items-center
                          py-1
                          px-2
                          border-0 border-b border-gray-300
                          justify-between
                          cursor-pointer
                          hover:bg-gray-100
                          transform
                          hover:-translate-y-1
                        "
                        v-for="member in Project.member"
                        :key="member.id"
                      >
                        <div class="flex items-center">
                          <img
                            class="w-10 h-10 rounded-full mr-3"
                            src="../../assets/images/user.jpg"
                            alt="Rounded avatar"
                          />
                          {{ member.name }}
                        </div>
                        <span
                          ><i
                          v-if="myAbilities.can('remove_project_member', 'pms')"
                            class="fas fa-times text-black-50 text-xs"
                            @click="deleteProjectMember(member.id)"
                          ></i
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- All members End -->

              <!-- qa member -->
              <div class="py-2 w-full m-2">
                <div
                  class="
                    border-b-2 border-black-200
                    text-black-100
                    font-medium
                    flex
                    justify-between
                    items-center
                    cursor-pointer
                  "
                  @click="toggleQaMembers"
                >
                  <h4 class="mb-2">QA Members</h4>
                  <span
                    ><i class="fas fa-caret-down" id="qaMembersIcon"></i
                  ></span>
                </div>
                <div class="hidden" id="qaMembers">
                  <div v-if="myAbilities.can('add_project_qa', 'pms')">
                    <multiselect
                      class="
                        text-xs
                        w-full
                        border-0
                        mb-0
                        placeholder-mb-0
                        text-black-50
                        z-10
                      "
                      :options="workspaceFilteredUser"
                      options-direction="bottom"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :allow-empty="false"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Select Q/A"
                      name="userSelect"
                      id="user"
                      track-by="id"
                      label="name"
                      v-model="projectQa"
                      @close="addProjectQaMembers"
                    >
                    </multiselect>
                  </div>
                  <div class="py-2 border mt-5 border-gray-400 rounded-md" v-if="myAbilities.can('get_project_qa', 'pms')">
                    <ul class="h-40 overflow-y-auto" id="qaList">
                      <div
                        class="
                          px-2
                          flex
                          justify-between
                          items-center
                          relative
                          mb-3
                        "
                      >
                        <h4 class="font-medium mb-1" id="qaMember">
                          Q/A Members
                        </h4>
                        <div class="">
                          <div
                            class="
                              flex
                              absolute
                              inset-y-0
                              right-0
                              items-center
                              pr-3
                              cursor-pointer
                            "
                            @click="searchQaMember"
                          >
                            <svg
                              class="w-5 h-5 text-gray-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                              id="QaSearchIcon"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="searchQa"
                            class="
                              hidden
                              p-2
                              pr-10
                              w-full
                              text-gray-900
                              rounded-lg
                              border border-gray-50
                              sm:text-sm
                              focus:ring-blue-500 focus:border-blue-500
                            "
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                      <li
                        class="
                          flex
                          items-center
                          py-1
                          px-2
                          border-0 border-b border-gray-300
                          justify-between
                          cursor-pointer
                          hover:bg-gray-100
                          transform
                          hover:-translate-y-1
                        "
                        v-for="member in Project.project_qa"
                        :key="member.id"
                      >
                        <div class="flex items-center">
                          <img
                            class="w-10 h-10 rounded-full mr-3"
                            src="../../assets/images/user.jpg"
                            alt="Rounded avatar"
                          />
                          {{ member.name }}
                        </div>
                        <span
                          ><i
                            class="fas fa-times text-black-50 text-xs"
                            @click="deleteProjectQaMember(member.id)"
                            v-if="myAbilities.can('remove_project_qa', 'pms')"
                          ></i
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- review members -->
              <div class="py-2 w-full m-2">
                <div
                  class="
                    border-b-2 border-black-200
                    text-black-100
                    font-medium
                    flex
                    justify-between
                    items-center
                    cursor-pointer
                  "
                  @click="toggleReviewMembers"
                >
                  <h4 class="mb-2">Review Members</h4>
                  <span
                    ><i class="fas fa-caret-down" id="reviewMembersIcon"></i
                  ></span>
                </div>
                <div class="hidden" id="reviewMembers">
                  <div v-if="myAbilities.can('add_project_reviewer', 'pms')">
                    <multiselect
                      class="
                        text-xs
                        w-full
                        border-0
                        mb-0
                        placeholder-mb-0
                        text-black-50
                        z-10
                      "
                      :options="workspaceFilteredUser"
                      options-direction="bottom"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :allow-empty="false"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Select"
                      name="userSelect"
                      id="user"
                      track-by="id"
                      label="name"
                      v-model="projectReviewer"
                      @close="addProjectReviewerMembers"
                    >
                    </multiselect>
                  </div>
                  <div class="py-2 border mt-5 border-gray-400 rounded-md" v-if="myAbilities.can('get_project_reviewer', 'pms')">
                    <ul class="h-40 overflow-y-auto" id="reviewList">
                      <div
                        class="
                          px-2
                          flex
                          justify-between
                          items-center
                          relative
                          mb-3
                        "
                      >
                        <h4 class="font-medium mb-1" id="reviewMember">
                          Review Members
                        </h4>
                        <div class="">
                          <div
                            class="
                              flex
                              absolute
                              inset-y-0
                              right-0
                              items-center
                              pr-3
                              cursor-pointer
                            "
                            @click="searchReviewMember"
                          >
                            <svg
                              class="w-5 h-5 text-gray-500"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                              id="reviewSearchIcon"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="searchReview"
                            class="
                              hidden
                              p-2
                              pr-10
                              w-full
                              text-gray-900
                              rounded-lg
                              border border-gray-50
                              sm:text-sm
                              focus:ring-blue-500 focus:border-blue-500
                            "
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                      <li
                        class="
                          flex
                          items-center
                          py-1
                          px-2
                          border-0 border-b border-gray-300
                          justify-between
                          cursor-pointer
                          hover:bg-gray-100
                          transform
                          hover:-translate-y-1
                        "
                        v-for="member in Project.project_reviewer"
                        :key="member.id"
                      >
                        <div class="flex items-center">
                          <img
                            class="w-10 h-10 rounded-full mr-3"
                            src="../../assets/images/user.jpg"
                            alt="Rounded avatar"
                          />
                          {{ member.name }}
                        </div>
                        <span
                          ><i
                          v-if="myAbilities.can('remove_project_reviewer', 'pms')"
                            class="fas fa-times text-black-50 text-xs"
                            @click="deleteProjectReviewerMember(member.id)"
                          ></i
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Projects -->
          <div v-if="myAbilities.can('get_projects_task_status', 'pms')" class="flex flex-col lg:p-4 w-full leading-normal lg:px-6 pt-7">
            <div
              class="tracking-tight border-b-2 border-black-200 text-black-100"
            >
              <h4 class="mb-2 font-medium">Project Task Status</h4>
            </div>
            <!-- Project cards -->
            <div class="lg:px-4 pt-8">
              <div
                class="
                  py-3
                  my-2
                  px-3
                  border-dashed border-2 border-black-200
                  rounded-md
                  transition
                  duration-500
                  ease-in-out
                  hover:border-gray-400
                  text-black-50
                  flex
                  items-center
                  cursor-pointer
                "
                @click="toggleStatusModal"
                v-if="myAbilities.can('store_task_status', 'pms')"
              >
                <i class="fas fa-plus text-sm text-black-50 mr-2"></i>
                Add
              </div>
              <!-- 2nd -->
              <div class="">
                <draggable
                  class="list-group kanban-column"
                  :list="taskStatusList"
                  group="taskStatus"
                  @end="show"
                  @change="updateTaskList"
                >
                  <div
                    class="
                      list-group-item
                      py-3
                      my-2
                      px-3
                      border border-black-200
                      rounded-md
                      transition
                      duration-500
                      ease-in-out
                      hover:border-gray-400
                      flex
                      items-center
                      justify-between
                    "
                    v-for="(element, index) in taskStatusList"
                    :key="element.id"
                  >
                    <div class="flex items-center">
                      <div class="mr-2">
                        <input
                          id="default-radio-1"
                          type="radio"
                          value=""
                          name="default-radio"
                          class="
                            w-4
                            h-4
                            text-blue-600
                            bg-gray-100
                            border-gray-500
                            focus:ring-blue-500
                            dark:focus:ring-blue-600 dark:ring-offset-gray-800
                            focus:ring-2
                            dark:bg-gray-700 dark:border-gray-600
                          "
                          @change="updateDefaultStatus($event, element.id)"
                          :checked="element.is_default"
                        />
                      </div>
                    
                      <div>
                        {{ element.name }}
                        <i
                         v-if="myAbilities.can('update_task_status', 'pms')"
                          class="
                            fas
                            fa-pen
                            ml-2
                            text-xs text-black-50
                            cursor-pointer
                          "
                          @click="toggleEditModal(element.id)"
                        ></i>
                      </div>
                    </div>
                    <div class="w-1/2 flex justify-between">
                      <span
                        class="
                          bg-blue-100
                          text-blue-800 text-xs
                          font-semibold
                          mr-2
                          px-2.5
                          py-1
                          rounded-md
                          border border-blue-400
                        "
                        >order: {{ index + 1 }}
                      </span>
                      <span
                        class="
                          bg-green-100
                          text-blue-800 text-sm
                          font-medium
                          text-xs
                          mr-2
                          px-2.5
                          py-1
                          rounded
                          dark:bg-blue-200 dark:text-blue-900
                        "
                        v-if="myAbilities.can('get_task_status_type', 'pms')"
                      >
                        {{ element.task_status_type.value }}
                      </span>
                      <span class="ml-2">
                        <i
                          class="fas fa-times text-sm text-black-50"
                          @click="removeTaskStatus(element.id)"
                          v-if="myAbilities.can('delete_task_status', 'pms')"
                        ></i>
                      </span>
                    </div>
                  </div>
                </draggable>
                <span class="text-gray-500 text-xs"
                  >* swap or drag to change order</span
                >
              </div>
            </div>
            <!-- Project Settings -->
            <div class="mt-5">
              <div class="border-0 border-b-2 border-black-200">
                <h4 class="mb-2 font-medium">Project Settings</h4>
              </div>
              <div class="px-7">
                <div class="flex items-center mb-4 mt-4">
                  <input
                    id="checkbox-1"
                    type="checkbox"
                    class="
                      w-4
                      h-4
                      text-blue-600
                      bg-gray-100
                      border-gray-500
                      rounded
                      focus:ring-blue-500
                    "
                    :value="Project.is_add_task"
                    :checked="Project.is_add_task"
                    @change="toggleAssignTaskSetting"
                  />
                  <label
                    for="checkbox-1"
                    class="
                      ml-2
                      text-sm
                      font-medium
                      text-gray-900
                      dark:text-gray-300
                    "
                    >User can add task.</label
                  >
                </div>
                <div class="flex items-center mb-4">
                  <input
                    id="checkbox-1"
                    type="checkbox"
                    class="
                      w-4
                      h-4
                      text-blue-600
                      bg-gray-100
                      border-gray-500
                      rounded
                      focus:ring-blue-500
                    "
                    :value="Project.is_single_inprogress"
                    :checked="Project.is_single_inprogress"
                    @change="toggleSingleTaskInProcess"
                  />
                  <label
                    for="checkbox-1"
                    class="
                      ml-2
                      text-sm
                      font-medium
                      text-gray-900
                      dark:text-gray-300
                    "
                    >Multiple Tasks In Process</label
                  >
                </div>
              </div>
              <!-- project delete option -->
               <div class="border-0 border-t-2 border-black-200" v-if="myAbilities.can('delete_project', 'pms')">
                <div class="mt-2 px-7 py-3 rounded-md hover:text-red-600 hover:bg-gray-200" @click="archiveProject">
                  <h5><i
                class="fas fa-trash pr-4"
                data-tooltip-target="archieve-tooltip"
                data-tooltip-placement="bottom"
               
              ></i> Delete Project</h5>
                </div>
                <!-- <h4 class="mb-2 font-medium">Archieve Project</h4> -->
              </div>

              
              <div
                  id="archieve-tooltip"
                  role="tooltip"
                  class="
                    inline-block
                    absolute
                    invisible
                    z-10
                    py-1
                    px-2
                    text-xs text-black-100
                    bg-white-100
                    rounded
                    border border-yellow-200
                    shadow-sm
                    opacity-0
                    tooltip
                  "
                >
                  Archive
                </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <!-- Modals  -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="projectStausModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Content -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <!-- Modal header -->
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Add Task Status
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleStatusModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <!-- Modal body -->
          <div class="py-6 px-4 space-y-6 w-full">
            <form class="space-y-6" @submit.prevent="addTaskStatus">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Name:</label
                >
                <input
                  type="text"
                  name="filedname"
                  id="field"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="status"
                  required
                  v-model="taskStatus.name"
                />
              </div>
              <div class="flex items-center">
                <!-- <div class="mr-3 w-1/2">
                  <label
                    for="description"
                    class="
                      block
                      mb-2
                      text-xs
                      font-medium
                      text-black-50
                      dark:text-gray-300
                    "
                    >Order:</label
                  >
                  <input
                    type="number"
                    name="order"
                    id="Order"
                    class="
                      bg-white-100
                      border border-black-200
                      text-gray-900 text-sm
                      rounded-lg
                      focus:ring-blue-300 focus:border-blue-300
                      block
                      w-full
                      px-2.5
                      py-2
                      dark:bg-gray-600
                      dark:border-gray-500
                      dark:placeholder-gray-400
                      dark:text-white
                    "
                  />
                </div> -->
                <div class="w-1/2">
                  <label
                    for="type"
                    class="
                      block
                      mb-2
                      text-xs
                      font-medium
                      text-black-50
                      dark:text-gray-300
                      required
                    "
                    >Type:</label
                  >
                  <multiselect
                    class="
                      text-xs
                      w-full
                      border-0
                      mb-0
                      placeholder-mb-0
                      text-black-50
                      z-10
                    "
                    :options="TaskStatusTypes"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    placeholder="Select Status Type"
                    name="userSelect"
                    id="statusType"
                    track-by="id"
                    label="slug"
                    v-model="taskStatus.type"
                    v-if="myAbilities.can('get_task_status_type', 'pms')"

                  >
                  </multiselect>
                </div>
              </div>
              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
              <!-- List -->
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="editModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Content -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <!-- Modal header -->
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Edit Task Status
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
             
              @click="toggleEditModal(null)"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <!-- Modal body -->
          <div class="py-6 px-4 space-y-6 w-full">
            <form class="space-y-6" @submit.prevent="editTaskStatus">
              <div>
                <label
                  for="name"
                  class="
                    block
                    mb-2
                    text-xs
                    font-medium
                    text-black-50
                    dark:text-gray-300
                    required
                  "
                  >Name:</label
                >
                <input
                  type="text"
                  name="filedname"
                  id="field"
                  class="
                    bg-white-100
                    border border-black-200
                    text-gray-900 text-sm
                    rounded-lg
                    focus:ring-blue-300 focus:border-blue-300
                    placeholder-gray-400
                    block
                    w-full
                    px-2.5
                    py-2
                  "
                  placeholder="status"
                  required
                  v-model="taskStatus.name"
                />
              </div>
              <div class="flex items-center">
                <!-- <div class="mr-3 w-1/2">
                  <label
                    for="description"
                    class="
                      block
                      mb-2
                      text-xs
                      font-medium
                      text-black-50
                      dark:text-gray-300
                    "
                    >Order:</label
                  >
                  <input
                    type="number"
                    name="order"
                    id="Order"
                    class="
                      bg-white-100
                      border border-black-200
                      text-gray-900 text-sm
                      rounded-lg
                      focus:ring-blue-300 focus:border-blue-300
                      block
                      w-full
                      px-2.5
                      py-2
                      dark:bg-gray-600
                      dark:border-gray-500
                      dark:placeholder-gray-400
                      dark:text-white
                    "
                    v-model="taskStatus.order"
                  />
                </div> -->
                <div class="w-1/2" v-if="myAbilities.can('change_task_status_type', 'pms')">
                  <label
                    for="type"
                    class="
                      block
                      mb-2
                      text-xs
                      font-medium
                      text-black-50
                      dark:text-gray-300
                      required
                    "
                    >Type:</label
                  >
                  <multiselect
                    class="
                      text-xs
                      w-full
                      border-0
                      mb-0
                      placeholder-mb-0
                      text-black-50
                      z-10
                    "
                    :options="TaskStatusTypes"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :allow-empty="false"
                    :searchable="true"
                    placeholder="Select Status Type"
                    name="userSelect"
                    id="statusType"
                    track-by="id"
                    label="slug"
                    v-model="taskStatus.type"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Save
                  </button>
                </div>
              </div>
              <!-- List -->
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- <button @click="openModal">Open Modal</button> -->
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import draggable from "vuedraggable";
import $ from "jquery";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "ProjectOverview",
  components: {
    draggable,
  },
  data() {
    return {
      fieldName: "",
      fields: [],
      options: ["Pending", "InProcess", "Completed"],
      statusModal: {},
      // Project Task Status
      statusList: [
        { id: 1, status: "Todo", type: "Pending" },
        { id: 2, status: "InProcess", type: "InProcess" },
        { id: 3, status: "Complete", type: "Completed" },
      ],
      project: {
        name: "",
        description: "",
      },
      projectDescription: "",
      projectMembers: [],
      projectReviewer: null,
      projectQa: null,
      workspaceFilteredUser: [],
      projectFilteredQa: [],
      projectFilteredReviewer: [],

      taskStatus: {
        name: "",
        id: null,
        is_default: false,
        type: null,
      },
      taskStatusList: [],
    };
  },
  methods: {
    ...mapActions([
      "fetchWorkspaceMembers",
      "storeProjectMembers",
      "updateProject",
      "removeProjectMembers",
      "removeProjectQaMembers",
      "removeProjectReviewMembers",
      "storeProjectReviewMembers",
      "storeProjectQaMembers",
      "fetchProject",
      "fetchTaskStatus",
      "fetchTaskStatusType",
      "storeTaskStatus",
      "updateTaskStatus",
      "deleteTaskStatus",
      "deleteProject",
      "updateDefaultTaskStatus",
      "updateTaskStatusList",
    ]),
    ...mapMutations(["toggleLoading"]),

    toggleStatusModal() {
      document.getElementById("projectStausModal").classList.toggle("hidden");
    },
    toggleEditModal(id) {
      if (id !== null) {
        this.toggleLoading(true);
        let existingTask = this.Project.task_status.find((el) => el.id == id);
        this.taskStatus.name = existingTask.name;
        this.taskStatus.type = existingTask.task_status_type;
        // this.taskStatus.order = existingTask.order;
        this.taskStatus.id = existingTask.id;
      }
      this.toggleLoading(false);

      document.getElementById("editModal").classList.toggle("hidden");
    },
    //assign members to project
    async addProjectMembers() {
      if (this.projectMembers.length > 0) {
        this.toggleLoading(true);
        let members = [];
        this.projectMembers.forEach((el) => members.push(el.id));
        let res = await this.storeProjectMembers({
          user_id: members,
          project_id: this.Project.id,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
          this.projectMembers = [];
          this.filterSelectUsers();
        } else {
          this.$toasted.error(res.msg);
        }
        this.toggleLoading(false);
      }
      
    },
    async deleteProjectMember(id) {
      this.toggleLoading(true);
      let res = await this.removeProjectMembers({
        user_id: id,
        project_id: this.$route.params.id,
      });

      if (res.success) {
        this.$toasted.success(res.msg);
        this.appendRemovedUser(id);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);

    },
    
// delete Project 
async archiveProject() {
      let res = await this.deleteProject({
        id: this.Project.id,
        workspace_id: this.Project.workspace_id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.$router.replace({
          path: "/",
          query: { redirect: this.$router.currentRoute.fullPath },
        });
      } else {
        this.$toasted.error(res.msg);
      }
    },



    //project reviewer
    async addProjectReviewerMembers() {
      if (this.projectReviewer !== null) {
        this.toggleLoading(true);
        console.log(this.projectReviewer);
        let res = await this.storeProjectReviewMembers({
          user_id: this.projectReviewer.id,
          project_id: this.Project.id,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
          this.projectReviewer = null;
          this.filterSelectUsers();
        } else {
          this.$toasted.error(res.msg);
        }
      }
      this.toggleLoading(false);
    },

    async deleteProjectReviewerMember(id) {
      this.toggleLoading(true);
      let res = await this.removeProjectReviewMembers({
        user_id: id,
        project_id: this.$route.params.id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.appendRemovedUser(id);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    //project qa
    async addProjectQaMembers() {
      if (this.projectQa !== null) {
        this.toggleLoading(true);
        let res = await this.storeProjectQaMembers({
          user_id: this.projectQa.id,
          project_id: this.Project.id,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
          this.projectQa = null;
          this.filterSelectUsers();
        } else {
          this.$toasted.error(res.msg);
        }
      }
      this.toggleLoading(false);
    },
    //delete project member
    async deleteProjectQaMember(id) {
      this.toggleLoading(true);
      let res = await this.removeProjectQaMembers({
        user_id: id,
        project_id: this.$route.params.id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.appendRemovedUser(id);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    //update project
    async editProject() {
      this.toggleLoading(true);
      let res = await this.updateProject({
        ...this.project,
        id: this.Project.id,
        workspace_id: this.Project.workspace_id,
      });
       if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    //project settings
    async toggleAssignTaskSetting() {
      this.toggleLoading(true);
      let res = await this.updateProject({
        id: this.Project.id,
        workspace_id: this.Project.workspace_id,
        name: this.Project.name,
        is_add_task: !this.Project.is_add_task,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    async toggleSingleTaskInProcess() {
      this.toggleLoading(true);
      let res = await this.updateProject({
        id: this.Project.id,
        workspace_id: this.Project.workspace_id,
        name: this.Project.name,
        is_single_inprogress: !this.Project.is_single_inprogress,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    //end of project settings

    //task status
    async addTaskStatus() {
      this.toggleLoading(true);

      let res = await this.storeTaskStatus({
        project_id: this.$route.params.id,
        name: this.taskStatus.name,
        task_status_type: this.taskStatus.type.id,
        is_default: false,
        order:this.Project.task_status.length>0? Math.max(...this.Project.task_status.map((el) => el.order)) + 1:1,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.taskStatus.name = "";
        this.taskStatus.type = null;
        this.toggleStatusModal();
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    async editTaskStatus() {
      this.toggleLoading(true);

      let res = await this.updateTaskStatus({
        name: this.taskStatus.name,
        task_status_type: this.taskStatus.type.id,
        project_id: this.Project.id,
        id: this.taskStatus.id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        this.taskStatus.name = "";
        this.taskStatus.type = null;
        this.toggleEditModal(null);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    async removeTaskStatus(id) {
      this.toggleLoading(true);
      console.log(id);
      let res = await this.deleteTaskStatus({ id });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    async updateDefaultStatus(e, new_id) {
      console.log(e);
      let existingDefaultStatus = this.Project.task_status.find(
        (el) => el.is_default
      );
      let res = await this.updateDefaultTaskStatus({
        new_id,
        old_id: existingDefaultStatus ? existingDefaultStatus.id : null,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
        // e.target.focusout()
      } else {
        this.$toasted.error(res.msg);
      }
    },
    show(item) {
      console.log("dragged", item);
    },
    async updateTaskList(item) {
      console.log("dragged", item);
      console.log("list", this.taskStatusList);
      this.taskStatusList.map((el, index) => (el.order = index + 1));
      console.log("post list", this.taskStatusList);
       let updatedList = [] 
       this.taskStatusList.forEach(el=>{
         updatedList.push({
           "id":el.id,
           "order":el.order,
        
        
         })
       })
      let res = await this.updateTaskStatusList({
        project_id: this.Project.id,
        status_list: updatedList,
      });
      if (res.success) {
        this.$toasted.success("Task Status order Updated Successfully");
        this.taskStatusList = this.Project.task_status
        this.taskStatusList.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
        console.log(this.taskStatusList);

      } else {
        this.$toasted.error(res.msg);
      }
    },
    //end of task status

    appendRemovedUser(id) {
      let user = this.WorkspaceUsers.find((el) => el.id == id);
      this.workspaceFilteredUser.push(user);
    },
    filterSelectUsers() {
      this.workspaceFilteredUser = this.WorkspaceUsers.filter((el) => {
        if (
          !this.Project.member.find((item) => item.id == el.id) &&
          !this.Project.project_qa.find((item) => item.id == el.id) &&
          !this.Project.project_reviewer.find((item) => item.id == el.id)
        )
          return el;
      });
    },
    //toggle  all Members List
    toggleAllMembers() {
      if ($("#allMembers").hasClass("hidden")) {
        $("#allMembersIcon").removeClass("fa-caret-down");
        $("#allMembersIcon").addClass("fa-caret-up");
        $("#allMembers").removeClass("hidden");
      } else {
        $("#allMembersIcon").removeClass("fa-caret-up");
        $("#allMembersIcon").addClass("fa-caret-down");
        $("#allMembers").addClass("hidden");
      }
    },
    // toggle Q/A Members List
    toggleQaMembers() {
      if ($("#qaMembers").hasClass("hidden")) {
        $("#qaMembersIcon").removeClass("fa-caret-down");
        $("#qaMembersIcon").addClass("fa-caret-up");
        $("#qaMembers").removeClass("hidden");
      } else {
        $("#qaMembersIcon").removeClass("fa-caret-up");
        $("#qaMembersIcon").addClass("fa-caret-down");
        $("#qaMembers").addClass("hidden");
      }
    },

    // toggle review members List
    toggleReviewMembers() {
      if ($("#reviewMembers").hasClass("hidden")) {
        $("#reviewMembersIcon").removeClass("fa-caret-down");
        $("#reviewMembersIcon").addClass("fa-caret-up");
        $("#reviewMembers").removeClass("hidden");
      } else {
        $("#reviewMembersIcon").removeClass("fa-caret-up");
        $("#reviewMembersIcon").addClass("fa-caret-down");
        $("#reviewMembers").addClass("hidden");
      }
    },
  
    // Search input field
    searchField() {
      console.log("search function clicked!");
      if ($("#searchInp").hasClass("hidden")) {
        $("#searchInp").removeClass("hidden");
        $("#searchInp").addClass("block");

        $("#headingMembers").addClass("hidden");
        $("#searchIcon").addClass("mr-2");
      } else {
        $("#searchInp").addClass("hidden");
        $("#headingMembers").removeClass("hidden");
        $("#searchIcon").removeClass("mr-2");
      }
       $("#searchInp").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#membersList li").filter(function() {
     $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });
    },
    searchQaMember() {
      if ($("#searchQa").hasClass("hidden")) {
        $("#searchQa").removeClass("hidden");
        $("#searchQa").addClass("block");

        $("#qaMember").addClass("hidden");
        $("#QaSearchIcon").addClass("mr-2");
      } else {
        $("#searchQa").addClass("hidden");
        $("#qaMember").removeClass("hidden");
        $("#QaSearchIcon").removeClass("mr-2");
      }
       $("#searchQa").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#qaList li").filter(function() {
     $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });
    },
    searchReviewMember() {
      if ($("#searchReview").hasClass("hidden")) {
        $("#searchReview").removeClass("hidden");
        $("#searchReview").addClass("block");

        $("#reviewMember").addClass("hidden");
        $("#reviewSearchIcon").addClass("mr-2");
      } else {
        $("#searchReview").addClass("hidden");
        $("#reviewMember").removeClass("hidden");
        $("#reviewSearchIcon").removeClass("mr-2");
      }
       $("#searchReview").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#reviewList li").filter(function() {
     $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });
    },
  },
  computed: {
    ...mapGetters([
      "Project",
      "WorkspaceUsers",
      "TaskStatuses",
      "TaskStatusTypes",
      "myAbilities"
    ]),
  },
  async created() {
    this.toggleLoading(true);
    this.Project == null &&
      (await this.fetchProject({ id: this.$route.params.id }));

      

    this.project.description = this.Project.description;
    this.projectDescription = this.Project.description;
    this.project.name = this.Project.name;
    await this.fetchWorkspaceMembers({ id: this.Project.workspace_id });
    this.toggleLoading(false);

    this.workspaceFilteredUser = this.WorkspaceUsers.filter((el) => {
      if (
        !this.Project.member.find((item) => item.id == el.id) &&
        !this.Project.project_qa.find((item) => item.id == el.id) &&
        !this.Project.project_reviewer.find((item) => item.id == el.id)
      )
        return el;
    });
    await this.fetchTaskStatusType();
    // this.taskStatusList = this.Project.task_status;
    var temp = this.Project.task_status;
  // await temp.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
  await temp.sort((a,b) => {return a.order - b.order})
   
   this.taskStatusList = temp;
    console.log(this.taskStatusList);
    // await this.fetchTaskStatus();
    // //------ event bus listener ---------
    this.$eventBus.$on("callTaskStatusModal", () => {
      console.log("in this modal")

      this.toggleStatusModal();
    });
  },
  mounted() {
    this.project.description = this.Project.description;
    this.project.name = this.Project.name;
    //filter the workspace members
    // this.filteredUsers = this.Users.filter((el) => {
    //   if (!this.Workspace.user.find((item) => item.id == el.id)) return el;
    // });
    console.log(this.Project);
    this.workspaceFilteredUser = this.WorkspaceUsers.filter((el) => {
      if (
        !this.Project.member.find((item) => item.id == el.id) &&
        !this.Project.project_qa.find((item) => item.id == el.id) &&
        !this.Project.project_reviewer.find((item) => item.id == el.id)
      )
        return el;
    });
    // this.taskStatusList = this.Project.task_status;

    console.log(this.workspaceFilteredUser, "filtered user");

    //------ event bus listener ---------
    this.$eventBus.$on("callTaskStatusModal", () => {
      console.log("in this modal")
      this.toggleStatusModal();
    });
  },
};
</script>
