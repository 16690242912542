import Api from "../../helper/constant"
import ProjectStore from './project.store'
const state = {
    taskStatuses: [],
    taskStatusTypes: [],
    taskPriorities: [],
    task: {
        name: "",
        description: "",
        comment: [],
        user: {}
    },
    taskId: 0,
    myTask: [],
    Tasks: [],
    newArr:[],
}
const getters = {
    TaskStatuses: state => state.taskStatuses,
    TaskStatusTypes: state => state.taskStatusTypes,
    TaskPriority: state => state.taskPriorities,
    Task: state => state.task,
    TaskId: state => state.taskId,
    myTask: state => state.myTask,
    Tasks: state => state.Tasks,
    Arr: state=>state.newArr
}

const actions = {
    //fetch project task Status type predefined from backend
    async fetchTaskStatusType({ commit }) {
        let response = await Api.get(`/task_status_type`)
        if (response.status == 200) {
            commit('setTaskStatusTypes', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // fetch project task status
    async fetchTaskStatus({ commit }) {
        let response = await Api.get(`/task_status`)
        if (response.status == 200) {
            commit('setTaskStatuses', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // store project task status 
    async storeTaskStatus({ commit }, body) {
        let response = await Api.post(`/task_status`, body)
        if (response.status == 200) {
            commit('addTaskStatus', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // update task status
    async updateTaskStatus({ commit }, body) {
        let response = await Api.put(`/task_status/${body.id}`, body)
        if (response.status == 200) {
            commit('updateTaskStatus', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // update Kanban task status
    async updateKanbanTaskstatus({commit}, body) {
        let response = await Api.post(`update_task_status`, body)
        if (response.status == 200) {
            commit('kanbanTaskStatus', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // delete task status
    async deleteTaskStatus({ commit }, body) {
        let response = await Api.delete(`/task_status/${body.id}`)
        if (response.status == 200) {
            commit('deleteTaskStatus', body.id)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // update project default task status 
    async updateDefaultTaskStatus({ commit }, body) {
        let response = await Api.post(`/default_task_status`, body)
        if (response.status == 200) {
            commit('updateDefaultTaskStatus', body)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // update task status  list
    async updateTaskStatusList({ commit }, body) {
        let response = await Api.put(`/update_task_status_list/${body.project_id}`, { status_list: body.status_list })
        if (response.status == 200) {
            commit('updateTaskStatusList', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    //priority
    async fetchPriority({ commit }) {
        let response = await Api.get(`/priority`)
        if (response.status == 200) {
            commit('setTaskPriority', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // use in list task
    async storeTask({ commit }, body) {
        let response = await Api.post(`/task`, body)
        if (response.status == 200) {
            commit('addTask', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // New task or header
    async storeNewTask({commit}, body) {
        let response = await Api.post(`/task`, body)
        if (response.status == 200) {
            commit( 'setArr', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // USe in Kanabn 
    async storeKanbanTask({ commit }, body) {
        let response = await Api.post(`/task`, body)
        if (response.status == 200) {
            commit('addTask', response.data.data)
            return { success: true, data: response.data.data, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // update task
    async updateTask({ commit }, body) {
        let response = await Api.put(`/task/${body.id}`, body)
        if (response.status == 200) {
            commit('updateTask', response.data.data)
            commit('updateMyTask', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // update my task 
    async updateMyTask({ commit }, body) {
        let response = await Api.put(`/task/${body.id}`, body)
        if (response.status == 200) {
            commit('updateMyTask', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // fetch single task 
    async fetchTask({ commit }, body) {
        let response = await Api.get(`/task/${body.id}`)
        if (response.status == 200) {
            commit('setTask', response.data.data);
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    //store task document
    async storeTaskDocument({ commit }, body) {
        let response = await Api.post(`/task_docs`, body)
        if (response.status == 200) {
            commit('setTaskDoc', response.data.data);
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // delete task docs
    async deleteTaskDocument({ commit }, body) {
        let response = await Api.delete(`task_docs/${body.id}`)
        if (response.status == 200) {
            commit('removeTaskDoc', body);
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // store task comment
    async storeTaskComment({ commit }, body) {
        let response = await Api.post(`/comments`, body)
        if (response.status == 200) {
            commit('addTaskComment', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },
    //store  my task comment
    async storeMyTaskComment({ commit }, body) {
        let response = await Api.post(`/comments`, body)
        if (response.status == 200) {
            commit('addMyTaskComment', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // delete task comment
    async deleteTaskComment({ commit }, body) {
        let response = await Api.delete(`/comments/${body.comment_id}`)
        if (response.status == 200) {
            commit('removeComment', body)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },
    
    // delete my task comment
    async deleteMyTaskComment({ commit }, body) {
        let response = await Api.delete(`/comments/${body.comment_id}`)
        if (response.status == 200) {
            commit('removeMyComment', body)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // update task comment
    async updateTaskComment({ commit }, body) {
        let response = await Api.put(`/comments/${body.id}`, body)
        if (response.status == 200) {
            commit('updateComment', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // update my task comment
    async updateMyTaskComment({ commit }, body) {
        let response = await Api.put(`/comments/${body.id}`, body)
        if (response.status == 200) {
            commit('updateMyComment', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    //task timmers
    async startTaskTimmer({ commit }, body) {
        let response = await Api.get(`/start/${body.id}`)
        console.log(response, "task timer")
        if (response.status == 200) {
            commit('updateTask', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // my task timmer
    async startMyTaskTimmer({ commit }, body) {
        let response = await Api.get(`/start/${body.id}`)
        console.log(response, "my task timer start")
        if (response.status == 200) {
            commit('updateMyTask', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // resume task timmer
    async resumeTaskTimmer({ commit }, body) {
        let response = await Api.get(`/resume/${body.id}`)
        console.log(response, "task timer resume")
        if (response.status == 200) {
            commit('updateTask', response.data.data)
            return { success: true,  msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // resume my task timmer
    async resumeMyTaskTimmer({ commit }, body) {
        let response = await Api.get(`/resume/${body.id}`)
        console.log(response, "my task timer resume")
        if (response.status == 200) {
            commit('updateMyTask', response.data.data)
            return { success: true,  msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // pause task timmer
    async pauseTaskTimmer({ commit }, body) {
        let response = await Api.get(`/pause/${body.id}`)
        console.log(response, "task timer pause")
        if (response.status == 200) {
            commit('updateTask', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // pause my task timmer
    async pauseMyTaskTimmer({ commit }, body) {
        let response = await Api.get(`/pause/${body.id}`)
        console.log(response, "my task timer pause")
        if (response.status == 200) {
            commit('updateMyTask', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // end task timmer
    async endTaskTimmer({ commit }, body) {
        let response = await Api.get(`/end/${body.id}`)
        console.log(response, "task timer end")
        if (response.status == 200) {
            commit('updateTask', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    // end my task timmer
    async endMyTaskTimmer({ commit }, body) {
        let response = await Api.get(`/end/${body.id}`)
        console.log(response, "mytask timer end")
        if (response.status == 200) {
            commit('updateMyTask', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    //  My Tasks 
    async fetchMyTasks({commit}){
        let response = await Api.get(`/get_authtasks`)
        if (response.status == 200) {
            commit('allMyTasks', response.data.data)
            return { success: true, msg: response.data.message }
        } else {
            return { success: false, msg: response.data.message }
        }
    },

    

}

const mutations = {
    addTaskStatus: (state, payload) => {
        ProjectStore.state.project.task_status.push(payload)
        // state.taskStatuses.push(payload)
    },
    setTaskStatuses: (state, payload) => {
        state.taskStatuses = payload
    },
    setTaskStatusTypes: (state, payload) => {
        state.taskStatusTypes = payload
    },
    kanbanTaskStatus: (state, payload) => {
        
        ProjectStore.state.project.task_status
            .find(el => {
                if (el.id == payload.id) {
                    // el.task_id = payload.task_id,
                    //     el.task_status_id = payload.task_status_id
                }
            })
    },
    updateTaskStatus: (state, payload) => {
        ProjectStore.state.project.task_status
            .find(el => {
                if (el.id == payload.id) {
                    el.name = payload.name,
                        el.task_status_type = payload.task_status_type
                }
            })
    },
    deleteTaskStatus: (state, payload) => {
        let statusIndex = ProjectStore.state.project.task_status
            .findIndex(el => {
                el.id == payload
            })
        ProjectStore.state.project.task_status.splice(statusIndex, 1)
    },
    updateDefaultTaskStatus: (state, payload) => {
        if (payload.old_id) {
            ProjectStore.state.project.task_status
                .find(el => {
                    if (el.id == payload.old_id) {
                        el.is_default = false
                    }
                })
        }
        ProjectStore.state.project.task_status
            .find(el => {
                if (el.id == payload.new_id) {
                    el.is_default = true
                }
            })
    },
    updateTaskStatusList: (state, payload) => {
        ProjectStore.state.project.task_status = payload
    },
    setTaskPriority: (state, payload) => {
        state.taskPriorities = payload;
    },
    addTask: (state, payload) => {
        state.myTask.push(payload);
        ProjectStore.state.project.task.push(payload);
    },

    setArr: (state, payload) => {
        state.myTask.push(payload);
    },
    setTask: (state, payload) => {
        state.task = payload
    },
    updateTask: (state, payload) => {
        ProjectStore.state.project.task.find(el => {
            if (el.id == payload.id) {
                el.name = payload.name
                el.description = payload.description
                el['comment'] = payload.comment
                el.assign_to = payload.assign_to
                el.created_at = payload.created_at
                el["deadline"] = payload["deadline"]
                el["document"] = payload["document"]
                el["duration"] = payload["duration"]
                el["end_time"] = payload["end_time"]
                el["estimated_duration"] = payload["estimated_duration"]
                el["is_pause"] = payload["is_pause"]
                el["pause_time"] = payload["pause_time"]
                el["priority"] = payload["priority"]
                el["project"] = payload["project"]
                el["project_id"] = payload["project_id"]
                el["resume_time"] = payload["resume_time"]
                el["start_time"] = payload["start_time"]
                el["status_id"] = payload["status_id"]
                el["task_priority"] = payload["task_priority"]
                el["task_qa"] = payload["task_qa"]
                el["task_review"] = payload["task_review"]
                el["task_status"] = payload["task_status"]
                el["updated_at"] = payload["updated_at"]
                el["user"] = payload["user"]
            }
        })
        state.task = payload

    },


    updateMyTask: (state, payload) => {
        state.myTask.find(el => {
            if (el.id == payload.id) {
                el.name = payload.name
                el.description = payload.description
                el['comment'] = payload.comment
                el.assign_to = payload.assign_to
                el.created_at = payload.created_at
                el["deadline"] = payload["deadline"]
                el["document"] = payload["document"]
                el["duration"] = payload["duration"]
                el["end_time"] = payload["end_time"]
                el["estimated_duration"] = payload["estimated_duration"]
                el["is_pause"] = payload["is_pause"]
                el["pause_time"] = payload["pause_time"]
                el["priority"] = payload["priority"]
                el["project"] = payload["project"]
                el["project_id"] = payload["project_id"]
                el["resume_time"] = payload["resume_time"]
                el["start_time"] = payload["start_time"]
                el["status_id"] = payload["status_id"]
                el["task_priority"] = payload["task_priority"]
                el["task_qa"] = payload["task_qa"]
                el["task_review"] = payload["task_review"]
                el["task_status"] = payload["task_status"]
                el["updated_at"] = payload["updated_at"]
                el["user"] = payload["user"]
            }
        })
        state.task = payload
    },
    addTaskComment: (state, payload) => {
        ProjectStore.state.project.task.find(el => {
            if (el.id == payload.id) {
                el["comment"] = payload.comment
                return el;
            }
        })
        state.task.comment = payload.comment
    },
    addMyTaskComment: (state, payload) => {
        state.myTask.find(el =>  {
            if (el.id == payload.id) {
                el["comment"] = payload.comment
                return el;
            }
        })
        state.task = payload
    },
    setTaskDoc: (state, payload) => {
        state.task["document"].push(payload)
    },
    removeTaskDoc: (state, payload) => {
        let docIndex = state.task.document
            .findIndex(el => {
                el.id == payload.id
            });
        state.task.document.splice(docIndex, 1)
    },
   
    removeComment: (state, payload) => {
        let commentIndex = state.task.comment
            .findIndex(el => {
                el.id == payload.comment_id
            });
        state.task.comment.splice(commentIndex, 1)

    },
    // remove Comment from my task 
    removeMyComment: (state, payload) => {
            let commentIndex = state.task.comment.map(function(e) 
            { return e.id }
        ).indexOf(payload.comment_id);
           
           
            state.task.comment.splice(commentIndex, 1) 
        
    },
    updateComment: (state, payload) => {
        state.task.comment.find(el => {
            if (el.id == payload.id)
                el.description = payload.description;
            el.created_at = payload.created_at;
            el.updated_at = payload.updated_at;
            el.user = payload.user;
        })
    },
    // update mytask comment
    updateMyComment: (state, payload) => {
        state.myTask.comment.find(el => {
            if (el.id == payload.id)
                el.description = payload.description;
            el.created_at = payload.created_at;
            el.updated_at = payload.updated_at;
            el.user = payload.user;
        })
    },
    //extra
    setTaskId: (state, id) => {
        state.taskId = id
    },

    allMyTasks: (state, payload) => {
        state.myTask=payload;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}