
import constant from "../../helper/constant"


const state = {
    taskCount: [],
    workspaceTaskCount : [],
    projectTaskCount: [],
    totalUsers: "",
    totalPresent: "",
    totalAbsent: "",
    absentUsers: "",
    presenceRatio: "",
    resourcesCount: "",
    freeResources: "",
    workspaceAttendance: [],
    workspaceResource: [],
}

const getters = {
    taskCount: state => state.taskCount,
    workspaceTaskCount: state => state.workspaceTaskCount,
    projectTaskCount: state => state.projectTaskCount,
    totalUsers: state => state.totalUsers,
    totalPresent: state => state.totalPresent,
    totalAbsent: state => state.totalAbsent,
    absentUsers: state => state.absentUsers,
    presenceRatio: state =>  state.presenceRatio,
    resourcesCount: state =>  state.resourcesCount,
    freeResources: state =>  state.freeResources,
    workspaceAttendance: state => state.workspaceAttendance,
    workspaceResource: state => state.workspaceResource,
    
}

const actions = {
    // get overall tasks count
    async getTaskCount({commit}){
        let response = await constant.get('/get_task_count') 
            if (response.status == '200') {
                commit('setTotalTaskCount', response.data.data);
                commit('setworkspaceTaskCount', response.data.data.data);
                return { success: response.status, msg:  response.data.message }
            }
            else
            {
                return { error: response.status, msg:  response.data.message }
            }
    },

    // get overall attendance count
    async getAttendanceCount({commit}){
        let response = await constant.get('/get_attendance_count') 
            if (response.status == '200') {
                commit('setTotalUsers', response.data.data.total_users);
                commit('setTotalPresent', response.data.data.total_present);
                commit('setTotalAbsent', response.data.data.total_absent);
                commit('setAbsentUsers', response.data.data.absent_user);
                commit('setPresenceRatio', response.data.data.present_ratio);
                commit('setWorkspaceAttendance', response.data.data.data);
                return { success: response.status, msg:  response.data.message }
            }
            else
            {
                return { error: response.status, msg:  response.data.message }
            }
    },

    // get free resources count
    async getFreeResources({commit}){
        let response = await constant.get('/free_resources') 
            if (response.status == '200') {
                commit('setResourceCount', response.data.data.count);
                commit('setFreeResources', response.data.data.free_resources);
                commit('setWorkspaceResource', response.data.data.data);
                

                return { success: response.status, msg:  response.data.message }
            }
            else
            {
                return { error: response.status, msg:  response.data.message }
            }
    }
}

const mutations = {
    // set tasks count according to workspace
    setworkspaceTaskCount: (state, payload) => {
        state.workspaceTaskCount = payload
    },

    // set total tasks (count)
    setTotalTaskCount: (state, payload) => {
        state.taskCount = payload
    },

    // set total users (count)
    setTotalUsers: (state, payload) => {
        state.totalUsers = payload
    },

    // set total presents (count)
    setTotalPresent: (state, payload) => {
        state.totalPresent = payload
    },

    // set total absents (count)
    setTotalAbsent: (state, payload) => {
        state.totalAbsent = payload
    },

    // set absent users (names)
    setAbsentUsers: (state, payload) => {
        state.absentUsers = payload
    },
    // set presence ratio
    setPresenceRatio: (state, payload) => {
        state.presenceRatio = payload
    },

    // set Resource count (number)
    setResourceCount: (state, payload) => {
        state.resourcesCount = payload
    },

    // set free resources (member/user)
    setFreeResources: (state, payload) => {
        state.freeResources = payload
    },

    // set attendance according to workspaces
    setWorkspaceAttendance: (state, payload) => {
        state.workspaceAttendance = payload;
        
    },

    // set free resources according to workspaces
    setWorkspaceResource: (state, payload) => {
        state.workspaceResource = payload;
        
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}