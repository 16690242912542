<template>
  <div>
    <div class="flex justify-center">
      <div
        class="
          block
          p-6
          lg:w-2/4
          bg-white
          rounded-lg
          border border-gray-200
          shadow-md
          permissions-div
        "
      >
        <!-- Select -->
        <div class="">
          <multiselect
            class="text-xs w-full border-0 mb-0 placeholder-mb-0 text-black-50"
            :options="Users"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :allow-empty="false"
            :searchable="true"
            :show-labels="false"
            placeholder="Select User"
            name="userSelect"
            id="user"
            track-by="id"
            label="name"
            v-model="user"
            @close="checkPerm(user)"
          >
          </multiselect>
        </div>
        <!-- Permissions Checkboxes -->
        <div class="pt-6">
          <div
            class="
              text-lg
              font-medium
              text-gray-900
              border-0 border-b border-gray-300
            "
          >
            Permissions
          </div>
          <div class="pt-6 lg:flex flex-row justify-between flex-wrap">
            <div
              class="lg:w-1/2 flex items-center mb-4"
              v-for="permission in Permissions"
              :key="permission.id"
            >
              <input
                type="checkbox"
                class="
                  p-checks
                  w-4
                  h-4
                  text-blue-600
                  bg-gray-100
                  border-gray-300
                  rounded
                  focus:ring-blue-500 focus:ring-2
                "
                :value="permission.id"
                :id="permission.id"
              />
              <label
                for="disabled-checkbox"
                class="
                  ml-2
                  text-sm
                  font-medium
                  text-gray-600
                  dark:text-gray-500
                "
              >
                {{ permission.name }}
              </label>
            </div>
          </div>
          <div class="flex items-center mb-4 border-0 border-t-2 py-2">
            <input
              type="checkbox"
              class="
                w-4
                h-4
                text-blue-600
                bg-gray-100
                border-gray-300
                rounded
                focus:ring-blue-500 focus:ring-2
              "
              id="checkAll"
              @change="checkAll"
            />
            <label
              for="disabled-checkbox"
              class="ml-2 text-sm font-medium text-gray-600 dark:text-gray-500"
            >
              Check All
            </label>
          </div>
        </div>

        <footer class="flex justify-center items-center px-3 pt-4">
          <button
            class="bg-blue-600 text-white-100 font-medium px-3 rounded-md py-1"
            @click="AssignPermission"
          >
            Update
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "UserPermission",
  data() {
    return {
      options: ["Neha", "Zayn", "Laraib"],
      user: "",
      temp: [],
      assignedPermissions: [],
      oldPermissions: [],
    };
  },
  methods: {
    ...mapActions(["fetchUsers", "fetchPermissions", "assignUserPermission"]),
    ...mapMutations(["toggleLoading"]),

    checkAll(e) {
      if (e.target.checked) {
        document
          .querySelectorAll(".p-checks")
          .forEach((el) => (el.checked = true));
        // e.target.disabled = true;
      } else {
        document
          .querySelectorAll(".p-checks")
          .forEach((el) => (el.checked = false));
        this.oldPermissions = [];
      }
    },

    uncheckAllCheckbox(e) {
      if (!e.target.checked) {
        document.getElementById("checkAll").checked = false;
        document.getElementById("checkAll").disabled = false;
      }
    },

    checkPerm(user) {
      this.oldPermissions = [];
      this.temp = [];
      user.permissions.forEach((el) => this.temp.push(el.id));
      this.assignedPermissions = this.temp;
      document
        .querySelectorAll(".p-checks")
        .forEach((el) =>
          this.temp.includes(parseInt(el.id))
            ? (el.checked = true)
            : (el.checked = false)
        );
      document.getElementById("checkAll").checked =
        this.temp.length == this.Permissions.length ? true : false;
    },

    // Assign Permission
    async AssignPermission() {
      let assigned = [];
      let removeArray = [];
      document.querySelectorAll(".p-checks").forEach((el) => {
        if (el.checked) {
          assigned.push(parseInt(el.value));
          this.oldPermissions.push(parseInt(el.value));
        }
      });
      this.temp.forEach((existing) => {
        if (assigned.find((el) => el == existing)) {
          let index = assigned.findIndex((el) => {
            if (el == existing) {
              assigned.splice(index, 1);
            }
          });
        } else {
          removeArray.push(existing);
        }
      });

      this.toggleLoading(true);
      let res = await this.assignUserPermission({
        user_id: this.user.id,
        permission_ids: this.oldPermissions,
      });
      if (res.success) {
        this.user = "";
        this.$toasted.success(res.msg);
        document.querySelectorAll(".p-checks").forEach((el) => {
          el.checked = false;
        });
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
  },
  computed: {
    ...mapGetters(["Users", "Permissions", "Roles"]),
  },
  async created() {
    await this.fetchPermissions();
  },
};
</script>